/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentFeed_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CommentThread_viewer">;
    readonly " $refType": "CommentFeed_viewer";
};
export type CommentFeed_viewer$data = CommentFeed_viewer;
export type CommentFeed_viewer$key = {
    readonly " $data"?: CommentFeed_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentFeed_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentFeed_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentThread_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '7946f6359eaa399148b50ef0a3a2655d';
export default node;
