/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EnumUserCommunityRole = "Admin" | "Member" | "Moderator" | "%future added value";
export type CurrentCommunity_userCommunity = {
    readonly userCommunity: {
        readonly id: string;
        readonly role: EnumUserCommunityRole | null;
        readonly community: {
            readonly id: string;
            readonly name: string;
            readonly filterObjects: ReadonlyArray<{
                readonly payload: {
                    readonly id?: string;
                    readonly slug?: string;
                    readonly label?: string;
                } | null;
            }> | null;
        } | null;
    } | null;
    readonly " $refType": "CurrentCommunity_userCommunity";
};
export type CurrentCommunity_userCommunity$data = CurrentCommunity_userCommunity;
export type CurrentCommunity_userCommunity$key = {
    readonly " $data"?: CurrentCommunity_userCommunity$data;
    readonly " $fragmentRefs": FragmentRefs<"CurrentCommunity_userCommunity">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userCommunityId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./CurrentCommunity_userCommunity_Query.graphql.ts')
    }
  },
  "name": "CurrentCommunity_userCommunity",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_id",
              "variableName": "userCommunityId"
            },
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "viewerId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "UserCommunity",
      "kind": "LinkedField",
      "name": "userCommunity",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Community",
          "kind": "LinkedField",
          "name": "community",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FilterObject",
              "kind": "LinkedField",
              "name": "filterObjects",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "payload",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "slug",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "label",
                          "storageKey": null
                        }
                      ],
                      "type": "Tag",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '05355e4891c7a20e7d2ac7a672fdb907';
export default node;
