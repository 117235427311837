/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ButtonsContainer = styled.View`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

export const EditorContainer = styled.View`
  margin-top: 8px;
`;

export const VoteContainer = styled.View`
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
`;

export const OptionsContainer = styled.View`
  display: flex;
  flex: 1 0 auto;
  flex-flow: row nowrap;
  margin-horizontal: 8px;
`;

export default null;
