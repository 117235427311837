import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';

import FormFieldBox from '@/FormFieldBox';
import FormFooterButtons from '@/FormFooterButtons';
import ModalCard from '@/ModalCard';
import TextInput from '@/TextInput';
import useFbt from '#hooks/useFbt';
import { CommunityRenameForm_viewer$key } from '~/CommunityRenameForm_viewer.graphql';

const renameCommunityMutation = graphql`
  mutation CommunityRenameFormMutation(
    $viewerId: MongoID!
    $communityId: MongoID!
    $name: String!
  ) @raw_response_type {
    communityUpdate(
      viewerId: $viewerId
      _id: $communityId
      record: { name: $name }
    ) {
      record {
        id
        name
      }
    }
  }
`;

const viewerFragment = graphql`
  fragment CommunityRenameForm_viewer on User {
    id
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  visible?: boolean;
  viewer: CommunityRenameForm_viewer$key | null;
  communityId?: string;
  name?: string;
  onDismiss?: () => void;
};

const CommunityRenameForm = ({
  visible,
  viewer,
  communityId,
  name,
  onDismiss,
}: Props) => {
  const [renameCommunity] = useMutation(renameCommunityMutation);
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [communityName, setCommunityName] = React.useState<string>();

  const isSubmittable = communityName && communityName !== name;

  const handleChangeText = React.useCallback(
    (value) => setCommunityName(value),
    [],
  );

  const handleDismiss = React.useCallback(() => {
    setCommunityName(undefined);
    onDismiss?.();
  }, [onDismiss]);

  const handleSubmit = React.useCallback(() => {
    if (isSubmittable && viewerData?.id && communityId) {
      setIsSubmitting(true);

      renameCommunity({
        variables: {
          viewerId: viewerData.id,
          communityId,
          name: communityName,
        },
        onCompleted: () => {
          setIsSubmitting(false);
          onDismiss?.();
        },
        onError: () => {
          setIsSubmitting(false);
        },
        optimisticResponse: {
          communityUpdate: {
            record: {
              id: communityId,
              name: communityName,
            },
          },
        },
      });
    }
  }, [
    communityId,
    communityName,
    isSubmittable,
    onDismiss,
    renameCommunity,
    viewerData?.id,
  ]);

  return viewer && communityId ? (
    <ModalCard
      visible={visible ?? false}
      title={fbt('Rename community', 'form title')}
      maxWidth="500px"
      onDismiss={handleDismiss}
    >
      <FormFieldBox label={fbt('New community name', 'field label')}>
        <TextInput
          autoFocus
          placeholder={name}
          size="m"
          value={communityName}
          onChangeText={handleChangeText}
        />
      </FormFieldBox>
      <FormFooterButtons
        disabled={!isSubmittable}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        onCancel={handleDismiss}
      />
    </ModalCard>
  ) : null;
};

export default React.memo(CommunityRenameForm);
