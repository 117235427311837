/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type PostStatusMessage_post = {
    readonly status: {
        readonly value: EnumDocumentStatusValue | null;
    } | null;
    readonly " $refType": "PostStatusMessage_post";
};
export type PostStatusMessage_post$data = PostStatusMessage_post;
export type PostStatusMessage_post$key = {
    readonly " $data"?: PostStatusMessage_post$data;
    readonly " $fragmentRefs": FragmentRefs<"PostStatusMessage_post">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostStatusMessage_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
(node as any).hash = '79b399258ba48b2c353b837a5d72b8e4';
export default node;
