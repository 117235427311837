import { AntDesign } from '@expo/vector-icons';
import { dequal } from 'dequal';
import fbt from 'fbt';
import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { graphql, useFragment } from 'react-relay';

import CommentInput from '@/CommentInput';
import CommentSorter from '@/CommentSorter';
import useCommentGlobal from '#hooks/useCommentGlobal';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';
import { CommentDrawerHeader_viewer$key } from '~/CommentDrawerHeader_viewer.graphql';

import {
  BackButton,
  BackButtonContainer,
  BackButtonText,
  CommentFeedHeader,
  CommentInputContainer,
  CommentSorterContainer,
} from './CommentDrawerHeader.style';

const viewerFragment = graphql`
  fragment CommentDrawerHeader_viewer on User {
    ...CommentInput_viewer
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: CommentDrawerHeader_viewer$key | null;
  disabledSorter?: boolean;
  onSubmit?: () => void;
  onBackPressed?: () => void;
};

const s = StyleSheet.create({
  goUpButton: {
    marginBottom: 4,
  },
  goUpLoading: {
    marginBottom: 2,
  },
});

const CommentDrawerHeader = ({
  viewer,
  disabledSorter,
  onSubmit,
  onBackPressed,
}: Props) => {
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const { isGoingUp, canGoUp } = useCommentGlobal();
  const { colors } = useTheme();

  return (
    <>
      <CommentInputContainer>
        <CommentInput
          viewer={viewerData}
          submitText={fbt('Comment', 'button label')}
          onSubmit={onSubmit}
        />
      </CommentInputContainer>
      <CommentFeedHeader>
        <BackButtonContainer>
          {(isGoingUp || canGoUp) && (
            <BackButton onPress={onBackPressed}>
              <AntDesign
                name="back"
                color={colors?.text2}
                size={16}
                style={s.goUpButton}
              />
              <BackButtonText>
                <fbt desc="button label">PARENT COMMENT</fbt>
              </BackButtonText>
              {isGoingUp && (
                <ActivityIndicator size={12} style={s.goUpLoading} />
              )}
            </BackButton>
          )}
        </BackButtonContainer>
        <CommentSorterContainer>
          <CommentSorter disabled={disabledSorter} />
        </CommentSorterContainer>
      </CommentFeedHeader>
    </>
  );
};

export default React.memo(
  CommentDrawerHeader,
  (prev, next) =>
    prev.disabledSorter === next.disabledSorter &&
    dequal(prev.onBackPressed, next.onBackPressed),
);
