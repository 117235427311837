import cleanDeep from 'clean-deep';
import { useRouting } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import base64 from 'react-native-base64';
import { Paragraph } from 'react-native-paper';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';

import FormFieldBox from '@/FormFieldBox';
import FormFooterButtons from '@/FormFooterButtons';
import ModalCard from '@/ModalCard';
import TagCloud from '@/TagCloud';
import TextInput from '@/TextInput';
import { FilterObjectType, QueryParam } from '#enum';
import useFbt from '#hooks/useFbt';
import { TagItem } from '#interfaces';
import createCommunity from '#mutations/CreateCommunity';
import { CreateCommunityForm_viewer$key } from '~/CreateCommunityForm_viewer.graphql';

const viewerFragment = graphql`
  fragment CreateCommunityForm_viewer on User {
    id
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: CreateCommunityForm_viewer$key;
  visible?: boolean;
  tags?: TagItem[] | null;
  onDismiss?: () => void;
};

const CreateCommunityForm = ({ viewer, visible, tags, onDismiss }: Props) => {
  const environment = useRelayEnvironment();
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [communityName, setCommunityName] = React.useState<string>();
  const { navigate } = useRouting();

  const handleChangeText = React.useCallback(
    (name) => setCommunityName(name),
    [],
  );

  const handleDismiss = React.useCallback(() => {
    setCommunityName(undefined);
    onDismiss?.();
  }, [onDismiss]);

  const handleSubmit = React.useCallback(() => {
    if (communityName && tags?.length) {
      setIsSubmitting(true);

      const filters = tags?.map((tag) => ({
        filterObjectType: FilterObjectType.Tag,
        filterObjectId: tag.id,
        condition: 1 as const,
      }));

      createCommunity(
        environment,
        {
          userId: viewerData.id,
          communityName,
          filters,
        },
        {
          onCompleted: (res) => {
            setIsSubmitting(false);

            if (res.userCommunityCreateCommunity?.recordId)
              navigate({
                routeName: 'Home',
                web: { path: '/', shallow: true },
                params: cleanDeep({
                  [QueryParam.EncodedUserCommunityId]: base64.encode(
                    res.userCommunityCreateCommunity.recordId,
                  ),
                  [QueryParam.CommunityName]: communityName,
                }),
              });

            handleDismiss?.();
          },
        },
      );
    }
  }, [
    communityName,
    handleDismiss,
    navigate,
    environment,
    tags,
    viewerData.id,
  ]);

  return tags?.length ? (
    <ModalCard
      visible={visible ?? false}
      title={fbt('Create new community', 'form title')}
      maxWidth="600px"
      onDismiss={handleDismiss}
    >
      <FormFieldBox label={fbt('Community name', 'field label')}>
        <TextInput
          autoFocus
          placeholder={fbt('My awesome community...', 'textinput placeholder')}
          size="l"
          value={communityName}
          onChangeText={handleChangeText}
        />
      </FormFieldBox>
      <Paragraph>
        <fbt desc="form description">
          The new community will be based on the following tags.
        </fbt>
      </Paragraph>
      <TagCloud tags={tags} />
      <FormFooterButtons
        disabled={!communityName}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        onCancel={handleDismiss}
      />
    </ModalCard>
  ) : null;
};

export default React.memo(CreateCommunityForm);
