/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumChannelFilterFilterObjectType = "Tag" | "User" | "%future added value";
export type EnumImageSizesKey = "avatar" | "medium" | "original" | "px1080" | "px720" | "thumbnail" | "%future added value";
export type CommunityImagesInput = {
    cover?: ImageInput | null;
    avatar?: ImageInput | null;
};
export type ImageInput = {
    sizes?: Array<ImageSizesInput | null> | null;
};
export type ImageSizesInput = {
    key?: EnumImageSizesKey | null;
    url?: string | null;
};
export type ChannelFilterInput = {
    filterObjectType: EnumChannelFilterFilterObjectType;
    filterObjectId: string;
    condition?: number | null;
};
export type KeyValueInput = {
    key: string;
    value?: unknown | null;
};
export type CreateCommunityMutationVariables = {
    userId: string;
    name: string;
    images?: CommunityImagesInput | null;
    filters: Array<ChannelFilterInput>;
    settings?: Array<KeyValueInput | null> | null;
};
export type CreateCommunityMutationResponse = {
    readonly userCommunityCreateCommunity: {
        readonly recordId: string | null;
        readonly record: {
            readonly community: {
                readonly id: string;
                readonly channelId: string;
                readonly name: string;
                readonly filters: ReadonlyArray<{
                    readonly filterObjectType: EnumChannelFilterFilterObjectType;
                    readonly filterObjectId: string;
                    readonly condition: number | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};
export type CreateCommunityMutation = {
    readonly response: CreateCommunityMutationResponse;
    readonly variables: CreateCommunityMutationVariables;
};



/*
mutation CreateCommunityMutation(
  $userId: MongoID!
  $name: String!
  $images: CommunityImagesInput
  $filters: [ChannelFilterInput!]!
  $settings: [KeyValueInput]
) {
  userCommunityCreateCommunity(userId: $userId, name: $name, images: $images, filters: $filters, settings: $settings) {
    recordId
    record {
      community {
        id
        channelId
        name
        filters {
          filterObjectType
          filterObjectId
          condition
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "images"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "settings"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "images",
        "variableName": "images"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "settings",
        "variableName": "settings"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "CreateOneUserCommunityPayload",
    "kind": "LinkedField",
    "name": "userCommunityCreateCommunity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "recordId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserCommunity",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Community",
            "kind": "LinkedField",
            "name": "community",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "channelId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ChannelFilter",
                "kind": "LinkedField",
                "name": "filters",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filterObjectType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filterObjectId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "condition",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCommunityMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateCommunityMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "3a882a2b07c2a843b62380deb7d633fd",
    "id": null,
    "metadata": {},
    "name": "CreateCommunityMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCommunityMutation(\n  $userId: MongoID!\n  $name: String!\n  $images: CommunityImagesInput\n  $filters: [ChannelFilterInput!]!\n  $settings: [KeyValueInput]\n) {\n  userCommunityCreateCommunity(userId: $userId, name: $name, images: $images, filters: $filters, settings: $settings) {\n    recordId\n    record {\n      community {\n        id\n        channelId\n        name\n        filters {\n          filterObjectType\n          filterObjectId\n          condition\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '24871e4c99713d0a06eda4b6d5794b4a';
export default node;
