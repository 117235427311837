import { Link, useRouting } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import { Image, Pressable } from 'react-native';

import CenterContent from '@/CenterContent';
import { decodeObjectId } from '#database/utils/base64ObjectId';
import { QueryParam } from '#enum';
import useFbt from '#hooks/useFbt';
import useModals from '#hooks/useModals';
import useTheme from '#hooks/useTheme';
import ImageSource from '#public/images/social-media-network.jpg';

import { ButtonLabel, Container, NavContainer } from './SidebarNav.style';

// eslint-disable-next-line no-unused-expressions
fbt;

const VisitorSidebarModule = () => {
  useFbt();

  const { pathname, getParam } = useRouting();
  const { colors } = useTheme();
  const { openSignUpLoginModal } = useModals();

  const feedQuery = getParam(QueryParam.FeedQuery);
  const isInCommunity = decodeObjectId(
    getParam(QueryParam.EncodedUserCommunityId),
  );

  const isHome = pathname === '/' && !feedQuery && !isInCommunity;

  return (
    <Container>
      <CenterContent>
        <Image source={ImageSource} style={{ width: 320, height: 194 }} />
        <NavContainer
          bg={isHome ? colors?.tag?.blue.backgroundColor : undefined}
        >
          <Link routeName="Home" web={{ path: '/' }}>
            <ButtonLabel color={isHome ? colors?.tag?.blue.label : undefined}>
              Home
            </ButtonLabel>
          </Link>
        </NavContainer>
        <NavContainer>
          <Pressable onPress={openSignUpLoginModal}>
            <ButtonLabel color={colors?.text}>Sign Up / Login</ButtonLabel>
          </Pressable>
        </NavContainer>
      </CenterContent>
    </Container>
  );
};

export default React.memo(VisitorSidebarModule);
