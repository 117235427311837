import React from 'react';

import { TextButton } from './CommentActionButton.style';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onPress?: () => void;
};

const CommentAcionButton = ({ children, disabled, onPress }: Props) => (
  <TextButton disabled={disabled} onPress={onPress}>
    {children}
  </TextButton>
);

export default React.memo(CommentAcionButton, () => true);
