/* eslint-disable prettier/prettier */
import fbt from 'fbt';
import { Types } from 'mongoose';
import React from 'react';
import { graphql, useFragment, useMutation, useQueryLoader } from 'react-relay';
import { useEffectOnce, useUnmount } from 'react-use';

import CollapsedCommentBlock from '@/CollapsedCommentBlock';
import CommentBlockFooter from '@/CommentBlockFooter';
import CommentBlockHeader from '@/CommentBlockHeader';
import CommentBlockSubFeed, {
  query as mainFeedQuery,
} from '@/CommentBlockSubFeed/CommentBlockSubFeed';
import CommentCascadeLine from '@/CommentCascadeLine';
import CommentContentBlock from '@/CommentContentBlock';
import CommentEmptyFeed, {
  query as emptyFeedQuery,
} from '@/CommentEmptyFeed/CommentEmptyFeed';
import CommentLoaderButton from '@/CommentLoaderButton';
import getCommentScoreKey from '#database/utils/getCommentScoreKey';
import useCommentGlobal from '#hooks/useCommentGlobal';
import { CommentLocalProvider } from '#hooks/useCommentLocal';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import { shouldUpdateScore } from '#utils';
import { CommentBlock_comment$key } from '~/CommentBlock_comment.graphql';
import { CommentBlock_viewer$key } from '~/CommentBlock_viewer.graphql';
import { CommentBlockSubFeedQuery } from '~/CommentBlockSubFeedQuery.graphql';
import { CommentEmptyFeedQuery } from '~/CommentEmptyFeedQuery.graphql';

import {
  CascadeLineContainer,
  CommentContainer,
  CommentContentContainer,
  Container,
  MainContainer,
  SubCommentsContainer,
} from './CommentBlock.style';

const updateCommentScoreMutation = graphql`
  mutation CommentBlockMutation(
    $commentId: MongoID!
    $parentCommentId: MongoID
  ) {
    commentUpdateScore(_id: $commentId, parentCommentId: $parentCommentId) {
      id
    }
  }
`;

const viewerFragment = graphql`
  fragment CommentBlock_viewer on User {
    id
    ...CommentContentBlock_viewer
    ...CommentBlockFooter_viewer
  }
`;

const commentFragment = graphql`
  fragment CommentBlock_comment on Comment
  @argumentDefinitions(viewerId: { type: "MongoID" }) {
    id
    postId
    parentCommentId
    count {
      totalSubComments
    }
    comment
    scoreInfo {
      lastUpdatedDate
    }
    createdDate
    ...CommentContentBlock_comment
    ...CollapsedCommentBlock_comment
    ...CommentCascadeLine_comment
    ...CommentBlockHeader_comment
    ...CommentBlockFooter_comment @arguments(viewerId: $viewerId)
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  children?: React.ReactNode[] | null;
  viewer: CommentBlock_viewer$key | null;
  comment: CommentBlock_comment$key;
  zIndex?: number;
  subCommentDepth?: number;
  fetchedIds?: (string | null | undefined)[] | null;
  onLoadMorePress?: () => void;
};

// get comment expand state from the provider to decide which comment should collapse or expand
const CommentBlock = ({
  children,
  viewer,
  comment,
  zIndex,
  subCommentDepth,
  fetchedIds,
  onLoadMorePress,
}: Props) => {
  const [updateCommentScore] = useMutation(updateCommentScoreMutation);
  const [
    emptyFeedQueryRef,
    loadQueryEmptyFeed,
    disposeQueryEmptyFeed,
  ] = useQueryLoader<CommentEmptyFeedQuery>(emptyFeedQuery);
  const [
    mainFeedQueryRef,
    loadQueryMainFeed,
    disposeQueryMainFeed,
  ] = useQueryLoader<CommentBlockSubFeedQuery>(mainFeedQuery);
  const viewerData = useFragment(viewerFragment, viewer);
  const commentData = useFragment(commentFragment, comment);

  useFbt();

  const {
    collapsedCommentIds,
    sort,
    featuredSubCommentType,
  } = useCommentGlobal();
  const [, { lteSm }] = useResponsive();
  const mountedTime = React.useRef(
    Types.ObjectId.createFromTime(Date.now() / 1000).toHexString(),
  );

  const isCollapsed = collapsedCommentIds.includes(commentData.id);
  const moreCommentsNumber =
    (commentData.count?.totalSubComments ?? 0) - (fetchedIds?.length ?? 0);

  const loadViewerReplyComments = () => {
    loadQueryEmptyFeed({
      viewerId: viewerData?.id,
      filter: {
        postId: commentData.postId,
        parentCommentId: commentData.id,
        createdByUserId: viewerData?.id,
        _operators: {
          _id: {
            gt: mountedTime.current,
          },
        },
      },
      sort,
      getFstSubComments: false,
      getSndSubComments: false,
      getTrdSubComments: false,
      getFthSubComments: false,
    });
  };

  // load any comments as long as it's not recently created by viewer
  const loadRepliedComments = React.useCallback(() => {
    const scoreKey = getCommentScoreKey(sort);
    const connectionQuery = scoreKey ? { [scoreKey]: { ne: null } } : {};

    loadQueryMainFeed({
      viewerId: viewerData?.id,
      count: 7,
      filter: {
        postId: commentData.postId,
        parentCommentId: commentData.id,
        _operators: {
          _id: { nin: fetchedIds as string[] },
          ...connectionQuery,
        },
        AND: [
          {
            OR: [
              // active comments
              { status: { value: null } },
              // any comments with active children
              { _operators: { count: { activeSubComments: { gt: 0 } } } },
            ],
          },
          {
            OR: [
              // old viewer comments
              {
                createdByUserId: viewerData?.id,
                _operators: {
                  _id: {
                    lte: mountedTime.current,
                  },
                },
              },
              // every comments but viewer comment
              {
                _operators: {
                  createdByUserId: { ne: viewerData?.id },
                },
              },
            ],
          },
        ],
      },
      sort,
      featuredSubCommentType,
      getFstSubComments: true,
      getSndSubComments: true,
      getTrdSubComments: !lteSm,
      getFthSubComments: !lteSm,
    });
  }, [
    commentData.id,
    commentData.postId,
    featuredSubCommentType,
    fetchedIds,
    loadQueryMainFeed,
    lteSm,
    sort,
    viewerData?.id,
  ]);

  const handleLoadMorePress = React.useCallback(() => {
    onLoadMorePress?.();
    loadRepliedComments();
  }, [loadRepliedComments, onLoadMorePress]);

  useEffectOnce(() => {
    loadViewerReplyComments();

    if (
      commentData.createdDate &&
      shouldUpdateScore(
        new Date(commentData.createdDate),
        new Date(commentData.scoreInfo?.lastUpdatedDate ?? 0),
      )
    )
      updateCommentScore({
        variables: {
          commentId: commentData.id,
          parentCommentId: commentData.parentCommentId,
        },
      });
  });

  useUnmount(() => {
    disposeQueryEmptyFeed();
    disposeQueryMainFeed();
  });

  return isCollapsed ? (
    <CollapsedCommentBlock comment={commentData} />
  ) : (
    <CommentLocalProvider>
      <Container zIndex={zIndex}>
        <CascadeLineContainer marginEnd={lteSm ? '8px' : undefined}>
          <CommentCascadeLine comment={commentData} />
        </CascadeLineContainer>
        <MainContainer>
          <CommentContainer>
            <CommentBlockHeader comment={commentData} />
            <CommentContentContainer>
              <CommentContentBlock
                viewer={viewerData}
                comment={commentData}
                submitText={fbt('Edit', 'button label')}
              />
            </CommentContentContainer>
            <CommentBlockFooter viewer={viewerData} comment={commentData} />
          </CommentContainer>
          <SubCommentsContainer>
            <React.Suspense fallback={null}>
              {emptyFeedQueryRef ? (
                <CommentEmptyFeed queryRef={emptyFeedQueryRef} />
              ) : null}
            </React.Suspense>
            {children}
            {!mainFeedQueryRef ? (
              <CommentLoaderButton
                type="reply"
                count={moreCommentsNumber}
                onPress={handleLoadMorePress}
              />
            ) : null}
            <React.Suspense fallback={null}>
              {mainFeedQueryRef ? (
                <CommentBlockSubFeed
                  queryRef={mainFeedQueryRef}
                  subCommentDepth={subCommentDepth}
                  fetchedIds={fetchedIds}
                  count={moreCommentsNumber}
                />
              ) : null}
            </React.Suspense>
          </SubCommentsContainer>
        </MainContainer>
      </Container>
    </CommentLocalProvider>
  );
};

export default React.memo(
  CommentBlock,
  (prev, next) =>
    prev.children?.length === next.children?.length &&
    prev.fetchedIds?.join(':') === next.fetchedIds?.join(':'),
);
