import { Octicons } from '@expo/vector-icons';
import React from 'react';
import { IconButton } from 'react-native-paper';

import useTheme from '#hooks/useTheme';

type Props = {
  isActive?: boolean;
  isSmallIcon?: boolean;
  onPress?: () => void;
};

const TagIconButton = ({ isActive, isSmallIcon, onPress }: Props) => {
  const { colors } = useTheme();

  const icon = React.useCallback(
    () => (
      <Octicons
        name="bookmark"
        size={isSmallIcon ? 18 : 20}
        color={isActive ? colors?.yellow2 : colors?.blackOpac10}
      />
    ),
    [colors?.blackOpac10, colors?.yellow2, isActive, isSmallIcon],
  );

  return <IconButton icon={icon} onPress={onPress} />;
};

export default React.memo(TagIconButton);
