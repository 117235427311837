/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
`;

export const UserNameContainer = styled.View`
  display: inline-flex;
  margin-end: 4px;
`;

export const UserDisplayName = styled(Text)`
  display: inline-flex;
  color: ${(props: any) => props.theme.colors.text2};
  font-size: 12px;
`;

export const Detail = styled(Text)`
  display: inline-flex;
  color: ${(props: any) => props.theme.colors.text4};
  font-size: 12px;
`;

export default null;
