import React from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';

import ModalCard from '@/ModalCard';
import PostCard from '@/PostCard';
import PostCardFullContent, {
  query,
} from '@/PostCardFullContent/PostCardFullContent';
import { CounterProvider } from '#hooks/useCounter';
import { RelayConnection } from '#interfaces';
import { Post_post$key } from '~/Post_post.graphql';
import { Post_viewer$key } from '~/Post_viewer.graphql';
import { PostCardFullContentQuery } from '~/PostCardFullContentQuery.graphql';

const viewerFragment = graphql`
  fragment Post_viewer on User {
    ...PostCard_viewer
    ...PostCardFullContent_viewer
  }
`;

const postFragment = graphql`
  fragment Post_post on Post
  @argumentDefinitions(viewerId: { type: "MongoID" }) {
    id
    ...PostCard_post @arguments(viewerId: $viewerId)
    ...PostCardFullContent_post @arguments(viewerId: $viewerId)
  }
`;

type Props = {
  viewer: Post_viewer$key | null;
  post: Post_post$key;
  connection: RelayConnection;
};

const Post = ({ viewer, post, connection }: Props) => {
  const [queryRef, loadQuery] = useQueryLoader<PostCardFullContentQuery>(query);
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const onReadMorePress = React.useCallback(() => {
    if (postData.id) {
      setIsModalVisible(true);
      loadQuery({ postId: postData.id });
    }
  }, [loadQuery, postData.id]);

  const onDismiss = React.useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <CounterProvider>
      <PostCard
        viewer={viewerData}
        post={postData}
        connection={connection}
        onReadMorePress={onReadMorePress}
      />
      <ModalCard
        noPadding
        visible={isModalVisible}
        width="auto"
        maxHeight="100%"
        bg="transparent"
        borderRadius="0px"
        onDismiss={onDismiss}
      >
        <React.Suspense fallback={null}>
          {queryRef ? (
            <PostCardFullContent
              queryRef={queryRef}
              viewer={viewerData}
              post={postData}
              connection={connection}
            />
          ) : null}
        </React.Suspense>
      </ModalCard>
    </CounterProvider>
  );
};

export default React.memo(Post);
