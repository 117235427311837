/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ScrollView, ScrollViewProps, StyleSheet } from 'react-native';
import { graphql, useFragment, usePaginationFragment } from 'react-relay';

import CommentLoaderButton from '@/CommentLoaderButton';
import CommentThread from '@/CommentThread';
import useCommentLocal from '#hooks/useCommentLocal';
import { CommentFeed_comments$key } from '~/CommentFeed_comments.graphql';
import { CommentFeed_viewer$key } from '~/CommentFeed_viewer.graphql';
import { CommentEdge } from '~~';

const viewerFragment = graphql`
  fragment CommentFeed_viewer on User {
    ...CommentThread_viewer
  }
`;

const commentsFragment = graphql`
  fragment CommentFeed_comments on Query
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    count: { type: "Int", defaultValue: 2 }
    cursor: { type: "String" }
    filter: { type: "FilterFindManyCommentInput" }
    sort: { type: "SortGetCommentsCommentEnum" }
    featuredSubCommentType: { type: "String", defaultValue: "featured" }
    getFstSubComments: { type: "Boolean!" }
    getSndSubComments: { type: "Boolean!" }
    getTrdSubComments: { type: "Boolean!" }
    getFthSubComments: { type: "Boolean!" }
  )
  @refetchable(queryName: "CommentFeedQuery") {
    comments(first: $count, after: $cursor, filter: $filter, sort: $sort)
      @connection(key: "CommentFeed_comments", filters: ["filter", "sort"]) {
      __id
      edges {
        node {
          id
          ...CommentThread_comment
            @arguments(
              viewerId: $viewerId
              featuredSubCommentType: $featuredSubCommentType
              getFstSubComments: $getFstSubComments
              getSndSubComments: $getSndSubComments
              getTrdSubComments: $getTrdSubComments
              getFthSubComments: $getFthSubComments
            )
        }
      }
    }
  }
`;

type Props = Pick<ScrollViewProps, 'contentContainerStyle' | 'style'> & {
  viewer: CommentFeed_viewer$key | null;
  comments: CommentFeed_comments$key | null;
  subCommentDepth?: number;
  totalSubComments?: number | null;
  excludeIds?: (string | null | undefined)[] | null;
  loadMoreType?: 'comment' | 'reply';
  scrollable?: boolean;
  recordConnection?: boolean;
  formatEdges?: (edges?: CommentEdge['node'][] | null) => any;
};

const CommentFeed = ({
  viewer,
  comments,
  subCommentDepth,
  totalSubComments,
  excludeIds,
  loadMoreType,
  scrollable,
  recordConnection,
  formatEdges,
  ...props
}: Props): React.ReactElement => {
  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment(
    commentsFragment,
    comments,
  );
  const viewerData = useFragment(viewerFragment, viewer);

  const { connections, setConnections } = useCommentLocal();

  const formattedEdges = formatEdges
    ? formatEdges(data?.comments?.edges as any)
    : data?.comments?.edges;
  const commentCount = (totalSubComments ?? 0) - (formattedEdges?.length ?? 0);

  const s = React.useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexGrow: 1,
        },
        contentContainer: {
          flexGrow: 1,
        },
      }),
    [],
  );

  const handleLoadMorePress = React.useCallback(() => {
    if (hasNext && !isLoadingNext) {
      loadNext(5);
    }
  }, [hasNext, isLoadingNext, loadNext]);

  React.useEffect(() => {
    if (
      recordConnection &&
      !connections &&
      data?.comments?.__id &&
      typeof setConnections === 'function'
    )
      setConnections([data?.comments?.__id]);
  }, [connections, data?.comments?.__id, recordConnection, setConnections]);

  return (
    <ScrollView
      scrollEnabled={scrollable ?? false}
      style={s.container}
      contentContainerStyle={s.contentContainer}
      {...props}
    >
      {formattedEdges?.map((item: any, index: number) =>
        item?.node && !excludeIds?.includes(item.node.id) ? (
          <CommentThread
            key={item.node.id}
            zIndex={0 - index}
            viewer={viewerData}
            comment={item.node ?? null}
            subCommentDepth={subCommentDepth}
          />
        ) : null,
      )}
      {commentCount > 0 && hasNext ? (
        <CommentLoaderButton
          type={loadMoreType}
          count={commentCount}
          isLoading={isLoadingNext}
          onPress={handleLoadMorePress}
        />
      ) : null}
    </ScrollView>
  );
};

export default CommentFeed;
