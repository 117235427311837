import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { Status } from '#enum';
import useFbt from '#hooks/useFbt';
import { CommentStatusMessage_comment$key } from '~/CommentStatusMessage_comment.graphql';

import { Message } from './CommentStatusMessage.style';

const commentFragment = graphql`
  fragment CommentStatusMessage_comment on Comment {
    status {
      value
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  comment: CommentStatusMessage_comment$key;
};

const CommentStatusMessage = ({ comment }: Props) => {
  const commentData = useFragment(commentFragment, comment);

  useFbt();

  return commentData.status?.value ? (
    <Message>
      {commentData.status.value === Status.Deleted && (
        <fbt desc="placeholder message">This comment has been deleted</fbt>
      )}
    </Message>
  ) : null;
};

export default React.memo(CommentStatusMessage);
