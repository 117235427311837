import React from 'react';

import { LayoutProps, SpaceProps } from '#styled-system';
import { countReactChildren } from '#utils';

import { Container } from './SidebarItem.style';

type Props = {
  children: React.ReactNode;
} & LayoutProps &
  SpaceProps;

let zIndex = 1;

const SidebarItem = ({ children, ...props }: Props) => {
  zIndex -= 1;
  const haveChildren = countReactChildren(children);

  return haveChildren ? (
    <Container zIndex={zIndex} {...props}>
      {children}
    </Container>
  ) : null;
};

export default React.memo(SidebarItem, () => true);
