/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardContent_post = {
    readonly title: string;
    readonly previewContent: ReadonlyArray<string | null> | null;
    readonly meta: ReadonlyArray<{
        readonly key: string;
        readonly value: unknown | null;
    } | null> | null;
    readonly " $refType": "PostCardContent_post";
};
export type PostCardContent_post$data = PostCardContent_post;
export type PostCardContent_post$key = {
    readonly " $data"?: PostCardContent_post$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardContent_post">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardContent_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyValue",
      "kind": "LinkedField",
      "name": "meta",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
(node as any).hash = 'a55bf1159516c0c0bc31235e22124805';
export default node;
