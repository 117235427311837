import { MaterialCommunityIcons } from '@expo/vector-icons';
import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CommentDrawer from '@/CommentDrawer';
import { CommentFeedProvider } from '#hooks/useCommentFeed';
import useCommentGlobal from '#hooks/useCommentGlobal';
import useCounter from '#hooks/useCounter';
import useDrawer from '#hooks/useDrawer';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';
import { DrawerPosition } from '#interfaces';
import { PostCardCommentButton_post$key } from '~/PostCardCommentButton_post.graphql';
import { PostCardCommentButton_viewer$key } from '~/PostCardCommentButton_viewer.graphql';

import { CommentNumber, Container } from './PostCardCommentButton.style';

const viewerFragment = graphql`
  fragment PostCardCommentButton_viewer on User {
    ...CommentDrawer_viewer
  }
`;

const postFragment = graphql`
  fragment PostCardCommentButton_post on Post {
    id
    count {
      totalComments
    }
    ...CommentDrawer_post
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: PostCardCommentButton_viewer$key | null;
  post: PostCardCommentButton_post$key;
};

const PostCardCommentButton = ({ viewer, post }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  useFbt();

  const { count, increment, reduction } = useCounter();
  const { openDrawer } = useDrawer();
  const { setActivePostId, reset } = useCommentGlobal();
  const [, { lteSm }] = useResponsive();
  const { colors } = useTheme();
  const [isOpenComments, setIsOpenComments] = React.useState(false);

  const commentCount = (postData?.count?.totalComments ?? 0) + count;

  const drawerProps = React.useMemo(
    () =>
      lteSm
        ? {
            drawerPosition: 'bottom' as DrawerPosition,
            maxHeight: '80%',
          }
        : {
            drawerPosition: 'end' as DrawerPosition,
            width: 720,
            maxWidth: '90%',
          },
    [lteSm],
  );

  const handlePress = React.useCallback(() => {
    setActivePostId(postData.id);
    setIsOpenComments(true);
  }, [postData.id, setActivePostId]);

  const comments = React.useMemo(
    () => (
      <CommentFeedProvider addedComment={increment} removedComment={reduction}>
        <CommentDrawer viewer={viewerData} post={postData} />
      </CommentFeedProvider>
    ),
    [increment, postData, reduction, viewerData],
  );

  React.useEffect(() => {
    if (isOpenComments) {
      openDrawer({
        content: comments,
        dismissable: true,
        onOpen: () => {
          reset(postData?.id);
        },
        onDismiss: () => {
          reset(postData.id);
          setIsOpenComments(false);
        },
        ...drawerProps,
      });
    }
  }, [comments, drawerProps, isOpenComments, openDrawer, postData.id, reset]);

  return (
    <Container onPress={handlePress}>
      <MaterialCommunityIcons
        name="comment"
        size={lteSm ? 22 : 24}
        color={colors?.light}
      />
      <CommentNumber>
        {commentCount > 0
          ? fbt(
              fbt.plural('Comment', commentCount, {
                name: 'number of comments',
                showCount: 'ifMany',
                many: 'Comments',
              }),
              'comment button',
            )
          : fbt('No comment', 'button label')}
      </CommentNumber>
    </Container>
  );
};

export default React.memo(PostCardCommentButton);
