/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateCommunityButton_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CreateCommunityForm_viewer">;
    readonly " $refType": "CreateCommunityButton_viewer";
};
export type CreateCommunityButton_viewer$data = CreateCommunityButton_viewer;
export type CreateCommunityButton_viewer$key = {
    readonly " $data"?: CreateCommunityButton_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CreateCommunityButton_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateCommunityButton_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateCommunityForm_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '5bd64d2368efdfe1dd44cf0c895313c3';
export default node;
