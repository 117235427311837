import React from 'react';
import { Portal } from 'react-native-paper';

import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';

import { Container } from './FeedSidebar.style';

type Props = {
  children: React.ReactNode;
  xPos?: string | number;
  yPos?: string | number;
  width?: string | number;
  isVisible?: boolean;
};

const FeedSidebar = ({
  children,
  xPos,
  yPos,
  width,
  isVisible,
}: Props): React.ReactElement | null => {
  const { isRTL } = useFbt();
  const { componentSizes } = useTheme();

  return children ? (
    <Portal>
      <Container
        width={width}
        top={yPos ?? (componentSizes?.headerHeight ?? 0) + 20}
        display={isVisible ? undefined : 'none'}
        left={isRTL ? undefined : xPos}
        right={isRTL ? xPos : undefined}
      >
        {children}
      </Container>
    </Portal>
  ) : null;
};

export default React.memo(FeedSidebar);
