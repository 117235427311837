/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CurrentCommunity_userCommunity_QueryVariables = {
    userCommunityId?: string | null;
    viewerId?: string | null;
};
export type CurrentCommunity_userCommunity_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CurrentCommunity_userCommunity">;
};
export type CurrentCommunity_userCommunity_Query = {
    readonly response: CurrentCommunity_userCommunity_QueryResponse;
    readonly variables: CurrentCommunity_userCommunity_QueryVariables;
};



/*
query CurrentCommunity_userCommunity_Query(
  $userCommunityId: MongoID
  $viewerId: MongoID
) {
  ...CurrentCommunity_userCommunity_1b9j2s
}

fragment CurrentCommunity_userCommunity_1b9j2s on Query {
  userCommunity(filter: {_id: $userCommunityId, userId: $viewerId}) {
    id
    role
    community {
      id
      name
      filterObjects {
        payload {
          __typename
          ... on Tag {
            id
            slug
            label
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userCommunityId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewerId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentCommunity_userCommunity_Query",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "userCommunityId",
            "variableName": "userCommunityId"
          },
          {
            "kind": "Variable",
            "name": "viewerId",
            "variableName": "viewerId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "CurrentCommunity_userCommunity"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurrentCommunity_userCommunity_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_id",
                "variableName": "userCommunityId"
              },
              {
                "kind": "Variable",
                "name": "userId",
                "variableName": "viewerId"
              }
            ],
            "kind": "ObjectValue",
            "name": "filter"
          }
        ],
        "concreteType": "UserCommunity",
        "kind": "LinkedField",
        "name": "userCommunity",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Community",
            "kind": "LinkedField",
            "name": "community",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FilterObject",
                "kind": "LinkedField",
                "name": "filterObjects",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "payload",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          }
                        ],
                        "type": "Tag",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e4be3da944f712bb45ff9d23c4047cd4",
    "id": null,
    "metadata": {},
    "name": "CurrentCommunity_userCommunity_Query",
    "operationKind": "query",
    "text": "query CurrentCommunity_userCommunity_Query(\n  $userCommunityId: MongoID\n  $viewerId: MongoID\n) {\n  ...CurrentCommunity_userCommunity_1b9j2s\n}\n\nfragment CurrentCommunity_userCommunity_1b9j2s on Query {\n  userCommunity(filter: {_id: $userCommunityId, userId: $viewerId}) {\n    id\n    role\n    community {\n      id\n      name\n      filterObjects {\n        payload {\n          __typename\n          ... on Tag {\n            id\n            slug\n            label\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '05355e4891c7a20e7d2ac7a672fdb907';
export default node;
