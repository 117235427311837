import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CommentActionButton from '@/CommentActionButton';
import useCommentGlobal from '#hooks/useCommentGlobal';
import useFbt from '#hooks/useFbt';
import { CommentButtonUp_comment$key } from '~/CommentButtonUp_comment.graphql';

const commentFragment = graphql`
  fragment CommentButtonUp_comment on Comment {
    id
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  comment: CommentButtonUp_comment$key;
};

const CommentButtonUp = ({ comment }: Props) => {
  const commentData = useFragment(commentFragment, comment);

  useFbt();

  const { setRootCommentId } = useCommentGlobal();

  const handlePress = () => {
    setRootCommentId(commentData.id);
  };

  return (
    <CommentActionButton onPress={handlePress}>
      <fbt desc="text button">Up</fbt>
    </CommentActionButton>
  );
};

export default React.memo(CommentButtonUp);
