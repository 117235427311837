import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import ReportForm from '@/ReportForm';
import useFbt from '#hooks/useFbt';
import { ReportCommentForm_viewer$key } from '~/ReportCommentForm_viewer.graphql';

const viewerFragment = graphql`
  fragment ReportCommentForm_viewer on User {
    ...ReportForm_viewer
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: ReportCommentForm_viewer$key | null;
  visible?: boolean;
  onDismiss?: () => void;
};

const title = fbt('Report comment', 'form title');

const items = [
  {
    value: 'unwanted-commercial-content-or-spam',
    label: fbt('Unwanted commercial content or spam', 'button label'),
  },
  { value: 'child-abuse', label: fbt('Child abuse', 'button label') },
  {
    value: 'hate-speech-or-graphic-violence',
    label: fbt('Hate speech or graphic violence', 'button label'),
  },
  {
    value: 'harassment-or-bullying',
    label: fbt('Harassment or bullying', 'button label'),
  },
];

const ReportCommentForm = ({ viewer, visible, onDismiss }: Props) => {
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  return (
    <ReportForm
      visible={visible}
      viewer={viewerData}
      title={title}
      items={items}
      onDismiss={onDismiss}
    />
  );
};

export default React.memo(ReportCommentForm);
