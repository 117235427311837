/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import { display, DisplayProps, width, WidthProps } from '#styled-system';

export const Container = styled.View<DisplayProps & WidthProps>`
  align-items: center;
  width: 100%;
  height: ${(props: any) =>
    `calc(100vh - ${props.theme.componentSizes?.headerHeight}px)`};
  ${display};
  ${width};
`;

export default null;
