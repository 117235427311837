import { useRouting } from 'expo-next-react-navigation';
import React from 'react';

import { FeedScope, QueryParam } from '#enum';
import { FeedContext } from '#interfaces/Analytics';

export type AnalyticsContext = FeedContext;

export const AnalyticsContext = React.createContext<AnalyticsContext>({
  feedType: 'default',
  feedTags: [],
  feedSorted: 'best',
});

const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const { getParam } = useRouting();

  const feedQuery = getParam<string | undefined>(QueryParam.FeedQuery);
  const feedScope = getParam<FeedScope | undefined>(QueryParam.FeedScope);
  const qsFeedTags = getParam<string | undefined>(QueryParam.FeedTags);
  const qsFeedSort = getParam<string | undefined>(
    QueryParam.FeedSort,
    undefined,
  );

  const feedTags = qsFeedTags?.split(',');
  const defaultSort = feedScope === FeedScope.Global ? 'best' : 'taggedOrder';
  const feedSorted = (qsFeedSort ?? defaultSort) as FeedContext['feedSorted'];

  let feedType = 'default' as FeedContext['feedType'];
  if (feedScope === FeedScope.Tagged) feedType = 'tagged';
  else if (feedQuery) feedType = 'tagFiltered';

  const contextValue = React.useMemo(
    () => ({
      feedType,
      feedTags,
      feedSorted,
    }),
    [feedSorted, feedTags, feedType],
  );

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
