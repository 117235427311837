import styled from 'styled-components/native';

import { margin, MarginProps } from '#styled-system';

export const Container = styled.View<MarginProps>`
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  margin-start: 50px;
  margin-vertical: 8px;
  ${margin};
`;

export default null;
