import constate from 'constate';
import { useMemo } from 'react';

const useCommentFeedHook = ({
  addedComment,
  removedComment,
}: {
  addedComment: () => void;
  removedComment: () => void;
}) => {
  const contextValue = useMemo(
    () => ({
      addedComment,
      removedComment,
    }),
    [addedComment, removedComment],
  );

  return contextValue;
};

const [CommentFeedProvider, useCommentFeed] = constate(useCommentFeedHook);

export { CommentFeedProvider };
export default useCommentFeed;
