import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { CBMutationConfig } from '#interfaces';
import {
  CreateCommentMutation,
  CreateOneCommentInput,
} from '~/CreateCommentMutation.graphql';

let tempId = 0;

const mutation = graphql`
  mutation CreateCommentMutation(
    $connections: [ID!]!
    $record: CreateOneCommentInput!
  ) {
    commentCreateOne(record: $record) {
      record
        @prependNode(connections: $connections, edgeTypeName: "CommentEdge") {
        id
        postId
        parentCommentId
        comment
        vote {
          upvotes
          downvotes
        }
        createdBy {
          id
          username
          displayName
        }
        createdDate
        createdByUserId
      }
    }
  }
`;

const createComment = (
  environment: Environment,
  comment: CreateOneCommentInput,
  nodeIds: { connections: string[] },
  viewer?: { id: string; username: string; displayName?: string | null } | null,
  config?: CBMutationConfig<CreateCommentMutation>,
): Disposable | undefined => {
  const variables = {
    connections: nodeIds?.connections,
    record: comment,
  };

  const tid = String((tempId += 1));

  if (viewer)
    return commitMutation<CreateCommentMutation>(environment, {
      ...config,
      mutation,
      variables,
      optimisticResponse: {
        commentCreateOne: {
          record: {
            id: tid,
            postId: comment.postId,
            comment: comment.comment,
            parentCommentId: comment.parentCommentId ?? null,
            vote: {
              upvotes: 1,
              downvotes: 0,
            },
            createdDate: comment.createdDate ?? null,
            createdByUserId: viewer.id,
            createdBy: {
              id: viewer.id,
              username: viewer.username,
              displayName: viewer.displayName ?? null,
            },
          },
        },
      },
    });
};

export default createComment;
