/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FilterTagsFooter_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CreateCommunityButton_viewer">;
    readonly " $refType": "FilterTagsFooter_viewer";
};
export type FilterTagsFooter_viewer$data = FilterTagsFooter_viewer;
export type FilterTagsFooter_viewer$key = {
    readonly " $data"?: FilterTagsFooter_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"FilterTagsFooter_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FilterTagsFooter_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateCommunityButton_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'a366dc6f73d22e4923f3c5c4cf5648b6';
export default node;
