/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from '#styled-system';

export const Button = styled.Pressable<BorderProps & SpaceProps>`
  padding: 10px 10px 5px;
  border: solid 2.5px ${(props: any) => props.theme.colors.blue};
  border-radius: 6px;
  ${border};
  ${space};
`;

export const ButtonLabel = styled(Text)<ColorProps & TypographyProps>`
  color: ${(props: any) => props.theme.colors.text};
  font-size: 24px;
  font-family: '${(props: any) => props.theme.fonts.officinaSans}';
  ${color};
  ${typography};
`;

export default null;
