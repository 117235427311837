/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostFeedTagged_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"Post_viewer" | "PostFeedEmpty_viewer">;
    readonly " $refType": "PostFeedTagged_viewer";
};
export type PostFeedTagged_viewer$data = PostFeedTagged_viewer;
export type PostFeedTagged_viewer$key = {
    readonly " $data"?: PostFeedTagged_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostFeedTagged_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostFeedTagged_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Post_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostFeedEmpty_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '07c0d579b0d338b57087829e121e7247';
export default node;
