/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommunityRenameForm_viewer = {
    readonly id: string;
    readonly " $refType": "CommunityRenameForm_viewer";
};
export type CommunityRenameForm_viewer$data = CommunityRenameForm_viewer;
export type CommunityRenameForm_viewer$key = {
    readonly " $data"?: CommunityRenameForm_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CommunityRenameForm_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunityRenameForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '59a4478805528bbd34be83fbef942fa6';
export default node;
