/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentButtonUp_comment = {
    readonly id: string;
    readonly " $refType": "CommentButtonUp_comment";
};
export type CommentButtonUp_comment$data = CommentButtonUp_comment;
export type CommentButtonUp_comment$key = {
    readonly " $data"?: CommentButtonUp_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentButtonUp_comment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentButtonUp_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
(node as any).hash = 'ab52a1f9c3bdc1bbdd26bdd9ac8f214d';
export default node;
