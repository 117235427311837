/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Message = styled(Text)`
  margin: 10px 0 8px;
  color: ${(props: any) => props.theme.colors.text4};
  font-size: 14px;
  font-style: italic;
  line-height: 150%;
`;

export default null;
