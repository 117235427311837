/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TaggedTagsModal_post = {
    readonly vote: {
        readonly upvotes: number | null;
        readonly downvotes: number | null;
    } | null;
    readonly count: {
        readonly shared: number | null;
        readonly tagged: number | null;
    } | null;
    readonly meta: ReadonlyArray<{
        readonly key: string;
        readonly value: unknown | null;
    } | null> | null;
    readonly userTaggedTags: {
        readonly id: string;
        readonly tags: ReadonlyArray<{
            readonly id: string;
            readonly label: string;
            readonly slug: string;
        } | null>;
    } | null;
    readonly " $refType": "TaggedTagsModal_post";
};
export type TaggedTagsModal_post$data = TaggedTagsModal_post;
export type TaggedTagsModal_post$key = {
    readonly " $data"?: TaggedTagsModal_post$data;
    readonly " $fragmentRefs": FragmentRefs<"TaggedTagsModal_post">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaggedTagsModal_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PostVote",
      "kind": "LinkedField",
      "name": "vote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "upvotes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downvotes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PostCount",
      "kind": "LinkedField",
      "name": "count",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shared",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tagged",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyValue",
      "kind": "LinkedField",
      "name": "meta",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "viewerId"
        }
      ],
      "concreteType": "TaggedPost",
      "kind": "LinkedField",
      "name": "userTaggedTags",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();
(node as any).hash = 'ea03c53d4f7fcb46afadfab6e3824314';
export default node;
