import fbt from 'fbt';
import React from 'react';
import { StyleSheet } from 'react-native';
import { ItemType } from 'react-native-dropdown-picker';

import CamouflageDropdown from '@/CamouflageDropdown';
import useCommentGlobal from '#hooks/useCommentGlobal';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';
import { SortGetCommentsCommentEnum } from '~~';

import {
  Container,
  DropdownContainer,
  Label,
  LabelContainer,
} from './CommentSorter.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  disabled?: boolean;
};

const items = [
  {
    label: `${fbt('FEATURED', 'item label')}`,
    value: SortGetCommentsCommentEnum.FeaturedScore,
  },
  {
    label: `${fbt('BEST', 'item label')}`,
    value: SortGetCommentsCommentEnum.WilsonScore,
  },
  {
    label: `${fbt('HOT', 'item label')}`,
    value: SortGetCommentsCommentEnum.HotScore,
  },
  {
    label: `${fbt('NEWEST', 'item label')}`,
    value: SortGetCommentsCommentEnum.Newest,
  },
  {
    label: `${fbt('OLDEST', 'item label')}`,
    value: SortGetCommentsCommentEnum.Oldest,
  },
  // { label: fbt('CONTROVERSIAL', 'item label'), value: 'controversial'},
];

const CommentSorter = ({ disabled }: Props) => {
  useFbt();

  const { colors } = useTheme();
  const { sort, setSort, isLoading } = useCommentGlobal();

  const s = React.useMemo(
    () =>
      StyleSheet.create({
        style: {
          flex: 0,
          paddingVertical: 3,
          paddingHorizontal: 4,
        },
        container: {
          justifyContent: 'center',
        },
        dropDown: {
          end: 0,
          minWidth: 95,
        },
        label: {
          color: colors?.text3,
          fontWeight: '700',
          fontSize: 12,
        },
        activeLabel: {
          color: colors?.text2,
          fontWeight: '700',
          fontSize: 12,
        },
        selectedLabel: {
          color: colors?.blue2,
          fontWeight: '700',
          fontSize: 12,
          marginStart: 0,
          marginEnd: 6,
        },
        arrow: {
          paddingVertical: 3,
        },
      }),
    [colors?.blue2, colors?.text2, colors?.text3],
  );

  const handleChangeItem = React.useCallback(
    (item: ItemType) => setSort(item.value),
    [setSort],
  );

  return (
    <Container>
      <LabelContainer>
        <Label color={disabled ? colors?.text2 : undefined}>
          <fbt desc="label">SORT BY</fbt>
        </Label>
      </LabelContainer>
      <DropdownContainer>
        <CamouflageDropdown
          defaultValue={sort}
          disabled={isLoading || disabled}
          items={items}
          onChangeItem={handleChangeItem}
          dropDownMaxHeight={300}
          style={s.style}
          containerStyle={s.container}
          dropDownStyle={s.dropDown}
          selectedLabelStyle={s.selectedLabel}
          activeLabelStyle={s.activeLabel}
          labelStyle={s.label}
          arrowStyle={s.arrow}
        />
      </DropdownContainer>
    </Container>
  );
};

export default React.memo(CommentSorter);
