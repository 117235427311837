/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardHeader_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"PostCardHeaderMenu_viewer">;
    readonly " $refType": "PostCardHeader_viewer";
};
export type PostCardHeader_viewer$data = PostCardHeader_viewer;
export type PostCardHeader_viewer$key = {
    readonly " $data"?: PostCardHeader_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardHeader_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardHeader_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCardHeaderMenu_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '13b0bc4d37a92c3c00aa24a914d2c958';
export default node;
