import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CommentActionButton from '@/CommentActionButton';
import ReportCommentForm from '@/ReportCommentForm';
import useFbt from '#hooks/useFbt';
import useModals from '#hooks/useModals';
import { CommentButtonReport_viewer$key } from '~/CommentButtonReport_viewer.graphql';

const viewerFragment = graphql`
  fragment CommentButtonReport_viewer on User {
    ...ReportCommentForm_viewer
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: CommentButtonReport_viewer$key | null;
};

const CommentButtonDelete = ({ viewer }: Props) => {
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const [visible, setVisible] = React.useState(false);
  const { openSignUpLoginModal } = useModals();

  const handlePress = () => {
    if (viewerData) {
      setVisible(true);
    } else {
      openSignUpLoginModal();
    }
  };

  return (
    <>
      <CommentActionButton onPress={handlePress}>
        <fbt desc="text button">Report</fbt>
      </CommentActionButton>
      <ReportCommentForm
        viewer={viewerData}
        visible={visible}
        onDismiss={() => setVisible(false)}
      />
    </>
  );
};

export default React.memo(CommentButtonDelete);
