/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CreateCommunityForm_viewer = {
    readonly id: string;
    readonly " $refType": "CreateCommunityForm_viewer";
};
export type CreateCommunityForm_viewer$data = CreateCommunityForm_viewer;
export type CreateCommunityForm_viewer$key = {
    readonly " $data"?: CreateCommunityForm_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CreateCommunityForm_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateCommunityForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'e6fa552ea9293670e7f805f26af7db5e';
export default node;
