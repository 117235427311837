/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentBlockFooter_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"Vote_viewer" | "CommentInput_viewer" | "CommentButtonReport_viewer">;
    readonly " $refType": "CommentBlockFooter_viewer";
};
export type CommentBlockFooter_viewer$data = CommentBlockFooter_viewer;
export type CommentBlockFooter_viewer$key = {
    readonly " $data"?: CommentBlockFooter_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentBlockFooter_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentBlockFooter_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Vote_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentInput_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentButtonReport_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'ac9b9d14eb899de9cde2e22134ef57c3';
export default node;
