/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.Pressable`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const CommentNumber = styled(Text)`
  margin-horizontal: 6px;
  color: ${(props: any) => props.theme.colors.text4};
  font-weight: bold;
  font-size: 12px;
`;

export default null;
