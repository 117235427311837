import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { useRouting } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import { ActivityIndicator } from 'react-native';
import {
  graphql,
  useFragment,
  useMutation,
  useRefetchableFragment,
} from 'react-relay';
import { useDebouncedCallback } from 'use-debounce';

import CommunityRenameForm from '@/CommunityRenameForm';
import ConfirmationDialog from '@/ConfirmationDialog';
import ControlledTaggedTagsBox from '@/ControlledTaggedTagsBox';
import {
  ControlledTaggedTags,
  TaggedTags,
} from '@/ControlledTaggedTagsBox/ControlledTaggedTagsBox';
import { decodeObjectId } from '#database/utils/base64ObjectId';
import { FilterObjectType, MemberRole, QueryParam } from '#enum';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';
import { TagItem } from '#interfaces';
import { compareTags } from '#utils';
import { CurrentCommunity_userCommunity$key } from '~/CurrentCommunity_userCommunity.graphql';
import { CurrentCommunity_viewer$key } from '~/CurrentCommunity_viewer.graphql';

import {
  Command,
  CommandsContainer,
  CommandText,
  CommunityContainer,
  CommunityName,
  CommunityNameContainer,
  Container,
  LoadingTagsContainer,
} from './CurrentCommunity.style';

const updateCommunityFiltersMutation = graphql`
  mutation CurrentCommunityUpdateCommunityFiltersMutation(
    $communityId: MongoID!
    $filters: [UpdateByIdChannelFilterInput]
    $viewerId: MongoID!
  ) {
    communityUpdate(
      _id: $communityId
      record: { filters: $filters }
      viewerId: $viewerId
    ) {
      record {
        id
        filterObjects {
          type
          condition
          payload {
            ... on Tag {
              id
              label
            }
          }
        }
      }
    }
  }
`;

const deleteCommunityMutation = graphql`
  mutation CurrentCommunityDeleteCommunityMutation($userCommunityId: MongoID!) {
    userCommunityDeleteCommunity(userCommunityId: $userCommunityId) {
      record {
        community {
          id
          name
          status {
            value
          }
        }
      }
    }
  }
`;

const visitCommunityMutation = graphql`
  mutation CurrentCommunityVisitCommunityMutation(
    $_id: MongoID!
    $record: UpdateByIdUserCommunityInput!
  ) {
    userCommunityUpdateById(_id: $_id, record: $record) {
      record {
        lastVisitedDate
      }
    }
  }
`;

const viewerFragment = graphql`
  fragment CurrentCommunity_viewer on User {
    id
    ...CommunityRenameForm_viewer
  }
`;

const userCommunityFragment = graphql`
  fragment CurrentCommunity_userCommunity on Query
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    userCommunityId: { type: "MongoID" }
  )
  @refetchable(queryName: "CurrentCommunity_userCommunity_Query") {
    userCommunity(filter: { _id: $userCommunityId, userId: $viewerId }) {
      id
      role
      community {
        id
        name
        filterObjects {
          payload {
            ... on Tag {
              id
              slug
              label
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: CurrentCommunity_viewer$key | null;
  userCommunity: CurrentCommunity_userCommunity$key | null;
  defaultComponent?: React.ReactElement | null;
};

const getTagIdsString = (tags: TaggedTags) =>
  tags
    ?.map((tag) => tag.id)
    .sort()
    .join('.');

// TODO: show error on the action button if the mutation have go wrong
// TODO: allow user to rename their community
const CurrentCommunity = ({
  viewer,
  userCommunity,
  defaultComponent,
}: Props) => {
  const [updateCommunityFilters] = useMutation(updateCommunityFiltersMutation);
  const [deleteCommunity] = useMutation(deleteCommunityMutation);
  const [visitCommunity] = useMutation(visitCommunityMutation);
  const viewerData = useFragment(viewerFragment, viewer);
  const [data, refetch] = useRefetchableFragment(
    userCommunityFragment,
    userCommunity,
  );

  useFbt();

  const { getParam, navigate } = useRouting();
  const { colors, isDarkTheme } = useTheme();
  const [, { sidebarType }] = useResponsive();

  const encodedUserCommunityId = getParam(
    QueryParam.EncodedUserCommunityId,
  ) as string;

  const communityQueryRef = React.useRef<string | undefined>(
    encodedUserCommunityId,
  );
  const [confirmDeleteVisible, setConfirmDeleteVisible] = React.useState(false);
  const [renameFormVisible, setRenameFormVisible] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isExiting, setIsExiting] = React.useState(false);

  const iconColor = colors?.text2;
  const updateColor = isDarkTheme ? colors?.text2 : colors?.blue;
  const isAdmin = data?.userCommunity?.role === MemberRole.Admin;
  const isSmallScreen = sidebarType === 'drawer';

  const communityNameRef = React.useRef(data?.userCommunity?.community?.name);
  const isAdminRef = React.useRef<boolean | undefined>(isAdmin);
  const communityName =
    data?.userCommunity?.community?.name ?? communityNameRef.current ?? '...';
  const isEditable = (isAdmin || isAdminRef.current) && !isRefetching;

  // transform `filterObjects` to `tags`
  const formattedFilterTags = data?.userCommunity?.community?.filterObjects
    ?.filter((FilterObject) => FilterObject?.payload)
    ?.map((filterObject) => filterObject.payload as TagItem);

  const [selectedTags, setSelectedTags] = React.useState<
    ControlledTaggedTags['selectedTags']
  >();
  const [filterTags, setFilterTags] = React.useState(formattedFilterTags);

  // use tag ids to check is the filter has change or not
  const tagIdsString = getTagIdsString(selectedTags);
  const tagIdsStringRef = React.useRef(getTagIdsString(filterTags));
  const disabledUpdate =
    !(selectedTags?.length && tagIdsStringRef.current !== tagIdsString) ||
    isUpdating ||
    isDeleting ||
    isRefetching;
  const disabledDelete = isUpdating || isDeleting || isRefetching;

  // get userCommunityId from the `c` query string
  const userCommunityId = decodeObjectId(encodedUserCommunityId);

  // methods
  const refetchCallback = React.useCallback(() => {
    isAdminRef.current = isAdmin;
    setIsRefetching(false);
  }, [isAdmin]);

  const refetchCallbackDebounce = useDebouncedCallback(() => {
    if (isRefetching) refetchCallback();
  }, 1500);

  const openConfirmDelete = React.useCallback(
    () => setConfirmDeleteVisible(true),
    [],
  );

  const closeConfirmDelete = React.useCallback(
    () => setConfirmDeleteVisible(false),
    [],
  );

  const openRenameForm = React.useCallback(
    () => setRenameFormVisible(true),
    [],
  );

  const closeRenameForm = React.useCallback(
    () => setRenameFormVisible(false),
    [],
  );

  const handleTagChange = React.useCallback((state: ControlledTaggedTags) => {
    setSelectedTags(state.selectedTags);
  }, []);

  const handleExit = React.useCallback(() => {
    setIsExiting(true);
    // reset filter tags
    setSelectedTags(filterTags);
    communityQueryRef.current = undefined;
    communityNameRef.current = undefined;

    // go to home
    navigate({
      routeName: 'Home',
      web: { path: '/', shallow: true },
      params: {},
    });
  }, [filterTags, navigate]);

  const handleUpdate = React.useCallback(() => {
    const communityId = data?.userCommunity?.community?.id;

    if (viewerData?.id && communityId) {
      setIsUpdating(true);

      const newFilters = selectedTags
        ?.filter((tag): tag is NonNullable<TagItem> => !!tag)
        ?.map((tag) => ({
          filterObjectType: FilterObjectType.Tag,
          filterObjectId: tag.id,
          condition: 1 as const,
        }));

      updateCommunityFilters({
        variables: {
          communityId,
          viewerId: viewerData.id,
          filters: newFilters,
        },
        onCompleted: () => {
          tagIdsStringRef.current = tagIdsString;
          setIsUpdating(false);
        },
        onError: (err) => {
          if (err) throw err;
          setIsUpdating(false);
        },
      });
    }
  }, [
    data?.userCommunity?.community?.id,
    selectedTags,
    tagIdsString,
    updateCommunityFilters,
    viewerData?.id,
  ]);

  const handleDelete = React.useCallback(() => {
    if (viewerData?.id && userCommunityId) {
      setIsDeleting(true);
      setConfirmDeleteVisible(false);

      deleteCommunity({
        variables: {
          userCommunityId,
        },
        onCompleted: () => {
          setIsDeleting(false);
          handleExit();
        },
        onError: () => {
          setIsDeleting(false);
        },
      });
    }
  }, [deleteCommunity, handleExit, userCommunityId, viewerData?.id]);

  React.useEffect(() => {
    if (!userCommunityId) setIsExiting(false);
    if (formattedFilterTags && !compareTags(formattedFilterTags, filterTags))
      setFilterTags(formattedFilterTags);
  }, [filterTags, formattedFilterTags, userCommunityId]);

  // refetch community data if query changed
  React.useEffect(() => {
    if (!isExiting && data?.userCommunity?.community?.name)
      // cache before rerender
      communityNameRef.current = data.userCommunity?.community?.name;

    if (
      !isExiting &&
      encodedUserCommunityId &&
      encodedUserCommunityId !== communityQueryRef.current
    ) {
      communityQueryRef.current = encodedUserCommunityId;

      // clear selectedTags when query change
      setSelectedTags(undefined);

      if (userCommunityId && viewerData?.id) {
        setIsRefetching(true);
        refetchCallbackDebounce();

        visitCommunity({
          variables: {
            _id: userCommunityId,
            record: {
              lastVisitedDate: new Date().toString(),
            },
          },
        });

        // TODO: should retry if it's too long
        // TODO: should cache filter tags for better UX
        refetch(
          {
            userCommunityId,
            viewerId: viewerData.id,
          },
          {
            fetchPolicy: 'store-and-network',
            onComplete: () => {
              refetchCallback();
            },
          },
        );
      }
    }
  }, [
    data?.userCommunity?.community?.name,
    encodedUserCommunityId,
    isExiting,
    refetch,
    refetchCallback,
    refetchCallbackDebounce,
    userCommunityId,
    viewerData?.id,
    visitCommunity,
  ]);

  return encodedUserCommunityId && (userCommunity ?? isRefetching) ? (
    <Container>
      <CommunityContainer>
        <CommunityNameContainer disabled={!isEditable} onPress={openRenameForm}>
          <CommunityName
            fontSize={isSmallScreen ? '14px' : undefined}
            numberOfLines={2}
            ellipsisMode="tail"
          >
            {isRefetching ? '...' : communityName}
          </CommunityName>
          {isEditable && (
            <AntDesign
              name="edit"
              size={isSmallScreen ? 12 : 14}
              color={colors?.text2}
            />
          )}
          {renameFormVisible && (
            <CommunityRenameForm
              visible={renameFormVisible}
              viewer={viewerData}
              communityId={data?.userCommunity?.community?.id}
              name={data?.userCommunity?.community?.name}
              onDismiss={closeRenameForm}
            />
          )}
        </CommunityNameContainer>
        <CommandsContainer>
          {isEditable && (
            <Command disabled={disabledUpdate} onPress={handleUpdate}>
              {isUpdating ? (
                <ActivityIndicator size={16} />
              ) : (
                <MaterialCommunityIcons
                  name="update"
                  size={16}
                  color={disabledUpdate ? colors?.text3 : updateColor}
                />
              )}
              <CommandText color={disabledUpdate ? colors?.text3 : updateColor}>
                <fbt desc="button label">Update</fbt>
              </CommandText>
            </Command>
          )}
          {isEditable && (
            <>
              <Command disabled={disabledDelete} onPress={openConfirmDelete}>
                {isDeleting ? (
                  <ActivityIndicator size={16} />
                ) : (
                  <MaterialCommunityIcons
                    name="trash-can-outline"
                    size={16}
                    color={disabledDelete ? colors?.text3 : iconColor}
                  />
                )}
                <CommandText color={disabledDelete ? colors?.text3 : undefined}>
                  <fbt desc="button label">Delete</fbt>
                </CommandText>
              </Command>
              <ConfirmationDialog
                visible={confirmDeleteVisible}
                title={fbt('Delete community', 'form title')}
                message={`${fbt(
                  `Are you sure you want to delete ${fbt.param(
                    'community name',
                    communityName,
                  )}?`,
                  'form message',
                )}`}
                submitLabel={fbt('Delete', 'button label')}
                submitProps={{
                  color: colors?.danger,
                  onPress: handleDelete,
                }}
                cancelProps={{ onPress: closeConfirmDelete }}
              />
            </>
          )}
          {!isRefetching && (
            <Command onPress={handleExit}>
              <MaterialCommunityIcons
                name="location-exit"
                size={16}
                color={iconColor}
              />
              <CommandText>
                <fbt desc="button label">Exit</fbt>
              </CommandText>
            </Command>
          )}
        </CommandsContainer>
      </CommunityContainer>
      <ControlledTaggedTagsBox
        disabled={isRefetching}
        initSelectedTags={isRefetching ? undefined : selectedTags ?? filterTags}
        noTagPlaceholder={
          !filterTags?.length || isRefetching ? (
            <LoadingTagsContainer>
              <ActivityIndicator />
            </LoadingTagsContainer>
          ) : undefined
        }
        onTagChange={handleTagChange}
      />
    </Container>
  ) : (
    defaultComponent ?? null
  );
};

export default React.memo(CurrentCommunity);
