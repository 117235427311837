/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import { flex, FlexboxProps } from '#styled-system';

export const Message = styled(Text)`
  color: ${(props: any) => props.theme.colors.text};
  font-size: 18px;
`;

export const ButtonContainer = styled.View<FlexboxProps>`
  width: fit-content;
  margin-top: 16px;
  ${flex};
`;

export default null;
