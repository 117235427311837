import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CommentInput from '@/CommentInput';
import CommentStatusMessage from '@/CommentStatusMessage';
import Editor from '@/Editor';
import useCommentLocal from '#hooks/useCommentLocal';
import { createEditorState } from '#lib/draft-js/converter';
import ds from '#lib/draft-js/dataSets';
import { CommentContentBlock_comment$key } from '~/CommentContentBlock_comment.graphql';
import { CommentContentBlock_viewer$key } from '~/CommentContentBlock_viewer.graphql';

import { CommentInputContainer } from './CommentContentBlock.style';

const viewerFragment = graphql`
  fragment CommentContentBlock_viewer on User {
    ...CommentInput_viewer
  }
`;

const commentFragment = graphql`
  fragment CommentContentBlock_comment on Comment {
    id
    parentCommentId
    comment
    status {
      value
    }
    ...CommentStatusMessage_comment
  }
`;

type Props = Omit<
  React.ComponentProps<typeof Editor> &
    React.ComponentProps<typeof CommentInput>,
  | 'viewer'
  | 'comment'
  | 'autoFocus'
  | 'isFullSize'
  | 'onSubmit'
  | 'onCancel'
  | 'editorState'
> & {
  viewer: CommentContentBlock_viewer$key | null;
  comment: CommentContentBlock_comment$key;
};

/**
 * Display comment content but can also switch to editor mode.
 */
const CommentContentBlock = ({
  viewer,
  comment,
  submitText,
  submitButtonColor,
  cancelText,
}: Props) => {
  const viewerData = useFragment(viewerFragment, viewer);
  const commentData = useFragment(commentFragment, comment);

  const { isEditable, closeEditor } = useCommentLocal();

  const editorState = createEditorState(commentData.comment);

  if (commentData.status?.value)
    return <CommentStatusMessage comment={commentData} />;

  return isEditable ? (
    <CommentInputContainer>
      <CommentInput
        viewer={viewerData}
        editorState={editorState}
        commentId={commentData.id}
        parentCommentId={commentData.parentCommentId}
        autoFocus
        isFullSize
        submitText={submitText}
        submitButtonColor={submitButtonColor}
        cancelText={cancelText}
        onSubmit={closeEditor}
        onCancel={closeEditor}
      />
    </CommentInputContainer>
  ) : (
    <Editor
      dataSet={ds.CommentContent}
      editorState={createEditorState(commentData.comment)}
      readOnly
    />
  );
};

export default React.memo(
  CommentContentBlock,
  (prev, next) =>
    prev.comment[' $data']?.comment === next.comment[' $data']?.comment &&
    prev.comment[' $data']?.status?.value ===
      next.comment[' $data']?.status?.value,
);
