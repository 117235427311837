import styled from 'styled-components/native';

export const Container = styled.View`
  flex-direction: column;
`;

export const ItemContainer = styled.View`
  margin-bottom: 12px;
`;

export default null;
