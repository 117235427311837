import { MaterialCommunityIcons } from '@expo/vector-icons';
import fbt from 'fbt';
import React from 'react';
import { Dialog } from 'react-native-paper';
import { createFragmentContainer, graphql } from 'react-relay';

import CreateCommunityForm from '@/CreateCommunityForm';
import ModalCard from '@/ModalCard';
import useAppState from '#hooks/useAppState';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';
import { TagItem } from '#interfaces';
import { CreateCommunityButton_viewer } from '~/CreateCommunityButton_viewer.graphql';

import {
  ActionButton,
  CommunityButton,
  CommunityButtonLabel,
  Message,
} from './CreateCommunityButton.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: CreateCommunityButton_viewer;
  selectedTags?: TagItem[] | null;
};

const CreateCommunityButton = ({ viewer, selectedTags }: Props) => {
  useFbt();

  const [formVisible, setFormVisible] = React.useState(false);
  const [alertVisible, setAlertVisible] = React.useState(false);
  const { colors } = useTheme();
  const { communityCount } = useAppState();

  const openForm = React.useCallback(() => setFormVisible(true), []);

  const closeForm = React.useCallback(() => setFormVisible(false), []);

  const openAlert = React.useCallback(() => setAlertVisible(true), []);

  const closeAlert = React.useCallback(() => setAlertVisible(false), []);

  const handlePress = React.useCallback(
    () => (communityCount && communityCount >= 10 ? openAlert() : openForm()),
    [communityCount, openAlert, openForm],
  );

  return (
    <>
      <CommunityButton onPress={handlePress}>
        <MaterialCommunityIcons
          name="earth-plus"
          size={18}
          color={colors?.text2}
        />
        <CommunityButtonLabel>
          <fbt desc="button label">Create community</fbt>
        </CommunityButtonLabel>
      </CommunityButton>
      <CreateCommunityForm
        viewer={viewer}
        visible={formVisible}
        tags={selectedTags}
        onDismiss={closeForm}
      />
      <ModalCard
        visible={alertVisible}
        title={fbt('Cannot create new community', 'alert message')}
        maxWidth="500px"
        onDismiss={closeAlert}
      >
        <Message>
          <fbt desc="alert message">
            Currently, the maximum limit is 10 communities per user.
          </fbt>
        </Message>
        <Dialog.Actions>
          <ActionButton onPress={closeAlert}>
            <fbt desc="button label">Got it!</fbt>
          </ActionButton>
        </Dialog.Actions>
      </ModalCard>
    </>
  );
};

export default createFragmentContainer(React.memo(CreateCommunityButton), {
  viewer: graphql`
    fragment CreateCommunityButton_viewer on User {
      ...CreateCommunityForm_viewer
    }
  `,
});
