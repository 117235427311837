/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FilterTagsBox_tags = {
    readonly getSuggestedTags: {
        readonly selectedTags: ReadonlyArray<{
            readonly tagId: string;
            readonly slug: string;
            readonly label: string;
        } | null> | null;
        readonly suggestedTags: ReadonlyArray<{
            readonly tag: {
                readonly tagId: string;
                readonly slug: string;
                readonly label: string;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "FilterTagsBox_tags";
};
export type FilterTagsBox_tags$data = FilterTagsBox_tags;
export type FilterTagsBox_tags$key = {
    readonly " $data"?: FilterTagsBox_tags$data;
    readonly " $fragmentRefs": FragmentRefs<"FilterTagsBox_tags">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tagId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "queryFor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FilterTagsBox_tags",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "queryFor",
          "variableName": "queryFor"
        },
        {
          "kind": "Variable",
          "name": "tagIds",
          "variableName": "tagIds"
        }
      ],
      "concreteType": "TagSuggestion",
      "kind": "LinkedField",
      "name": "getSuggestedTags",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TagSuggestionSelectedTags",
          "kind": "LinkedField",
          "name": "selectedTags",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TagSuggestionSuggestedTags",
          "kind": "LinkedField",
          "name": "suggestedTags",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TagSuggestionSuggestedTagsTag",
              "kind": "LinkedField",
              "name": "tag",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '64e2b143c4b1f6004a34bc4ac22fbbfc';
export default node;
