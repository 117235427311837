import fbt from 'fbt';
import React from 'react';
import { ActivityIndicator } from 'react-native';

import ControlledTaggedTagsBox from '@/ControlledTaggedTagsBox';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';

import {
  CommunityContainer,
  CommunityName,
  CommunityNameContainer,
  Container,
  LoadingTagsContainer,
} from './CurrentCommunity.style';

// eslint-disable-next-line no-unused-expressions
fbt;

const CurrentCommunityPlaceholder = () => {
  useFbt();

  const [, { sidebarType }] = useResponsive();

  const isSmallScreen = sidebarType === 'drawer';

  return (
    <Container>
      <CommunityContainer>
        <CommunityNameContainer disabled>
          <CommunityName
            fontSize={isSmallScreen ? '14px' : undefined}
            numberOfLines={2}
            ellipsisMode="tail"
          >
            ...
          </CommunityName>
        </CommunityNameContainer>
      </CommunityContainer>
      <ControlledTaggedTagsBox
        disabled
        initSelectedTags={undefined}
        noTagPlaceholder={
          <LoadingTagsContainer>
            <ActivityIndicator />
          </LoadingTagsContainer>
        }
      />
    </Container>
  );
};

export default React.memo(CurrentCommunityPlaceholder);
