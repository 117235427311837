import Color from 'color';
import merge from 'deepmerge';
import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import Editor from '@/Editor';
import getMetaByKey from '#database/utils/getMetaByKey';
import { MetaKey } from '#enum';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';
import { createEditorState } from '#lib/draft-js/converter';
import ds from '#lib/draft-js/dataSets';
import { LayoutProps, SpaceProps } from '#styled-system';
import { PostCardContent_post$key } from '~/PostCardContent_post.graphql';

import {
  Container,
  ReadMoreButton,
  Title,
  TitleContainer,
} from './PostCardContent.style';

const postFragment = graphql`
  fragment PostCardContent_post on Post {
    title
    previewContent
    meta {
      key
      value
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  post: PostCardContent_post$key;
  content?: string;
  onReadMorePress?: () => void;
} & LayoutProps &
  SpaceProps;

const PostCardContent = ({
  post,
  content,
  onReadMorePress,
  ...props
}: Props) => {
  const postData = useFragment(postFragment, post);

  useFbt();

  const previewContentPoints = getMetaByKey(
    MetaKey.PreviewContentPoints,
    postData.meta,
  );

  const isExpandable =
    previewContentPoints?.value > 4 &&
    (postData.previewContent?.length ?? 0) > 1;

  const { colors, isDarkTheme } = useTheme();
  const [, { lteSm }] = useResponsive();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const readMoreStyles = React.useMemo(
    () => ({
      label: {
        color: colors?.text4,
        fontWeight: '600',
        fontSize: 11,
      },
    }),
    [colors?.text4],
  );

  const previewContent = React.useMemo(() => {
    const [fst, snd] = postData.previewContent ?? [];
    return isExpandable && isExpanded && fst && snd
      ? JSON.stringify(merge(JSON.parse(fst), JSON.parse(snd)))
      : fst;
  }, [isExpandable, isExpanded, postData.previewContent]);

  const titleFgColor = isDarkTheme ? '#7c98b1' : '#035398';
  const titleBgColor = isDarkTheme
    ? '#22282f' // `${Color(colors?.brand).darken(0.2).fade(0.7)}`
    : '#e2e9f2'; // `${Color(colors?.brand).fade(0.88)}`;
  const readMoreBgColor = isDarkTheme
    ? `${Color(colors?.black).lightness(17)}`
    : `${Color(colors?.black).lightness(95)}`;

  const editorState = React.useMemo(
    () => createEditorState(content ?? previewContent),
    [content, previewContent],
  );

  const handleReadmorePress = React.useCallback(() => {
    if ((postData.previewContent?.length ?? 0) > 1) {
      setIsExpanded(true);
    } else if (onReadMorePress) {
      onReadMorePress();
    }
  }, [onReadMorePress, postData.previewContent?.length]);

  return postData.previewContent?.length ? (
    <Container {...props}>
      <TitleContainer bg={titleBgColor}>
        <Title numberOfLines={2} ellipsisMode="tail" color={titleFgColor}>
          {postData.title}
        </Title>
      </TitleContainer>
      <Editor dataSet={ds.PostContent} readOnly editorState={editorState} />
      {isExpandable && !isExpanded && !content && (
        <ReadMoreButton
          position={lteSm ? undefined : 'absolute'}
          bottom={lteSm ? '11px' : '-24px'}
          borderRadius={lteSm ? '12px' : '40px'}
          bg={readMoreBgColor}
          labelStyle={readMoreStyles.label}
          onPress={handleReadmorePress}
        >
          <fbt desc="button label">Read More</fbt>
        </ReadMoreButton>
      )}
    </Container>
  ) : null;
};

export default React.memo(PostCardContent);
