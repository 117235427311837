/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostFeedTagged_posts = {
    readonly taggedPostFeed: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly post: {
                    readonly " $fragmentRefs": FragmentRefs<"Post_post">;
                } | null;
            };
        }>;
    } | null;
    readonly " $refType": "PostFeedTagged_posts";
};
export type PostFeedTagged_posts$data = PostFeedTagged_posts;
export type PostFeedTagged_posts$key = {
    readonly " $data"?: PostFeedTagged_posts$data;
    readonly " $fragmentRefs": FragmentRefs<"PostFeedTagged_posts">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "taggedPostFeed"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 1,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedSort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PostFeedTagged_taggedPosts_Query.graphql.ts')
    }
  },
  "name": "PostFeedTagged_posts",
  "selections": [
    {
      "alias": "taggedPostFeed",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "feedFilter"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "feedSort"
        }
      ],
      "concreteType": "TaggedPostFeed",
      "kind": "LinkedField",
      "name": "__PostFeedTagged_taggedPostFeed_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaggedPostEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaggedPost",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Post",
                  "kind": "LinkedField",
                  "name": "post",
                  "plural": false,
                  "selections": [
                    {
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "viewerId",
                          "variableName": "viewerId"
                        }
                      ],
                      "kind": "FragmentSpread",
                      "name": "Post_post"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '5f151ed66eafe5504cf58d551bab6e7a';
export default node;
