import fbt from 'fbt';
import React from 'react';
import { Image, StyleSheet } from 'react-native';

import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import ArrowImg from '#public/images/arrow.png';

import { Container, Message } from './GetStartedPointer.style';

// eslint-disable-next-line no-unused-expressions
fbt;

const s = StyleSheet.create({
  containerToSidebar: {
    top: 16,
    start: 20,
  },
  containerToDrawer: {
    top: 12,
    start: 14,
  },
  imageToSidebar: {
    width: 48,
    height: 68,
    transform: [{ scaleY: -1 }, { rotate: '24deg' }],
  },
  imageToDrawer: {
    width: 48,
    height: 68,
    transform: [{ rotate: '124deg' }],
  },
  textToSidebar: {
    top: -14,
    transform: [{ rotate: '-8deg' }],
  },
  textToDrawer: {
    top: -2,
    start: -6,
    transform: [{ rotate: '-26deg' }],
  },
});

const GetStartedPointer = () => {
  useFbt();

  const [, { sidebarType, lteSm }] = useResponsive();

  const isDrawer = sidebarType === 'drawer';
  const containerStyle = isDrawer ? s.containerToDrawer : s.containerToSidebar;
  const imageStyle = isDrawer ? s.imageToDrawer : s.imageToSidebar;
  const textStyle = isDrawer ? s.textToDrawer : s.textToSidebar;

  return !lteSm ? (
    <Container style={containerStyle}>
      <Image source={{ uri: ArrowImg }} style={imageStyle} />
      <Message style={textStyle}>Get Started!</Message>
    </Container>
  ) : null;
};

export default React.memo(GetStartedPointer, () => true);
