/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const TitleContainer = styled.View``;

export const Title = styled(Text)`
  color: ${(props: any) => props.theme.colors.text};
  font-weight: bold;
`;

export const List = styled.View`
  margin-top: 6px;
  margin-start: 10px;
`;

export const CommunityName = styled(Text)`
  display: inline-flex;
  margin: 5px 0;
  color: ${(props: any) => props.theme.colors.text2};
  font-size: 16px;
`;

export default null;
