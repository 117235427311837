/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardTemplate_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"PostCardHeader_viewer" | "PostCardFooter_viewer">;
    readonly " $refType": "PostCardTemplate_viewer";
};
export type PostCardTemplate_viewer$data = PostCardTemplate_viewer;
export type PostCardTemplate_viewer$key = {
    readonly " $data"?: PostCardTemplate_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardTemplate_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardTemplate_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCardHeader_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCardFooter_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'bba3cec5a0e55e494bbd594d5b012bc6';
export default node;
