import React from 'react';
import { useWindowDimensions } from 'react-native';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';

import PostCardFooter from '@/PostCardFooter';
import PostCardHeader from '@/PostCardHeader';
import PostStatusMessage from '@/PostStatusMessage';
import useResponsive from '#hooks/useResponsive';
import { RelayConnection } from '#interfaces';
import updatePostScore from '#mutations/UpdatePostScore';
import { LayoutProps, SpaceProps } from '#styled-system';
import { PostCardTemplate_post$key } from '~/PostCardTemplate_post.graphql';
import { PostCardTemplate_viewer$key } from '~/PostCardTemplate_viewer.graphql';

import { Card, CardContainer } from './PostCardTemplate.style';

const viewerFragment = graphql`
  fragment PostCardTemplate_viewer on User {
    ...PostCardHeader_viewer
    ...PostCardFooter_viewer
  }
`;

const postFragment = graphql`
  fragment PostCardTemplate_post on Post
  @argumentDefinitions(viewerId: { type: "MongoID" }) {
    id
    scoreInfo {
      lastUpdatedDate
    }
    createdDate
    status {
      value
    }
    ...PostCardHeader_post @arguments(viewerId: $viewerId)
    ...PostCardFooter_post @arguments(viewerId: $viewerId)
    ...PostStatusMessage_post
  }
`;

type Props = {
  viewer: PostCardTemplate_viewer$key | null;
  post: PostCardTemplate_post$key;
  children: React.ReactElement;
  sidebar?: React.ReactNode;
  connection: RelayConnection;
  borderRadius?: string;
  hideHeader?: boolean;
  hideFooter?: boolean;
  containerStyle?: LayoutProps & SpaceProps;
};

const PostCardTemplate = ({
  viewer,
  post,
  children,
  sidebar,
  connection,
  borderRadius,
  hideHeader,
  hideFooter,
  containerStyle,
}: Props) => {
  const environment = useRelayEnvironment();
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  const [r] = useResponsive();
  const windowWidth = useWindowDimensions().width;
  const cardWidth = r([windowWidth, 708]) as number | undefined;

  React.useEffect(() => {
    if (postData && environment)
      updatePostScore(environment, {
        _id: postData.id,
        postCreatedDate: postData.createdDate,
        lastScoreUpdatedDate: postData.scoreInfo?.lastUpdatedDate,
      });
  }, [environment, postData]);

  return (
    <CardContainer width={r(['100%', undefined])} {...containerStyle}>
      {sidebar}
      <Card
        elevation={1}
        width={r(['100%', '100%', cardWidth])}
        maxWidth={cardWidth}
        borderRadius={borderRadius ?? r([0, undefined])}
      >
        {!postData.status?.value ? (
          <>
            {!hideHeader && (
              <PostCardHeader
                connection={connection}
                viewer={viewerData}
                post={postData}
              />
            )}
            {children}
            {!hideFooter && (
              <PostCardFooter viewer={viewerData} post={postData} />
            )}
          </>
        ) : (
          <PostStatusMessage post={postData} />
        )}
      </Card>
    </CardContainer>
  );
};

export default React.memo(
  PostCardTemplate,
  (prev, next) =>
    !prev.sidebar === !next.sidebar &&
    prev.hideHeader === next.hideHeader &&
    prev.hideFooter === next.hideFooter,
);
