/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeletePostMutationVariables = {
    postId: string;
};
export type DeletePostMutationResponse = {
    readonly postRemoveById: {
        readonly record: {
            readonly id: string;
        } | null;
    } | null;
};
export type DeletePostMutation = {
    readonly response: DeletePostMutationResponse;
    readonly variables: DeletePostMutationVariables;
};



/*
mutation DeletePostMutation(
  $postId: MongoID!
) {
  postRemoveById(_id: $postId) {
    record {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "postId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "postId"
      }
    ],
    "concreteType": "RemoveByIdPostPayload",
    "kind": "LinkedField",
    "name": "postRemoveById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeletePostMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeletePostMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9a105404c1a6ec654369e8de97a772b7",
    "id": null,
    "metadata": {},
    "name": "DeletePostMutation",
    "operationKind": "mutation",
    "text": "mutation DeletePostMutation(\n  $postId: MongoID!\n) {\n  postRemoveById(_id: $postId) {\n    record {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b67128a847ee40f9c92faff26f066884';
export default node;
