import React from 'react';

import { Button, ButtonLabel } from './LinealButton.style';

type Props = {
  children: React.ReactNode;
  fontSize?: string | number;
  color?: string;
  borderColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const LinealButton = ({
  children,
  fontSize,
  color,
  borderColor,
  disabled,
  onPress,
}: Props) => (
  <Button borderColor={borderColor} disabled={disabled} onPress={onPress}>
    <ButtonLabel fontSize={fontSize} color={color}>
      {children}
    </ButtonLabel>
  </Button>
);

export default React.memo(LinealButton);
