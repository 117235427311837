/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommunityList_viewer = {
    readonly id: string;
    readonly " $refType": "CommunityList_viewer";
};
export type CommunityList_viewer$data = CommunityList_viewer;
export type CommunityList_viewer$key = {
    readonly " $data"?: CommunityList_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CommunityList_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunityList_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '1ab71feb2d90b815c0b0fac727b05645';
export default node;
