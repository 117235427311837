/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReportPostForm_post = {
    readonly id: string;
    readonly " $refType": "ReportPostForm_post";
};
export type ReportPostForm_post$data = ReportPostForm_post;
export type ReportPostForm_post$key = {
    readonly " $data"?: ReportPostForm_post$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportPostForm_post">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportPostForm_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
(node as any).hash = 'e177c3b370a3859d03d0339d2da769db';
export default node;
