import React from 'react';

import useResponsive from '#hooks/useResponsive';

import { Container } from './FeedMarginals.style';

type Props = {
  children: React.ReactNode;
};

const FeedMarginals = ({ children }: Props): React.ReactElement => {
  const [, { lteSm }] = useResponsive();

  return <Container ms={lteSm ? '0px' : undefined}>{children}</Container>;
};

export default React.memo(FeedMarginals);
