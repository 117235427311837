/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react';
import { Avatar as PaperAvatar } from 'react-native-paper';

import Avatar from '@/Avatar';

import colorHash from './colorHash';
import { Container, ImageContainer, Label } from './ImageWithText.style';

type Props = {
  userId?: string | null;
  size?: number;
  image?: string | null;
  icon?: string | React.ReactElement;
  text: string;
  chipComponent?: React.ReactElement;
  color?: string;
  onPress?: () => void;
};

const ImageWithText = ({
  userId,
  size = 24,
  image,
  icon,
  text,
  chipComponent,
  color,
  onPress,
}: Props): React.ReactElement | null => {
  const getChip = React.useMemo(() => {
    if (chipComponent) return chipComponent;

    const imageChip = (image || userId) && (
      <Avatar userId={userId} imageUri={image} size={size} />
    );
    const iconChip = !imageChip && icon && (
      <PaperAvatar.Icon
        size={size}
        icon={typeof icon === 'string' ? icon : () => icon}
        color={color}
      />
    );
    const textChip = !iconChip && text[0] && (
      <PaperAvatar.Text
        size={size}
        label={text[0]}
        color={color ?? colorHash.hex(text)}
      />
    );

    return imageChip || iconChip || textChip || <Avatar size={size} />;
  }, [chipComponent, color, icon, image, size, text, userId]);

  return text.length ? (
    <Container onPress={onPress}>
      <ImageContainer>{getChip}</ImageContainer>
      <Label>{text}</Label>
    </Container>
  ) : null;
};

export default React.memo(ImageWithText);
