/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentThread_comment = {
    readonly id: string;
    readonly parentCommentId: string | null;
    readonly featuredSubComments?: ReadonlyArray<{
        readonly id: string;
        readonly featuredSubComments?: ReadonlyArray<{
            readonly id: string;
            readonly featuredSubComments?: ReadonlyArray<{
                readonly id: string;
                readonly featuredSubComments?: ReadonlyArray<{
                    readonly id: string;
                    readonly " $fragmentRefs": FragmentRefs<"CommentBlock_comment">;
                } | null>;
                readonly " $fragmentRefs": FragmentRefs<"CommentBlock_comment">;
            } | null>;
            readonly " $fragmentRefs": FragmentRefs<"CommentBlock_comment">;
        } | null>;
        readonly " $fragmentRefs": FragmentRefs<"CommentBlock_comment">;
    } | null>;
    readonly " $fragmentRefs": FragmentRefs<"CommentBlock_comment">;
    readonly " $refType": "CommentThread_comment";
};
export type CommentThread_comment$data = CommentThread_comment;
export type CommentThread_comment$key = {
    readonly " $data"?: CommentThread_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentThread_comment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": [
    {
      "kind": "Variable",
      "name": "viewerId",
      "variableName": "viewerId"
    }
  ],
  "kind": "FragmentSpread",
  "name": "CommentBlock_comment"
},
v2 = [
  {
    "kind": "Variable",
    "name": "featuredSubCommentType",
    "variableName": "featuredSubCommentType"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "featured",
      "kind": "LocalArgument",
      "name": "featuredSubCommentType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "getFstSubComments"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "getFthSubComments"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "getSndSubComments"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "getTrdSubComments"
    },
    {
      "defaultValue": "FEATURED_SCORE",
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentThread_comment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentCommentId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "condition": "getFstSubComments",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": (v2/*: any*/),
          "concreteType": "Comment",
          "kind": "LinkedField",
          "name": "featuredSubComments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "condition": "getSndSubComments",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": (v2/*: any*/),
                  "concreteType": "Comment",
                  "kind": "LinkedField",
                  "name": "featuredSubComments",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "condition": "getTrdSubComments",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": (v2/*: any*/),
                          "concreteType": "Comment",
                          "kind": "LinkedField",
                          "name": "featuredSubComments",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "condition": "getFthSubComments",
                              "kind": "Condition",
                              "passingValue": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": (v2/*: any*/),
                                  "concreteType": "Comment",
                                  "kind": "LinkedField",
                                  "name": "featuredSubComments",
                                  "plural": true,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v1/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ]
                            }
                          ],
                          "storageKey": null
                        }
                      ]
                    }
                  ],
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();
(node as any).hash = 'f6c8b357f670917928430e47941538b7';
export default node;
