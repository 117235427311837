import fbt from 'fbt';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import PostCardCommentButton from '@/PostCardCommentButton';
import TaggedTagsModal from '@/TaggedTagsModal';
import Vote from '@/Vote';
import { postVoted } from '#analytics/app/post';
import { ObjectType, VoteType } from '#enum';
import useFbt from '#hooks/useFbt';
import useFullPostAnalytics from '#hooks/useFullPostAnalytics';
import useResponsive from '#hooks/useResponsive';
import { PostCardFooter_post } from '~/PostCardFooter_post.graphql';
import { PostCardFooter_viewer } from '~/PostCardFooter_viewer.graphql';

import {
  Container,
  LeftContainer,
  OptionContainer,
  RightContainer,
  VoteContainer,
} from './PostCardFooter.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: PostCardFooter_viewer | null;
  post: PostCardFooter_post;
};

const PostCardFooter = ({ viewer, post }: Props): React.ReactElement => {
  useFbt();

  const [, { lteSm }] = useResponsive();
  const fullPostProps = useFullPostAnalytics(post);
  const voteCallback = React.useCallback(
    async ({
      prevVote,
      newVote,
    }: {
      prevVote: VoteType;
      newVote: VoteType;
    }) => {
      await postVoted({ prevVote, newVote, ...fullPostProps });
    },
    [fullPostProps],
  );

  const voteScore = (post?.vote?.upvotes ?? 0) - (post?.vote?.downvotes ?? 0);

  return (
    <Container>
      {lteSm && (
        <LeftContainer marginStart={lteSm ? '6px' : '16px'}>
          <VoteContainer>
            <Vote
              viewer={viewer}
              objectType={ObjectType.Post}
              objectId={post?.id}
              userVote={post?.userVote as { id: string; vote: VoteType }}
              voteScore={voteScore}
              voteCallback={voteCallback}
              isHorizontal
              size="m"
            />
          </VoteContainer>
        </LeftContainer>
      )}
      <RightContainer marginEnd={lteSm ? '6px' : '16px'}>
        <OptionContainer>
          <PostCardCommentButton viewer={viewer} post={post} />
        </OptionContainer>
        {lteSm && (
          <OptionContainer>
            <TaggedTagsModal
              modalLabel={fbt('Post Tags', "viewer's post tagged tags")}
              objectType={ObjectType.Post}
              objectId={post?.id}
              viewer={viewer}
              post={post}
            />
          </OptionContainer>
        )}
      </RightContainer>
    </Container>
  );
};

export default createFragmentContainer(React.memo(PostCardFooter), {
  viewer: graphql`
    fragment PostCardFooter_viewer on User {
      ...Vote_viewer
      ...TaggedTagsModal_viewer
      ...PostCardCommentButton_viewer
    }
  `,
  post: graphql`
    fragment PostCardFooter_post on Post
    @argumentDefinitions(viewerId: { type: "MongoID" }) {
      id
      vote {
        upvotes
        downvotes
      }
      count {
        shared
        tagged
      }
      meta {
        key
        value
      }
      userVote(userId: $viewerId) {
        id
        vote
      }
      ...PostCardCommentButton_post
      ...TaggedTagsModal_post @arguments(viewerId: $viewerId)
    }
  `,
});
