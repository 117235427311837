/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ReportPostForm_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"ReportForm_viewer">;
    readonly " $refType": "ReportPostForm_viewer";
};
export type ReportPostForm_viewer$data = ReportPostForm_viewer;
export type ReportPostForm_viewer$key = {
    readonly " $data"?: ReportPostForm_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportPostForm_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportPostForm_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReportForm_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '204d4ebcb6de482ed4c5c6220a3d6091';
export default node;
