import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { Link } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import DeletedUser from '@/DeletedUser';
import ImageWithText from '@/ImageWithText';
import PostCardHeaderMenu from '@/PostCardHeaderMenu';
import TimeAgo from '@/TimeAgo';
import getImageBySize from '#database/utils/getImageBySize';
import { botId } from '#database/utils/systemObjectIds';
import { ImageSize } from '#enum';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';
import { RelayConnection } from '#interfaces';
import { PostCardHeader_post } from '~/PostCardHeader_post.graphql';
import { PostCardHeader_viewer } from '~/PostCardHeader_viewer.graphql';

import {
  CreatorContainer,
  DateContainer,
  HeaderContainer,
} from './PostCardHeader.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  connection: RelayConnection;
  post: PostCardHeader_post;
  viewer: PostCardHeader_viewer | null;
};

const PostCardHeader = ({
  connection,
  post,
  viewer,
}: Props): React.ReactElement => {
  useFbt();

  const { colors, isDarkTheme } = useTheme();
  const [r] = useResponsive();
  const { avatar, username, displayName, fullName } = {
    ...post?.createdBy,
  };

  const creatorLinkWeb = React.useMemo(
    () => ({ path: '/[username]', as: `/${username}` }),
    [username],
  );
  const postCreator = React.useMemo(() => {
    const imageSize = 24;
    const color = isDarkTheme ? colors?.text3 : colors?.text2;

    if (post?.createdByUserId === botId)
      return (
        <MaterialCommunityIcons
          name="robot-vacuum"
          size={imageSize}
          color={color}
        />
      );

    if (!post?.createdBy?.id) return <DeletedUser />;

    return post?.createdBy ? (
      <ImageWithText
        userId={post.createdBy.id}
        image={getImageBySize(avatar, [ImageSize.Thumbnail, ImageSize.Avatar])}
        icon={
          username ? undefined : (
            <AntDesign name="frowno" size={imageSize} color={color} />
          )
        }
        text={
          displayName ??
          fullName ??
          username ??
          fbt('deleted user', 'deleted user name')
        }
      />
    ) : null;
  }, [
    avatar,
    colors?.text2,
    colors?.text3,
    displayName,
    fullName,
    isDarkTheme,
    post?.createdBy,
    post?.createdByUserId,
    username,
  ]);

  return (
    <HeaderContainer paddingX={r(['12px', '12px', undefined])}>
      <CreatorContainer>
        {username ? (
          <Link isText={false} routeName="User" web={creatorLinkWeb}>
            {postCreator}
          </Link>
        ) : (
          postCreator
        )}
      </CreatorContainer>
      {post?.createdDate && post?.createdByUserId !== botId ? (
        <DateContainer>
          <TimeAgo>{post.createdDate}</TimeAgo>
        </DateContainer>
      ) : null}
      <PostCardHeaderMenu connection={connection} post={post} viewer={viewer} />
    </HeaderContainer>
  );
};

export default createFragmentContainer(PostCardHeader, {
  viewer: graphql`
    fragment PostCardHeader_viewer on User {
      ...PostCardHeaderMenu_viewer
    }
  `,
  post: graphql`
    fragment PostCardHeader_post on Post
    @argumentDefinitions(viewerId: { type: "MongoID" }) {
      ...PostCardHeaderMenu_post @arguments(viewerId: $viewerId)
      createdDate
      createdByUserId
      createdBy {
        id
        username
        fullName
        displayName
        avatar {
          sizes {
            key
            url
          }
        }
      }
    }
  `,
});
