import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import cleanDeep from 'clean-deep';
import { useRouting } from 'expo-next-react-navigation';
import React from 'react';
import { StyleSheet } from 'react-native';

import RoundIconButton from '@/RoundIconButton';
import { FeedScope, FeedSort, QueryParam, TaggedFeedSort } from '#enum';
import useSession from '#hooks/useSession';
import useTheme from '#hooks/useTheme';
import { feedSort, feedSortTagged } from '#relay/helpers/feed';

import { Container } from './FeedSideSorter.style';

const iconSize = 24;

const s = StyleSheet.create({
  button: {
    marginBottom: 12,
  },
});

const FeedSideSorter = (): React.ReactElement => {
  const [activeSorting, setActiveSorting] = React.useState<
    FeedSort | TaggedFeedSort
  >();
  const [session] = useSession();
  const { colors } = useTheme();
  const { navigate, getParam } = useRouting();

  const feedQuery = getParam(QueryParam.FeedQuery);
  const feedScope = getParam(QueryParam.FeedScope);
  const isTaggedScope = feedScope === FeedScope.Tagged && session?.user;
  const communityName = getParam(QueryParam.CommunityName);
  const encodedCommunityId = getParam(QueryParam.EncodedUserCommunityId);

  const icons = React.useMemo(
    () => ({
      rssFeed: <MaterialIcons name="rss-feed" size={iconSize} />,
      whatshot: <MaterialIcons name="whatshot" size={iconSize} />,
      clockFast: <MaterialCommunityIcons name="clock-fast" size={iconSize} />,
      number1: (
        <MaterialCommunityIcons
          name="numeric-1-box-multiple-outline"
          size={iconSize - 2}
        />
      ),
    }),
    [],
  );

  const handlePress = React.useCallback(
    (sort?: 'newest' | 'hot') => () => {
      navigate({
        routeName: 'Home',
        web: { path: '/', shallow: true },
        params: cleanDeep({
          [QueryParam.FeedQuery]: feedQuery,
          [QueryParam.FeedScope]: feedScope,
          [QueryParam.EncodedUserCommunityId]: encodedCommunityId,
          [QueryParam.CommunityName]: communityName,
          [QueryParam.FeedSort]: sort,
        }),
      });
    },
    [communityName, encodedCommunityId, feedQuery, feedScope, navigate],
  );

  React.useEffect(() => {
    const sort: string | undefined = getParam(QueryParam.FeedSort);
    const current = isTaggedScope ? feedSortTagged(sort) : feedSort(sort);
    setActiveSorting(current);
  }, [getParam, isTaggedScope]);

  return (
    <Container>
      {!isTaggedScope && (
        <>
          <RoundIconButton
            isActive={activeSorting === FeedSort.FeaturedScore}
            color={colors?.orange}
            style={s.button}
            iconComponent={icons.rssFeed}
            onPress={handlePress()}
          />
          <RoundIconButton
            isActive={activeSorting === FeedSort.HotScore}
            color={colors?.red}
            style={s.button}
            iconComponent={icons.whatshot}
            onPress={handlePress('hot')}
          />
          <RoundIconButton
            isActive={activeSorting === FeedSort.Newest}
            color={colors?.blue}
            style={s.button}
            iconComponent={icons.clockFast}
            onPress={handlePress('newest')}
          />
        </>
      )}
      {isTaggedScope && (
        <>
          <RoundIconButton
            isActive={activeSorting === TaggedFeedSort.TaggedDateDesc}
            color={colors?.orange}
            style={s.button}
            iconComponent={icons.number1}
            onPress={handlePress()}
          />
          <RoundIconButton
            isActive={activeSorting === TaggedFeedSort.Newest}
            color={colors?.blue}
            style={s.button}
            iconComponent={icons.clockFast}
            onPress={handlePress('newest')}
          />
        </>
      )}
    </Container>
  );
};

export default React.memo(FeedSideSorter);
