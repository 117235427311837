/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostFeed_posts = {
    readonly " $fragmentRefs": FragmentRefs<"PostFeedMain_posts" | "PostFeedChannel_posts">;
    readonly " $refType": "PostFeed_posts";
};
export type PostFeed_posts$data = PostFeed_posts;
export type PostFeed_posts$key = {
    readonly " $data"?: PostFeed_posts$data;
    readonly " $fragmentRefs": FragmentRefs<"PostFeed_posts">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "viewerId",
  "variableName": "viewerId"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedSort"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isInCommunity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userCommunityId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostFeed_posts",
  "selections": [
    {
      "condition": "isInCommunity",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "feedFilter",
              "variableName": "feedFilter"
            },
            {
              "kind": "Variable",
              "name": "feedSort",
              "variableName": "feedSort"
            },
            (v0/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "PostFeedMain_posts"
        }
      ]
    },
    {
      "condition": "isInCommunity",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "sort",
              "variableName": "feedSort"
            },
            {
              "kind": "Variable",
              "name": "userCommunityId",
              "variableName": "userCommunityId"
            },
            (v0/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "PostFeedChannel_posts"
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '7c132442162baadb7a006b257c6ce3c2';
export default node;
