/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EnumImageSizesKey = "avatar" | "medium" | "original" | "px1080" | "px720" | "thumbnail" | "%future added value";
export type PostCardHeader_post = {
    readonly createdDate: string | null;
    readonly createdByUserId: string;
    readonly createdBy: {
        readonly id: string;
        readonly username: string;
        readonly fullName: string;
        readonly displayName: string | null;
        readonly avatar: {
            readonly sizes: ReadonlyArray<{
                readonly key: EnumImageSizesKey | null;
                readonly url: string | null;
            } | null> | null;
        } | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"PostCardHeaderMenu_post">;
    readonly " $refType": "PostCardHeader_post";
};
export type PostCardHeader_post$data = PostCardHeader_post;
export type PostCardHeader_post$key = {
    readonly " $data"?: PostCardHeader_post$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardHeader_post">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardHeader_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdByUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageSizes",
              "kind": "LinkedField",
              "name": "sizes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "viewerId",
          "variableName": "viewerId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PostCardHeaderMenu_post"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
(node as any).hash = '9e4f17691585b9ddcc6307a5101b54c7';
export default node;
