/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type IndexPageSidebar_data = {
    readonly " $fragmentRefs": FragmentRefs<"SidebarTagsBox_data" | "CommunityList_userCommunities">;
    readonly " $refType": "IndexPageSidebar_data";
};
export type IndexPageSidebar_data$data = IndexPageSidebar_data;
export type IndexPageSidebar_data$key = {
    readonly " $data"?: IndexPageSidebar_data$data;
    readonly " $fragmentRefs": FragmentRefs<"IndexPageSidebar_data">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "viewerId",
  "variableName": "viewerId"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInCommunity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "queryFor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userCommunityId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndexPageSidebar_data",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "queryFor",
          "variableName": "queryFor"
        },
        {
          "kind": "Variable",
          "name": "tagIds",
          "variableName": "tagIds"
        },
        {
          "kind": "Variable",
          "name": "userCommunityId",
          "variableName": "userCommunityId"
        },
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "SidebarTagsBox_data"
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "CommunityList_userCommunities"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = 'e00a2b4be670c76344d80ac6f58f957c';
export default node;
