/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

export const Container = styled.View`
  display: inline-flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const AvatarContainer = styled.View`
  display: flex;
  flex: 0 0 auto;
  margin-bottom: 4px;
`;

export const VerticalLineButton = styled.Pressable`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const VerticalLine = styled.View`
  display: flex;
  width: 2px;
  background-color: ${(props: any) => props.theme.colors.blackOpac05};
  padding-horizontal: auto;
`;

export default null;
