import fbt from 'fbt';
import React from 'react';

import CommentActionButton from '@/CommentActionButton';
import useCommentLocal from '#hooks/useCommentLocal';
import useFbt from '#hooks/useFbt';

// eslint-disable-next-line no-unused-expressions
fbt;

const CommentButtonEdit = () => {
  useFbt();

  const { openReplyEditor } = useCommentLocal();

  return (
    <CommentActionButton onPress={openReplyEditor}>
      <fbt desc="text button">Reply</fbt>
    </CommentActionButton>
  );
};

export default React.memo(CommentButtonEdit);
