/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type EnumUserCommunityRole = "Admin" | "Member" | "Moderator" | "%future added value";
export type UpdateByIdUserCommunityInput = {
    communityId?: string | null;
    userId?: string | null;
    role?: EnumUserCommunityRole | null;
    memberSince?: string | null;
    status?: UpdateByIdDocumentStatusInput | null;
    lastVisitedDate?: string | null;
};
export type UpdateByIdDocumentStatusInput = {
    value?: EnumDocumentStatusValue | null;
    reason?: string | null;
    timestamp?: string | null;
};
export type CurrentCommunityVisitCommunityMutationVariables = {
    _id: string;
    record: UpdateByIdUserCommunityInput;
};
export type CurrentCommunityVisitCommunityMutationResponse = {
    readonly userCommunityUpdateById: {
        readonly record: {
            readonly lastVisitedDate: string | null;
        } | null;
    } | null;
};
export type CurrentCommunityVisitCommunityMutation = {
    readonly response: CurrentCommunityVisitCommunityMutationResponse;
    readonly variables: CurrentCommunityVisitCommunityMutationVariables;
};



/*
mutation CurrentCommunityVisitCommunityMutation(
  $_id: MongoID!
  $record: UpdateByIdUserCommunityInput!
) {
  userCommunityUpdateById(_id: $_id, record: $record) {
    record {
      lastVisitedDate
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "_id"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdUserCommunityPayload",
    "kind": "LinkedField",
    "name": "userCommunityUpdateById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserCommunity",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastVisitedDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentCommunityVisitCommunityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurrentCommunityVisitCommunityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f0fb3a625d6accbcf77fff712f67eaaa",
    "id": null,
    "metadata": {},
    "name": "CurrentCommunityVisitCommunityMutation",
    "operationKind": "mutation",
    "text": "mutation CurrentCommunityVisitCommunityMutation(\n  $_id: MongoID!\n  $record: UpdateByIdUserCommunityInput!\n) {\n  userCommunityUpdateById(_id: $_id, record: $record) {\n    record {\n      lastVisitedDate\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c86a5fd7a87751bd15e4e026cb509b8e';
export default node;
