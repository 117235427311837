/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SidebarTagsBox_data = {
    readonly " $fragmentRefs": FragmentRefs<"CurrentCommunity_userCommunity" | "FilterTagsBox_tags">;
    readonly " $refType": "SidebarTagsBox_data";
};
export type SidebarTagsBox_data$data = SidebarTagsBox_data;
export type SidebarTagsBox_data$key = {
    readonly " $data"?: SidebarTagsBox_data$data;
    readonly " $fragmentRefs": FragmentRefs<"SidebarTagsBox_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "queryFor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userCommunityId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarTagsBox_data",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "userCommunityId",
          "variableName": "userCommunityId"
        },
        {
          "kind": "Variable",
          "name": "viewerId",
          "variableName": "viewerId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "CurrentCommunity_userCommunity"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "queryFor",
          "variableName": "queryFor"
        },
        {
          "kind": "Variable",
          "name": "tagIds",
          "variableName": "tagIds"
        }
      ],
      "kind": "FragmentSpread",
      "name": "FilterTagsBox_tags"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '73d6fb1e8ccbcd11f356e1b8cbfc680d';
export default node;
