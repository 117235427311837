import cleanDeep from 'clean-deep';
import { Link, useRouting } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import { createRefetchContainer, graphql, RelayRefetchProp } from 'react-relay';

import { decodeObjectId, encodeObjectId } from '#database/utils/base64ObjectId';
import { QueryParam } from '#enum';
import useAppState from '#hooks/useAppState';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import { CommunityList_userCommunities } from '~/CommunityList_userCommunities.graphql';
import { CommunityList_viewer } from '~/CommunityList_viewer.graphql';

import {
  CommunityName,
  List,
  Title,
  TitleContainer,
} from './CommunityList.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: CommunityList_viewer | null;
  userCommunities: CommunityList_userCommunities;
  relay: RelayRefetchProp;
};

// TODO: add action button to edit/delete community directly through the list
const CommunityList = ({ viewer, userCommunities, relay }: Props) => {
  useFbt();

  const { communityCount, setCommunityCount } = useAppState();
  const [, { sidebarType }] = useResponsive();
  const { getParam } = useRouting();
  const encodedUserCommunityId = getParam(QueryParam.EncodedUserCommunityId);
  const userCommunityId = decodeObjectId(encodedUserCommunityId as string);
  const userCommunityIdRef = React.useRef(userCommunityId);

  const isDrawer = sidebarType === 'drawer';
  const filteredUserCommunities = userCommunities?.userCommunityFindMany
    ?.filter((userCommunity) => userCommunity.id !== userCommunityId)
    .sort((a, b) => {
      const aVisit = a.lastVisitedDate ?? 0;
      const bVisit = b.lastVisitedDate ?? 0;

      if (aVisit < bVisit) return 1;
      if (aVisit > bVisit) return -1;
      return 0;
    })
    .slice(0, 10);

  React.useEffect(() => {
    if (
      viewer?.id &&
      userCommunityId !== userCommunityIdRef.current &&
      // from anywhere to community or from community to home
      (userCommunityId || (!userCommunityId && userCommunityIdRef.current))
    ) {
      userCommunityIdRef.current = userCommunityId;
      relay.refetch({ viewerId: viewer.id });
    }
  }, [encodedUserCommunityId, relay, userCommunityId, viewer?.id]);

  React.useEffect(() => {
    const count = userCommunities?.userCommunityFindMany.length;
    if (communityCount !== count) setCommunityCount(count);
  }, [
    communityCount,
    setCommunityCount,
    userCommunities?.userCommunityFindMany.length,
  ]);

  return userCommunities?.userCommunityFindMany?.length ? (
    <>
      <TitleContainer>
        <Title fontSize={isDrawer ? '14px' : undefined}>
          <fbt desc="section title">Awesome Communities</fbt>
        </Title>
      </TitleContainer>
      <List>
        {filteredUserCommunities?.map((userCommunity) =>
          userCommunity ? (
            <Link
              key={userCommunity.id}
              isText={false}
              routeName="Home"
              web={{ path: '/', shallow: true }}
              params={cleanDeep({
                [QueryParam.EncodedUserCommunityId]: encodeObjectId(
                  userCommunity.id,
                ),
                [QueryParam.CommunityName]: userCommunity.community?.name,
                [QueryParam.FeedSort]: getParam(QueryParam.FeedSort),
              })}
            >
              <CommunityName
                numberOfLines={1}
                ellipsisMode="tail"
                fontSize={isDrawer ? '14px' : undefined}
              >
                {userCommunity.community?.name}
              </CommunityName>
            </Link>
          ) : null,
        )}
      </List>
    </>
  ) : null;
};

export default createRefetchContainer(
  React.memo(CommunityList),
  {
    viewer: graphql`
      fragment CommunityList_viewer on User {
        id
      }
    `,
    userCommunities: graphql`
      fragment CommunityList_userCommunities on Query
      @argumentDefinitions(viewerId: { type: "MongoID" }) {
        userCommunityFindMany(filter: { userId: $viewerId }) {
          id
          lastVisitedDate
          community {
            name
          }
        }
      }
    `,
  },
  graphql`
    query CommunityListRefetchQuery($viewerId: MongoID) {
      ...CommunityList_userCommunities @arguments(viewerId: $viewerId)
    }
  `,
);
