import constate from 'constate';
import { useCallback, useMemo, useState } from 'react';

const useCounterHook = (): {
  count: number;
  increment: (num?: number | undefined) => void;
  reduction: (num?: number | undefined) => void;
} => {
  const [count, setCount] = useState<number>(0);

  const increment = useCallback(
    (num?: number) => setCount((prev) => prev + (num ?? 1)),
    [],
  );
  const reduction = useCallback(
    (num?: number) => setCount((prev) => prev - (num ?? 1)),
    [],
  );

  const contextValue = useMemo(
    () => ({
      count,
      increment,
      reduction,
    }),
    [count, increment, reduction],
  );

  return contextValue;
};

const [CounterProvider, useCounter] = constate(useCounterHook);

export { CounterProvider, useCounterHook };
export default useCounter;
