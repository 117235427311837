/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumVoteVote = "Downvote" | "Unvote" | "Upvote" | "%future added value";
export type VoteMutationVariables = {
    userId: string;
    objectType: string;
    objectId: string;
    vote: string;
    incUpvote?: number | null;
    incDownvote?: number | null;
    postIncVote?: boolean | null;
    commentIncVote?: boolean | null;
};
export type VoteMutationResponse = {
    readonly voteUpsert: {
        readonly id: string;
        readonly vote: EnumVoteVote;
    } | null;
    readonly postVote?: {
        readonly id: string;
        readonly vote: {
            readonly upvotes: number | null;
            readonly downvotes: number | null;
        } | null;
    };
    readonly commentVote?: {
        readonly id: string;
        readonly vote: {
            readonly upvotes: number | null;
            readonly downvotes: number | null;
        } | null;
    };
};
export type VoteMutation = {
    readonly response: VoteMutationResponse;
    readonly variables: VoteMutationVariables;
};



/*
mutation VoteMutation(
  $userId: MongoID!
  $objectType: String!
  $objectId: MongoID!
  $vote: String!
  $incUpvote: Int
  $incDownvote: Int
  $postIncVote: Boolean = false
  $commentIncVote: Boolean = false
) {
  voteUpsert(userId: $userId, objectType: $objectType, objectId: $objectId, vote: $vote) {
    id
    vote
  }
  postVote(_id: $objectId, incUpvote: $incUpvote, incDownvote: $incDownvote) @include(if: $postIncVote) {
    id
    vote {
      upvotes
      downvotes
    }
  }
  commentVote(_id: $objectId, incUpvote: $incUpvote, incDownvote: $incDownvote) @include(if: $commentIncVote) {
    id
    vote {
      upvotes
      downvotes
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "commentIncVote"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "incDownvote"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "incUpvote"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objectId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objectType"
},
v5 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "postIncVote"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vote"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "_id",
    "variableName": "objectId"
  },
  {
    "kind": "Variable",
    "name": "incDownvote",
    "variableName": "incDownvote"
  },
  {
    "kind": "Variable",
    "name": "incUpvote",
    "variableName": "incUpvote"
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "upvotes",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "downvotes",
    "storageKey": null
  }
],
v11 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objectId",
        "variableName": "objectId"
      },
      {
        "kind": "Variable",
        "name": "objectType",
        "variableName": "objectType"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      },
      {
        "kind": "Variable",
        "name": "vote",
        "variableName": "vote"
      }
    ],
    "concreteType": "Vote",
    "kind": "LinkedField",
    "name": "voteUpsert",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "vote",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "condition": "postIncVote",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "postVote",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PostVote",
            "kind": "LinkedField",
            "name": "vote",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  {
    "condition": "commentIncVote",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "commentVote",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CommentVote",
            "kind": "LinkedField",
            "name": "vote",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VoteMutation",
    "selections": (v11/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VoteMutation",
    "selections": (v11/*: any*/)
  },
  "params": {
    "cacheID": "6f3cf18221618f02c04cdf0abcf0f278",
    "id": null,
    "metadata": {},
    "name": "VoteMutation",
    "operationKind": "mutation",
    "text": "mutation VoteMutation(\n  $userId: MongoID!\n  $objectType: String!\n  $objectId: MongoID!\n  $vote: String!\n  $incUpvote: Int\n  $incDownvote: Int\n  $postIncVote: Boolean = false\n  $commentIncVote: Boolean = false\n) {\n  voteUpsert(userId: $userId, objectType: $objectType, objectId: $objectId, vote: $vote) {\n    id\n    vote\n  }\n  postVote(_id: $objectId, incUpvote: $incUpvote, incDownvote: $incDownvote) @include(if: $postIncVote) {\n    id\n    vote {\n      upvotes\n      downvotes\n    }\n  }\n  commentVote(_id: $objectId, incUpvote: $incUpvote, incDownvote: $incDownvote) @include(if: $commentIncVote) {\n    id\n    vote {\n      upvotes\n      downvotes\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1a3a006877b842172622c2fca23e9d0b';
export default node;
