/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useSuggestedTagsQueryVariables = {
    tagIds?: Array<string | null> | null;
    queryFor: string;
    userId?: string | null;
};
export type useSuggestedTagsQueryResponse = {
    readonly getSuggestedTags: {
        readonly suggestedTags: ReadonlyArray<{
            readonly tag: {
                readonly tagId: string;
                readonly slug: string;
                readonly label: string;
            } | null;
        } | null> | null;
    } | null;
};
export type useSuggestedTagsQuery = {
    readonly response: useSuggestedTagsQueryResponse;
    readonly variables: useSuggestedTagsQueryVariables;
};



/*
query useSuggestedTagsQuery(
  $tagIds: [String]
  $queryFor: String!
  $userId: String
) {
  getSuggestedTags(tagIds: $tagIds, queryFor: $queryFor, userId: $userId) {
    suggestedTags {
      tag {
        tagId
        slug
        label
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryFor"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "queryFor",
        "variableName": "queryFor"
      },
      {
        "kind": "Variable",
        "name": "tagIds",
        "variableName": "tagIds"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "TagSuggestion",
    "kind": "LinkedField",
    "name": "getSuggestedTags",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagSuggestionSuggestedTags",
        "kind": "LinkedField",
        "name": "suggestedTags",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagSuggestionSuggestedTagsTag",
            "kind": "LinkedField",
            "name": "tag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tagId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSuggestedTagsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useSuggestedTagsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e1dc375dc42789f1e244c122205dbecf",
    "id": null,
    "metadata": {},
    "name": "useSuggestedTagsQuery",
    "operationKind": "query",
    "text": "query useSuggestedTagsQuery(\n  $tagIds: [String]\n  $queryFor: String!\n  $userId: String\n) {\n  getSuggestedTags(tagIds: $tagIds, queryFor: $queryFor, userId: $userId) {\n    suggestedTags {\n      tag {\n        tagId\n        slug\n        label\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5d891650eea3f0f0abae4872e2f538c5';
export default node;
