/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommunityList_userCommunities = {
    readonly userCommunityFindMany: ReadonlyArray<{
        readonly id: string;
        readonly lastVisitedDate: string | null;
        readonly community: {
            readonly name: string;
        } | null;
    }>;
    readonly " $refType": "CommunityList_userCommunities";
};
export type CommunityList_userCommunities$data = CommunityList_userCommunities;
export type CommunityList_userCommunities$key = {
    readonly " $data"?: CommunityList_userCommunities$data;
    readonly " $fragmentRefs": FragmentRefs<"CommunityList_userCommunities">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunityList_userCommunities",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "viewerId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "UserCommunity",
      "kind": "LinkedField",
      "name": "userCommunityFindMany",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastVisitedDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Community",
          "kind": "LinkedField",
          "name": "community",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '9bd5c93c37823f94fdc91ffcbe6ac718';
export default node;
