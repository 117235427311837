/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.Pressable`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 16px;
  margin-end: auto;
`;

export const Button = styled(Text)`
  margin-end: 6px;
  color: ${(props: any) => props.theme.colors.text4};
  font-weight: bolder;
  font-size: 12px;
`;

export default null;
