import { ReadonlyDeep } from 'type-fest';

import { IKeyValue } from '#database/models';
import { MetaKey } from '#enum';

const getMetaByKey = (
  key: MetaKey,
  meta: ReadonlyDeep<IKeyValue[]> | null,
): ReadonlyDeep<IKeyValue> | null | undefined =>
  meta?.find((obj) => obj?.key === key);

export default getMetaByKey;
