/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  margin-top: 6px;
`;

export const CommunityContainer = styled.View`
  z-index: -1;
  display: flex;
  flex-flow: row wrap;
`;

export const CommunityNameContainer = styled.Pressable`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 6px 16px 6px 0;
`;

export const CommunityName = styled(Text)`
  display: inline-flex;
  flex-shrink: 1;
  margin-end: 3px;
  color: ${(props: any) => props.theme.colors.text};
  font-weight: bold;
  font-size: 16px;
`;

export const CommandsContainer = styled.View`
  display: flex;
  flex-flow: row wrap;
`;

export const Command = styled.Pressable`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 6px 16px 6px 0;
`;

export const CommandText = styled(Text)`
  margin-start: 4px;
  color: ${(props: any) => props.theme.colors.text2};
  font-size: 12px;
`;

export const LoadingTagsContainer = styled.View`
  align-items: center;
  justify-content: center;
  height: 38px;
`;

export default null;
