import { useRouting } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment, usePaginationFragment } from 'react-relay';

import Feed from '@/Feed';
import FeedSideSorter from '@/FeedSideSorter';
import Post from '@/Post';
import { decodeObjectId } from '#database/utils/base64ObjectId';
import { QueryParam } from '#enum';
import useFbt from '#hooks/useFbt';
import { feedSort } from '#relay/helpers/feed';
import { initFeedCount } from '#variables';
import {
  PostFeedChannel_posts,
  PostFeedChannel_posts$key,
} from '~/PostFeedChannel_posts.graphql';
import { PostFeedChannel_viewer$key } from '~/PostFeedChannel_viewer.graphql';

const viewerFragment = graphql`
  fragment PostFeedChannel_viewer on User {
    ...Post_viewer
  }
`;

const postsFragment = graphql`
  fragment PostFeedChannel_posts on Query
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    count: { type: "Int", defaultValue: 5 }
    cursor: { type: "String" }
    userCommunityId: { type: "MongoID" }
    sort: { type: "SortFeedPostEnum" }
  )
  @refetchable(queryName: "PostFeedChannel_posts_Query") {
    channelFeed(
      userCommunityId: $userCommunityId
      first: $count
      after: $cursor
      sort: $sort
    )
      @connection(
        key: "PostFeedChannel_channelFeed"
        filters: ["userCommunityId", "sort"]
      ) {
      edges {
        node {
          id
          ...Post_post @arguments(viewerId: $viewerId)
        }
      }
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: PostFeedChannel_viewer$key | null;
  posts: PostFeedChannel_posts$key | null;
  initIsRefetch?: boolean;
};

const PostFeedChannel = ({
  posts,
  viewer,
  initIsRefetch,
}: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);
  const { data: postsData, ...postsPagination } = usePaginationFragment(
    postsFragment,
    posts,
  );

  useFbt();

  const { getParam } = useRouting();
  const query: {
    [QueryParam.EncodedUserCommunityId]?: string;
    [QueryParam.FeedSort]?: string;
    [QueryParam.CommunityName]?: string;
  } = React.useMemo(
    () => ({
      [QueryParam.EncodedUserCommunityId]: getParam(
        QueryParam.EncodedUserCommunityId,
      ),
      [QueryParam.FeedSort]: getParam(QueryParam.FeedSort),
      [QueryParam.CommunityName]: getParam(QueryParam.CommunityName),
    }),
    [getParam],
  );

  const userCommunityId = decodeObjectId(
    query[QueryParam.EncodedUserCommunityId],
  );

  const refetchVariables = React.useMemo(
    () => ({
      userCommunityId,
      sort: feedSort(query[QueryParam.FeedSort]),
    }),
    [query, userCommunityId],
  );

  const connection = React.useMemo(
    () => ({
      key: 'PostFeedChannel_channelFeed',
      filters: {
        userCommunityId: refetchVariables.userCommunityId,
        sort: refetchVariables.sort,
      },
    }),
    [refetchVariables.sort, refetchVariables.userCommunityId],
  );

  const renderItem = React.useCallback(
    ({ item }): React.ReactElement | null =>
      item?.node ? (
        <Post connection={connection} viewer={viewerData} post={item.node} />
      ) : null,
    [connection, viewerData],
  );

  return (
    <Feed<PostFeedChannel_posts['channelFeed']>
      initIsRefetch={initIsRefetch}
      pagination={postsPagination}
      isDataReady={!!postsData?.channelFeed}
      feedSidebar={
        (postsData?.channelFeed?.edges.length ?? 0) >= initFeedCount ? (
          <FeedSideSorter />
        ) : null
      }
      feedSidebarWidth={postsData?.channelFeed ? 48 : undefined}
      data={postsData?.channelFeed?.edges}
      renderItem={renderItem}
      refetchVariables={refetchVariables}
      query={query}
      itemsPerLoad={initFeedCount}
      noMoreText={
        postsData?.channelFeed?.edges.length
          ? fbt('No more post', 'footer message')
          : fbt('No post for the selected tags', 'search results message')
      }
    />
  );
};

export default PostFeedChannel;
