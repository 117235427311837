/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentBlock_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"CommentContentBlock_viewer" | "CommentBlockFooter_viewer">;
    readonly " $refType": "CommentBlock_viewer";
};
export type CommentBlock_viewer$data = CommentBlock_viewer;
export type CommentBlock_viewer$key = {
    readonly " $data"?: CommentBlock_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentBlock_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentBlock_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentContentBlock_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentBlockFooter_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '3f222bf1971f5066c23619b30b95daa2';
export default node;
