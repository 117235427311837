import React from 'react';
import { Text } from 'react-native';

import useTheme from '#hooks/useTheme';

type Props = {
  onlyText?: boolean;
};

const MddleDot = ({ onlyText }: Props): React.ReactElement => {
  const { colors } = useTheme();

  return onlyText ? (
    <Text style={{ marginVertical: 'auto', fontWeight: 'bold' }}>·</Text>
  ) : (
    <Text
      style={{
        marginVertical: 'auto',
        marginHorizontal: 5,
        color: colors?.text2,
        fontWeight: 'bold',
      }}
    >
      ·
    </Text>
  );
};

export default React.memo(MddleDot);
