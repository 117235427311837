/* eslint-disable @typescript-eslint/no-explicit-any */
import { BasicPostStats } from '#interfaces';

const getBasicPostProps = (
  content:
    | {
        vote?: { upvotes: number | null; downvotes: number | null } | null;
        count?: { shared: number | null; tagged: number | null } | null;
      }
    | undefined
    | null,
): BasicPostStats | undefined => {
  if (!content) return undefined;

  return {
    upvotes: content.vote?.upvotes,
    downvotes: content.vote?.downvotes,
    totalShared: content.count?.shared,
    totalTagged: content.count?.tagged,
  };
};

export default getBasicPostProps;
