/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from '#styled-system';

export const Container = styled.View<FlexboxProps & LayoutProps & SpaceProps>`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  margin: 12px 20px;
  ${flexbox};
  ${layout};
  ${space};
`;

export const ImageContainer = styled.View<SpaceProps>`
  flex: 1 0 auto;
  ${space};
`;

export const MessageContainer = styled.View<FlexboxProps>`
  flex: 1 1 auto;
  justify-content: center;
  ${flexbox};
`;

export const Message = styled(Text)`
  flex: 1 1 auto;
  color: ${(props: any) => props.theme.colors.text};
  font-size: 16px;
  line-height: 150%;
`;

export default null;
