import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Menu as PaperMenu } from 'react-native-paper';
import styled from 'styled-components/native';

export const Menu = styled(PaperMenu)`
  position: fixed;
`;

export const MenuButton = styled(MaterialCommunityIcons)`
  width: 24px;
  height: 24px;
  margin-vertical: auto;
`;

export default null;
