import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeletedUser from '@/DeletedUser';
import TimeAgo from '@/TimeAgo';
import { CommentBlockHeader_comment$key } from '~/CommentBlockHeader_comment.graphql';

import {
  Container,
  Detail,
  UserDisplayName,
  UserNameContainer,
} from './CommentBlockHeader.style';

const commentFragment = graphql`
  fragment CommentBlockHeader_comment on Comment {
    createdBy {
      id
      username
      displayName
    }
    createdDate
  }
`;

type Props = {
  comment: CommentBlockHeader_comment$key;
};

const CommentBlockHeader = ({ comment }: Props) => {
  const commentData = useFragment(commentFragment, comment);

  const name =
    commentData?.createdBy?.displayName ?? commentData?.createdBy?.username;

  return (
    <Container>
      <UserNameContainer>
        {commentData?.createdBy?.id ? (
          <UserDisplayName>{name}</UserDisplayName>
        ) : (
          <DeletedUser fontSize="12px" />
        )}
      </UserNameContainer>
      {commentData?.createdDate && (
        <Detail>
          <TimeAgo onlyText>{commentData.createdDate}</TimeAgo>
        </Detail>
      )}
    </Container>
  );
};

export default React.memo(CommentBlockHeader);
