import fbt from 'fbt';
import React from 'react';
import { ActivityIndicator } from 'react-native';
import { graphql, useFragment, useQueryLoader } from 'react-relay';
import { useUnmount } from 'react-use';

import CenterContent from '@/CenterContent';
import CommentDrawerHeader from '@/CommentDrawerHeader';
import CommentFeedRoot, { query } from '@/CommentFeedRoot/CommentFeedRoot';
import getCommentScoreKey from '#database/utils/getCommentScoreKey';
import useCommentGlobal from '#hooks/useCommentGlobal';
import { CommentLocalProvider } from '#hooks/useCommentLocal';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import useTheme from '#hooks/useTheme';
import { CommentDrawer_post$key } from '~/CommentDrawer_post.graphql';
import { CommentDrawer_viewer$key } from '~/CommentDrawer_viewer.graphql';
import { CommentFeedRootQuery } from '~/CommentFeedRootQuery.graphql';
import { SortGetCommentsCommentEnum } from '~~';

import {
  CommentsContainer,
  Container,
  ExtraContainer,
  NoCommentText,
} from './CommentDrawer.style';

const viewerFragment = graphql`
  fragment CommentDrawer_viewer on User {
    id
    ...CommentDrawerHeader_viewer
    ...CommentFeedRoot_viewer
  }
`;

const postFragment = graphql`
  fragment CommentDrawer_post on Post {
    id
    count {
      totalComments
      activeRootComments
    }
    ...CommentFeedRoot_post
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: CommentDrawer_viewer$key | null;
  post: CommentDrawer_post$key;
};

/**
 * If post comment count is 0, do nothing and render `No Comment`
 * if post comment count > 0, show `ActivityIndicator` and fetch comments
 */
const CommentDrawer = ({ viewer, post }: Props): React.ReactElement => {
  const [
    queryRef,
    loadQuery,
    disposeQuery,
  ] = useQueryLoader<CommentFeedRootQuery>(query, null);
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  useFbt();

  const [, { lteSm }] = useResponsive();
  const {
    sort,
    rootCommentId,
    setRootCommentId,
    parentCommentId,
    isLoading,
    setIsGoingUp,
    featuredSubCommentType,
  } = useCommentGlobal();
  const { componentSizes } = useTheme();

  const feedStatus =
    (!postData.count?.totalComments && 'noComment') ||
    (!postData.count?.activeRootComments && 'noActiveThread');
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const handleGoBack = React.useCallback(() => {
    setIsGoingUp(true);
    if (parentCommentId || rootCommentId)
      setRootCommentId(parentCommentId ?? undefined);
  }, [parentCommentId, rootCommentId, setIsGoingUp, setRootCommentId]);

  // handle the very first comment of the post
  const handleFirstComment = React.useCallback(() => {
    setIsSubmitted(true);
  }, []);

  const activityIndicatorComponent = React.useMemo(
    () => (
      <CenterContent>
        <ActivityIndicator size={28} />
      </CenterContent>
    ),
    [],
  );

  const noCommentComponent = React.useMemo(
    () => (
      <CenterContent>
        <NoCommentText>
          {feedStatus === 'noComment' && <fbt desc="message">No comment</fbt>}
          {feedStatus === 'noActiveThread' && (
            <fbt desc="message">No active thread</fbt>
          )}
        </NoCommentText>
      </CenterContent>
    ),
    [feedStatus],
  );

  const loadComments = React.useCallback(
    (v?: {
      sort?: SortGetCommentsCommentEnum;
      rootCommentId?: string | null;
    }) => {
      const scoreKey = getCommentScoreKey(sort);
      const connectionQuery = scoreKey
        ? { _operators: { [scoreKey]: { ne: null } } }
        : {};

      loadQuery({
        viewerId: viewerData?.id,
        rootCount: 1,
        count: 7,
        filter: {
          postId: postData.id,
          // if `rootCommentId` is not null, use it to fetch a comment as thread root
          _id: v?.rootCommentId ?? rootCommentId,
          // if `rootCommentId` is not exists, query for all root comments (comment with parentComment equal to `null`)
          parentCommentId: v?.rootCommentId ?? rootCommentId ? undefined : null,
          ...connectionQuery,
          // get all active comments and deleted comments with active replied
          AND: [
            {
              OR: [
                { status: { value: null } },
                { _operators: { count: { activeSubComments: { gt: 0 } } } },
              ],
            },
          ],
        },
        sort: v?.sort ?? sort ?? SortGetCommentsCommentEnum.FeaturedScore,
        featuredSubCommentType,
        getFstSubComments: true,
        getSndSubComments: true,
        getTrdSubComments: !lteSm,
        getFthSubComments: !lteSm,
      });
    },
    [
      featuredSubCommentType,
      loadQuery,
      lteSm,
      postData.id,
      rootCommentId,
      sort,
      viewerData?.id,
    ],
  );

  React.useEffect(() => {
    let isMounted = true;

    // refetch comments if variables change
    if (isMounted) loadComments({ sort, rootCommentId });

    return () => {
      isMounted = false;
    };
  }, [loadComments, rootCommentId, sort]);

  useUnmount(() => disposeQuery());

  return (
    <CommentLocalProvider>
      <Container
        marginBottom={lteSm ? undefined : componentSizes?.headerHeight}
      >
        <CommentDrawerHeader
          viewer={viewerData}
          disabledSorter={!isSubmitted && (!!feedStatus ?? isLoading)}
          onSubmit={handleFirstComment}
          onBackPressed={handleGoBack}
        />
        <CommentsContainer>
          {feedStatus && (
            <ExtraContainer>
              {!isSubmitted && !isLoading && feedStatus && noCommentComponent}
            </ExtraContainer>
          )}
          <React.Suspense
            fallback={feedStatus ? null : activityIndicatorComponent}
          >
            {queryRef ? (
              <CommentFeedRoot
                queryRef={queryRef}
                viewer={viewerData}
                post={postData}
              />
            ) : null}
          </React.Suspense>
        </CommentsContainer>
      </Container>
    </CommentLocalProvider>
  );
};

export default CommentDrawer;
