/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type EnumVoteVote = "Downvote" | "Unvote" | "Upvote" | "%future added value";
export type CommentBlockFooter_comment = {
    readonly id: string;
    readonly status: {
        readonly value: EnumDocumentStatusValue | null;
    } | null;
    readonly count: {
        readonly activeSubComments: number | null;
    } | null;
    readonly vote: {
        readonly upvotes: number | null;
        readonly downvotes: number | null;
    } | null;
    readonly userVote: {
        readonly id: string;
        readonly vote: EnumVoteVote;
    } | null;
    readonly createdBy: {
        readonly id: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CommentButtonUp_comment" | "CommentButtonDelete_comment">;
    readonly " $refType": "CommentBlockFooter_comment";
};
export type CommentBlockFooter_comment$data = CommentBlockFooter_comment;
export type CommentBlockFooter_comment$key = {
    readonly " $data"?: CommentBlockFooter_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentBlockFooter_comment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentBlockFooter_comment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentCount",
      "kind": "LinkedField",
      "name": "count",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activeSubComments",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentVote",
      "kind": "LinkedField",
      "name": "vote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "upvotes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downvotes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "viewerId"
        }
      ],
      "concreteType": "Vote",
      "kind": "LinkedField",
      "name": "userVote",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vote",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentButtonUp_comment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentButtonDelete_comment"
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();
(node as any).hash = 'ebb636c492b7da1aff65a1fb92959897';
export default node;
