/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import {
  flexDirection,
  FlexDirectionProps,
  layout,
  LayoutProps,
  padding,
  PaddingProps,
  typography,
  TypographyProps,
} from '#styled-system';

export const Container = styled.View<FlexDirectionProps>`
  ${flexDirection};
`;

export const IconContainer = styled.View<LayoutProps & PaddingProps>`
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: auto;
  ${layout};
  ${padding};
`;

export const ScoreContainer = styled.View<LayoutProps>`
  align-items: center;
  justify-content: center;
  min-width: 28px;
  height: 24px;
  margin: auto;
`;

export const Score = styled(Text)<PaddingProps & TypographyProps>`
  color: ${(props: any) => props.theme.colors.text3};
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  ${padding};
  ${typography};
`;

export default null;
