/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import { padding, PaddingProps } from '#styled-system';

export const HeaderContainer = styled.View<PaddingProps>`
  flex: 0 1 auto;
  flex-direction: row;
  padding: 16px 16px 0;
  ${padding};
`;

export const CreatorContainer = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
`;

export const DateContainer = styled.View`
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-end: 8px;
`;

export default null;
