/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import { color, ColorProps, typography, TypographyProps } from '#styled-system';

export const Container = styled.View`
  display: inline-flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto 0;
`;

export const DatetimeText = styled(Text)<ColorProps & TypographyProps>`
  color: ${(props: any) => props.theme.colors.text3};
  font-size: 12px;
  ${color};
  ${typography};
`;

export default null;
