/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type PostCardTemplate_post = {
    readonly id: string;
    readonly scoreInfo: {
        readonly lastUpdatedDate: string | null;
    } | null;
    readonly createdDate: string | null;
    readonly status: {
        readonly value: EnumDocumentStatusValue | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"PostCardHeader_post" | "PostCardFooter_post" | "PostStatusMessage_post">;
    readonly " $refType": "PostCardTemplate_post";
};
export type PostCardTemplate_post$data = PostCardTemplate_post;
export type PostCardTemplate_post$key = {
    readonly " $data"?: PostCardTemplate_post$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardTemplate_post">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "viewerId",
    "variableName": "viewerId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardTemplate_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PostScoreInfo",
      "kind": "LinkedField",
      "name": "scoreInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastUpdatedDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "PostCardHeader_post"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "PostCardFooter_post"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostStatusMessage_post"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();
(node as any).hash = '3e4972b2bc36e35062ac4446c4543ee5';
export default node;
