/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostFeed_taggedPosts = {
    readonly " $fragmentRefs": FragmentRefs<"PostFeedTagged_posts">;
    readonly " $refType": "PostFeed_taggedPosts";
};
export type PostFeed_taggedPosts$data = PostFeed_taggedPosts;
export type PostFeed_taggedPosts$key = {
    readonly " $data"?: PostFeed_taggedPosts$data;
    readonly " $fragmentRefs": FragmentRefs<"PostFeed_taggedPosts">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedSort"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isInCommunity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostFeed_taggedPosts",
  "selections": [
    {
      "condition": "isInCommunity",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "feedFilter",
              "variableName": "feedFilter"
            },
            {
              "kind": "Variable",
              "name": "feedSort",
              "variableName": "feedSort"
            },
            {
              "kind": "Variable",
              "name": "viewerId",
              "variableName": "viewerId"
            }
          ],
          "kind": "FragmentSpread",
          "name": "PostFeedTagged_posts"
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '79528ebd2ae1ff972dd7146dbc3c6ebf';
export default node;
