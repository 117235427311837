/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardSidebar_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"Vote_viewer" | "TaggedTagsModal_viewer">;
    readonly " $refType": "PostCardSidebar_viewer";
};
export type PostCardSidebar_viewer$data = PostCardSidebar_viewer;
export type PostCardSidebar_viewer$key = {
    readonly " $data"?: PostCardSidebar_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardSidebar_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardSidebar_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Vote_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaggedTagsModal_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '7979bf6b1a59162c9d56885a9661d0bc';
export default node;
