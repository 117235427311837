import { useRouting } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment, usePaginationFragment } from 'react-relay';

import Feed from '@/Feed';
import FeedSideSorter from '@/FeedSideSorter';
import Post from '@/Post';
import PostFeedEmpty from '@/PostFeedEmpty';
import { QueryParam } from '#enum';
import useFbt from '#hooks/useFbt';
import { feedFilterTagged, feedSortTagged } from '#relay/helpers/feed';
import { initFeedCount } from '#variables';
import {
  PostFeedTagged_posts,
  PostFeedTagged_posts$key,
} from '~/PostFeedTagged_posts.graphql';
import { PostFeedTagged_viewer$key } from '~/PostFeedTagged_viewer.graphql';

const viewerFragemnt = graphql`
  fragment PostFeedTagged_viewer on User {
    id
    ...Post_viewer
    ...PostFeedEmpty_viewer
  }
`;

const postsFragment = graphql`
  fragment PostFeedTagged_posts on Query
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    count: { type: "Int", defaultValue: 1 }
    cursor: { type: "String" }
    feedFilter: { type: "FilterFindManyTaggedPostInput" }
    feedSort: { type: "SortFeedTaggedPostEnum" }
  )
  @refetchable(queryName: "PostFeedTagged_taggedPosts_Query") {
    taggedPostFeed(
      first: $count
      after: $cursor
      filter: $feedFilter
      sort: $feedSort
    )
      @connection(
        key: "PostFeedTagged_taggedPostFeed"
        filters: ["filter", "sort"]
      ) {
      edges {
        node {
          id
          post {
            ...Post_post @arguments(viewerId: $viewerId)
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: PostFeedTagged_viewer$key | null;
  posts: PostFeedTagged_posts$key | null;
  initIsRefetch?: boolean;
};

const PostFeedTagged = ({
  viewer,
  posts,
  initIsRefetch,
}: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragemnt, viewer);
  const { data: postsData, ...postsPagination } = usePaginationFragment(
    postsFragment,
    posts,
  );

  useFbt();

  const { getParam, pathname } = useRouting();

  const f = getParam(QueryParam.FeedQuery) as string | undefined;
  const fs = getParam(QueryParam.FeedScope) as string | undefined;
  const sort = getParam(QueryParam.FeedSort) as string | undefined;
  const query: {
    [QueryParam.FeedQuery]?: string;
    [QueryParam.FeedScope]?: string;
    [QueryParam.FeedSort]?: string;
  } = React.useMemo(
    () => ({
      [QueryParam.FeedQuery]: f,
      [QueryParam.FeedScope]: fs,
      [QueryParam.FeedSort]: sort,
    }),
    [f, fs, sort],
  );

  const refetchVariables = React.useMemo(
    () => ({
      feedFilter: feedFilterTagged(query[QueryParam.FeedQuery], viewerData?.id),
      feedSort: feedSortTagged(query[QueryParam.FeedSort]),
    }),
    [query, viewerData?.id],
  );

  const connection = React.useMemo(
    () => ({
      key: 'PostFeedTagged_taggedPostFeed',
      filters: {
        filter: refetchVariables.feedFilter,
        sort: refetchVariables.feedSort,
      },
    }),
    [refetchVariables.feedFilter, refetchVariables.feedSort],
  );

  const renderItem = React.useCallback(
    ({ item }): React.ReactElement | null =>
      item?.node ? (
        <Post
          connection={connection}
          viewer={viewerData}
          post={item.node.post}
        />
      ) : null,
    [connection, viewerData],
  );

  if (
    !initIsRefetch &&
    viewer &&
    pathname === '/' &&
    !f &&
    !postsData?.taggedPostFeed?.edges?.length
  )
    return <PostFeedEmpty viewer={viewerData} />;

  return (
    <Feed<PostFeedTagged_posts['taggedPostFeed']>
      initIsRefetch={initIsRefetch}
      pagination={postsPagination}
      isDataReady={!!postsData?.taggedPostFeed}
      feedSidebar={
        (postsData?.taggedPostFeed?.edges.length ?? 0) >= initFeedCount ? (
          <FeedSideSorter />
        ) : null
      }
      feedSidebarWidth={postsData?.taggedPostFeed ? 48 : undefined}
      data={postsData?.taggedPostFeed?.edges}
      renderItem={renderItem}
      refetchVariables={refetchVariables}
      query={query}
      itemsPerLoad={initFeedCount}
      noMoreText={
        postsData?.taggedPostFeed?.edges.length
          ? fbt('No more post', 'footer message')
          : fbt('No post for the selected tags', 'search results message')
      }
    />
  );
};

export default PostFeedTagged;
