/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentInput_viewer = {
    readonly id: string;
    readonly username: string;
    readonly displayName: string | null;
    readonly " $refType": "CommentInput_viewer";
};
export type CommentInput_viewer$data = CommentInput_viewer;
export type CommentInput_viewer$key = {
    readonly " $data"?: CommentInput_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentInput_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentInput_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '3ec507d47e722da30691398f080db58b';
export default node;
