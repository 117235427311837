import React from 'react';
import { ScrollView } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';

import CenterContent from '@/CenterContent';
import Features from '@/Features';
import GetStartedPointer from '@/GetStartedPointer';
import LinealButton from '@/LinealButton';
import useResponsive from '#hooks/useResponsive';
import useSidebar from '#hooks/useSidebar';
import MissionImg from '#public/images/mission.png';
import PiggyBankImg from '#public/images/piggy-bank.png';
import TagImg from '#public/images/tag.png';
import UserImg from '#public/images/user.png';

import {
  ButtonContainer,
  FeaturesContainer,
  Hint,
  HintContainer,
  Title,
  TitleContainer,
} from './FeaturesList.style';

const FeaturesList = () => {
  const [r, { lteSm }] = useResponsive();
  const { isOpen, setIsOpen } = useSidebar();

  const openDrawer = React.useCallback(() => setIsOpen(true), [setIsOpen]);

  return (
    <>
      <GetStartedPointer />
      <ScrollView
        style={{ flexGrow: 1, width: '100%' }}
        contentContainerStyle={{ flexGrow: 1, width: '100%' }}
      >
        <CenterContent>
          <TitleContainer
            mt={lteSm ? '20px' : undefined}
            mb={lteSm ? '20px' : undefined}
          >
            <Title
              fontSize={r([RFPercentage(3.3), RFPercentage(3.4), undefined])}
            >
              Welcome to Our Community
            </Title>
          </TitleContainer>
          <FeaturesContainer>
            <Features imageSide="r" imageUri={MissionImg}>
              Lpaydat is an open community and our mission is to create an
              community that is truely manage and control by the users.
            </Features>
            <Features imageSide="l" imageUri={UserImg}>
              Gain more privileges by contribute to community. Let&apos;s
              contribute to community together, together we can make everything
              better.
            </Features>
            <Features imageSide="r" imageUri={TagImg}>
              Save post for later reading, find more interesting contents,
              create an community, easily control over everything using tags.
            </Features>
            <Features imageSide="l" imageUri={PiggyBankImg}>
              Lpaydat is free and free forever. You can join our community with
              no fear and use it as you please with zero worry.
            </Features>
          </FeaturesContainer>
          {lteSm && (
            <ButtonContainer>
              <LinealButton disabled={isOpen} onPress={openDrawer}>
                Get Started!
              </LinealButton>
            </ButtonContainer>
          )}
          <HintContainer mt={lteSm ? '36px' : undefined}>
            <Hint mb={lteSm ? '8px' : undefined}>
              We are in the BETA stage which still lacks many features and may
              have some bugs.
            </Hint>
            <Hint>
              Any feedback and help are very welcome and important to us.
            </Hint>
          </HintContainer>
        </CenterContent>
      </ScrollView>
    </>
  );
};

export default React.memo(FeaturesList);
