import { useRouting } from 'expo-next-react-navigation';
import React from 'react';
import { ActivityIndicator } from 'react-native';
import { graphql, useFragment } from 'react-relay';
import { useEffectOnce } from 'react-use';

import CenterContent from '@/CenterContent';
import FeaturesList from '@/FeaturesList';
import PostFeedChannel from '@/PostFeedChannel';
import PostFeedMain from '@/PostFeedMain';
import PostFeedTagged from '@/PostFeedTagged';
import { decodeObjectId } from '#database/utils/base64ObjectId';
import { FeedScope, QueryParam } from '#enum';
import { PostFeed_posts$key } from '~/PostFeed_posts.graphql';
import { PostFeed_taggedPosts$key } from '~/PostFeed_taggedPosts.graphql';
import { PostFeed_viewer$key } from '~/PostFeed_viewer.graphql';

const viewerFragment = graphql`
  fragment PostFeed_viewer on User {
    id
    ...PostFeedMain_viewer
    ...PostFeedTagged_viewer
    ...PostFeedChannel_viewer
  }
`;

const postsFragment = graphql`
  fragment PostFeed_posts on Query
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    feedFilter: { type: "FilterFindManyPostInput" }
    feedSort: { type: "SortFeedPostEnum" }
    userCommunityId: { type: "MongoID" }
    isInCommunity: { type: "Boolean", defaultValue: false }
  ) {
    ...PostFeedMain_posts
      @arguments(
        viewerId: $viewerId
        feedFilter: $feedFilter
        feedSort: $feedSort
      )
      @skip(if: $isInCommunity)

    ...PostFeedChannel_posts
      @arguments(
        viewerId: $viewerId
        userCommunityId: $userCommunityId
        sort: $feedSort
      )
      @include(if: $isInCommunity)
  }
`;

const taggedPostsFragment = graphql`
  fragment PostFeed_taggedPosts on Query
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    feedFilter: { type: "FilterFindManyPostInput" }
    feedSort: { type: "SortFeedPostEnum" }
    isInCommunity: { type: "Boolean", defaultValue: false }
  ) {
    ...PostFeedTagged_posts
      @arguments(
        viewerId: $viewerId
        feedFilter: $feedFilter
        feedSort: $feedSort
      )
      @skip(if: $isInCommunity)
  }
`;

type Props = {
  viewer: PostFeed_viewer$key | null;
  posts: PostFeed_posts$key;
  taggedPosts: PostFeed_taggedPosts$key;
};

const PostFeed = ({
  viewer,
  posts,
  taggedPosts,
}: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);
  const postsData = useFragment(postsFragment, posts);
  const taggedPostsData = useFragment(taggedPostsFragment, taggedPosts);

  const { getParam, pathname } = useRouting();
  const initIsRefetchRef = React.useRef(false);

  const feedScope = getParam(QueryParam.FeedScope);
  const feedQuery = getParam(QueryParam.FeedQuery);
  const userCommunityId = decodeObjectId(
    getParam(QueryParam.EncodedUserCommunityId),
  );

  useEffectOnce(() => {
    initIsRefetchRef.current = true;
  });

  if (!viewer && !feedQuery && !userCommunityId && pathname === '/')
    return <FeaturesList />;

  const fallbackComponent = (
    <CenterContent>
      <ActivityIndicator size={32} />
    </CenterContent>
  );

  if (userCommunityId)
    return (
      <React.Suspense fallback={fallbackComponent}>
        <PostFeedChannel
          viewer={viewerData}
          posts={postsData}
          initIsRefetch={initIsRefetchRef.current}
        />
      </React.Suspense>
    );

  if (viewerData?.id && feedScope === FeedScope.Tagged)
    return (
      <React.Suspense fallback={fallbackComponent}>
        <PostFeedTagged
          viewer={viewerData}
          posts={taggedPostsData}
          initIsRefetch={initIsRefetchRef.current}
        />
      </React.Suspense>
    );

  return (
    <React.Suspense fallback={fallbackComponent}>
      <PostFeedMain
        viewer={viewerData}
        posts={postsData}
        initIsRefetch={initIsRefetchRef.current}
      />
    </React.Suspense>
  );
};

export default PostFeed;
