/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import { fonts } from '#styles/variables';

export const Container = styled.View`
  position: absolute;
`;

export const Message = styled(Text)`
  position: relative;
  margin: 8px 14px;
  color: ${(props: any) => props.theme.colors.text};
  font-size: 16px;
  font-family: '${fonts.OfficinaSans}';
`;

export default null;
