import React from 'react';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';

import CommentFeed from '@/CommentFeed';
import CommentLoaderButton from '@/CommentLoaderButton';
import { CommentBlockSubFeedQuery } from '~/CommentBlockSubFeedQuery.graphql';

import { Container } from './CommentBlockSubFeed.style';

export const query = graphql`
  query CommentBlockSubFeedQuery(
    $viewerId: MongoID
    $count: Int
    $cursor: String
    $filter: FilterFindManyCommentInput
    $sort: SortGetCommentsCommentEnum
    $featuredSubCommentType: String
    $getFstSubComments: Boolean!
    $getSndSubComments: Boolean!
    $getTrdSubComments: Boolean!
    $getFthSubComments: Boolean!
  ) {
    # This prevent circular fragment spread
    viewer(_id: $viewerId) {
      ...CommentFeed_viewer
    }
    ...CommentFeed_comments
      @arguments(
        viewerId: $viewerId
        count: $count
        cursor: $cursor
        filter: $filter
        sort: $sort
        featuredSubCommentType: $featuredSubCommentType
        getFstSubComments: $getFstSubComments
        getSndSubComments: $getSndSubComments
        getTrdSubComments: $getTrdSubComments
        getFthSubComments: $getFthSubComments
      )
  }
`;

type Props = {
  queryRef: PreloadedQuery<CommentBlockSubFeedQuery>;
  subCommentDepth?: number;
  fetchedIds?: (string | null | undefined)[] | null;
  count?: number;
};

const CommentBlockSubFeed = ({
  queryRef,
  subCommentDepth,
  fetchedIds,
  count,
}: Props): React.ReactElement => {
  const data = usePreloadedQuery(query, queryRef);

  return (
    <Container>
      <React.Suspense
        fallback={<CommentLoaderButton type="reply" count={count} isLoading />}
      >
        <CommentFeed
          viewer={data?.viewer ?? null}
          comments={data ?? null}
          totalSubComments={count}
          subCommentDepth={(subCommentDepth ?? 0) - 1}
          excludeIds={fetchedIds}
        />
      </React.Suspense>
    </Container>
  );
};

export default CommentBlockSubFeed;
