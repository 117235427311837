/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCard_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"PostCardTemplate_viewer" | "PostCardSidebar_viewer">;
    readonly " $refType": "PostCard_viewer";
};
export type PostCard_viewer$data = PostCard_viewer;
export type PostCard_viewer$key = {
    readonly " $data"?: PostCard_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCard_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCard_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCardTemplate_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCardSidebar_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '128128be7a04f43223e70d246faa4301';
export default node;
