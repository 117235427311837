/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardFullContent_post = {
    readonly " $fragmentRefs": FragmentRefs<"PostCard_post">;
    readonly " $refType": "PostCardFullContent_post";
};
export type PostCardFullContent_post$data = PostCardFullContent_post;
export type PostCardFullContent_post$key = {
    readonly " $data"?: PostCardFullContent_post$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardFullContent_post">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardFullContent_post",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "viewerId",
          "variableName": "viewerId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "PostCard_post"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
(node as any).hash = '1a2d461291aed4b6f138972155b8c7c9';
export default node;
