/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import { margin, MarginProps } from '#styled-system';

export const Container = styled.View`
  flex-direction: row;
  height: 44px;
  border-top-color: ${(props: any) => props.theme.colors.blackOpac10};
  border-top-width: 1px;
`;

export const LeftContainer = styled.View<MarginProps>`
  display: flex;
  flex: 2 2 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-start: 6px;
  ${margin};
`;

export const RightContainer = styled.View`
  display: flex;
  flex: 3 3 auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-end: 6px;
  ${margin}
`;

export const VoteContainer = styled.View`
  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: center;
`;

export const OptionContainer = styled.View`
  align-items: center;
  justify-content: center;
  padding: 4px;
`;

export default null;
