import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { shouldUpdateScore } from '#utils';
import { UpdatePostScoreMutation } from '~/UpdatePostScoreMutation.graphql';

const mutation = graphql`
  mutation UpdatePostScoreMutation(
    $_id: MongoID!
    $shouldUpdateScore: Boolean!
  ) {
    postUpdateScore(_id: $_id) @include(if: $shouldUpdateScore) {
      _id
    }
  }
`;

const updatePostScore = (
  environment: Environment,
  args: {
    _id: string;
    postCreatedDate?: string | null;
    lastScoreUpdatedDate?: string | null;
  },
): Disposable | void => {
  const postDate =
    typeof args.postCreatedDate === 'string' && new Date(args.postCreatedDate);
  const scoreDate =
    typeof args.lastScoreUpdatedDate === 'string'
      ? new Date(args.lastScoreUpdatedDate)
      : undefined;
  const shouldUpdate = postDate && shouldUpdateScore(postDate, scoreDate);

  const variables = {
    _id: args._id,
    shouldUpdateScore: shouldUpdate,
  };

  if (shouldUpdate)
    return commitMutation<UpdatePostScoreMutation>(environment, {
      mutation,
      variables,
    });
};

export default updatePostScore;
