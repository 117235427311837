import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CommentButtonDelete from '@/CommentButtonDelete';
import CommentButtonEdit from '@/CommentButtonEdit';
import CommentButtonReply from '@/CommentButtonReply';
import CommentButtonReport from '@/CommentButtonReport';
import CommentButtonUp from '@/CommentButtonUp';
import CommentInput from '@/CommentInput';
import Vote from '@/Vote';
import { ObjectType, VoteType } from '#enum';
import useCommentLocal from '#hooks/useCommentLocal';
import useFbt from '#hooks/useFbt';
import { CommentBlockFooter_comment$key } from '~/CommentBlockFooter_comment.graphql';
import { CommentBlockFooter_viewer$key } from '~/CommentBlockFooter_viewer.graphql';

import {
  ButtonsContainer,
  Container,
  EditorContainer,
  OptionsContainer,
  VoteContainer,
} from './CommentBlockFooter.style';

const viewerFragment = graphql`
  fragment CommentBlockFooter_viewer on User {
    id
    ...Vote_viewer
    ...CommentInput_viewer
    ...CommentButtonReport_viewer
  }
`;

const commentFragment = graphql`
  fragment CommentBlockFooter_comment on Comment
  @argumentDefinitions(viewerId: { type: "MongoID" }) {
    id
    status {
      value
    }
    count {
      activeSubComments
    }
    vote {
      upvotes
      downvotes
    }
    userVote(userId: $viewerId) {
      id
      vote
    }
    createdBy {
      id
    }
    ...CommentButtonUp_comment
    ...CommentButtonDelete_comment
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: CommentBlockFooter_viewer$key | null;
  comment: CommentBlockFooter_comment$key;
};

const CommentBlockFooter = ({ viewer, comment }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);
  const commentData = useFragment(commentFragment, comment);

  useFbt();

  const {
    setShouldRefetch,
    isRefetchOnSubmit,
    isReplyEditorOpen,
    closeReplyEditor,
  } = useCommentLocal();
  const [disabledActions, setDisabledActions] = React.useState(
    commentData.status?.value,
  );

  const voteScore =
    (commentData.vote?.upvotes ?? 0) - (commentData.vote?.downvotes ?? 0);

  const handleSubmit = React.useCallback(() => {
    closeReplyEditor();
    if (isRefetchOnSubmit) setShouldRefetch(true);
  }, [closeReplyEditor, isRefetchOnSubmit, setShouldRefetch]);

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) setDisabledActions(commentData.status?.value);

    return () => {
      isMounted = false;
    };
  }, [commentData.status?.value]);

  return (
    <Container>
      <ButtonsContainer>
        <VoteContainer>
          <Vote
            viewer={viewerData}
            objectType={ObjectType.Comment}
            objectId={commentData.id}
            userVote={commentData.userVote as { id: string; vote: VoteType }}
            voteScore={voteScore}
            isHorizontal
            size="s"
            disabled={!!disabledActions}
          />
        </VoteContainer>
        <OptionsContainer>
          {!disabledActions && <CommentButtonReply />}
          {(!commentData.status?.value ||
            !!commentData.count?.activeSubComments) && (
            <CommentButtonUp comment={commentData} />
          )}
          {!disabledActions && viewerData?.id === commentData.createdBy?.id ? (
            <>
              <CommentButtonEdit />
              <CommentButtonDelete comment={commentData} />
            </>
          ) : null}
          {!disabledActions && <CommentButtonReport viewer={viewerData} />}
        </OptionsContainer>
      </ButtonsContainer>
      {isReplyEditorOpen && (
        <EditorContainer>
          <CommentInput
            viewer={viewerData}
            parentCommentId={commentData.id}
            autoFocus
            isFullSize
            submitText={fbt('Reply', 'button label')}
            onSubmit={handleSubmit}
            onCancel={closeReplyEditor}
          />
        </EditorContainer>
      )}
    </Container>
  );
};

export default React.memo(
  CommentBlockFooter,
  (prev, next) =>
    prev.comment[' $data']?.userVote === next.comment[' $data']?.userVote &&
    prev.comment[' $data']?.status?.value ===
      next.comment[' $data']?.status?.value,
);
