import { NextPage } from 'next';
import Head from 'next/head';
import React from 'react';
import { graphql } from 'react-relay';

import IndexPageSidebar from '@/IndexPageSidebar';
import PageLayout from '@/PageLayout';
import PostFeed from '@/PostFeed';
import { decodeObjectId } from '#database/utils/base64ObjectId';
import { ObjectType, QueryParam } from '#enum';
import { withHoc } from '#hoc/page';
import Root from '#layers/Root';
import {
  feedFilter,
  feedFilterTagged,
  feedSort,
  feedSortTagged,
  feedValue,
} from '#relay/helpers/feed';
import { pagesQueryResponse } from '~/pagesQuery.graphql';

const Home: NextPage<pagesQueryResponse> = (
  data: pagesQueryResponse,
): React.ReactElement => {
  const { viewer } = { viewer: null, ...data };

  return (
    <Root viewer={viewer}>
      <Head>
        <title>Lpaydat</title>
      </Head>
      <PageLayout
        viewer={viewer}
        sidebar={<IndexPageSidebar viewer={viewer} data={data} />}
      >
        <PostFeed viewer={viewer} posts={data} taggedPosts={data} />
      </PageLayout>
    </Root>
  );
};

export default withHoc(Home, {
  query: graphql`
    query pagesQuery(
      $viewerId: MongoID
      $fetchViewer: Boolean = false
      $userCommunityId: MongoID
      $isInCommunity: Boolean = false
      $queryFor: String!
      $feedValue: [String]
      $mainFeedFilter: FilterFindManyPostInput
      $mainFeedSort: SortFeedPostEnum
      $taggedFeedFilter: FilterFindManyTaggedPostInput
      $taggedFeedSort: SortFeedTaggedPostEnum
    ) {
      viewer(_id: $viewerId) @include(if: $fetchViewer) {
        ...Root_viewer
        ...PageLayout_viewer
        ...PostFeed_viewer
        ...IndexPageSidebar_viewer
      }
      ...IndexPageSidebar_data
        @arguments(
          viewerId: $viewerId
          tagIds: $feedValue
          queryFor: $queryFor
          userCommunityId: $userCommunityId
          isInCommunity: $isInCommunity
        )
      ...PostFeed_posts
        @arguments(
          viewerId: $viewerId
          feedSort: $mainFeedSort
          feedFilter: $mainFeedFilter
          userCommunityId: $userCommunityId
          isInCommunity: $isInCommunity
        )
      ...PostFeed_taggedPosts
        @arguments(
          viewerId: $viewerId
          feedSort: $taggedFeedSort
          feedFilter: $taggedFeedFilter
          isInCommunity: $isInCommunity
        )
    }
  `,
  variables: (query, viewerId) => ({
    queryFor: ObjectType.Post,
    userCommunityId: decodeObjectId(query[QueryParam.EncodedUserCommunityId]),
    isInCommunity: !!decodeObjectId(query[QueryParam.EncodedUserCommunityId]),
    isTagged: !!query[QueryParam.FeedScope],
    feedValue: feedValue(query[QueryParam.FeedQuery]),
    mainFeedFilter: feedFilter(query[QueryParam.FeedQuery]),
    mainFeedSort: feedSort(query[QueryParam.FeedSort]),
    taggedFeedFilter: feedFilterTagged(query[QueryParam.FeedQuery], viewerId),
    taggedFeedSort: feedSortTagged(query[QueryParam.FeedSort]),
  }),
});
