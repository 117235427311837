/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import { color, ColorProps } from '#styled-system';

export const ButtonsContainer = styled.View`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

export const TextButton = styled.Pressable<ColorProps>`
  margin-end: 4px;
  padding: 6px 4px;
  color: ${(props: any) => props.theme.colors.blackOpac};
  font-weight: bold;
  font-size: 12px;
  border-radius: 4px;
  ${color};
`;

export default null;
