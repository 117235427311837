import fbt from 'fbt';
import React from 'react';

import { DeletedUserText } from './DeletedUser.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  fontSize?: string;
};

const DeletedUser = ({ fontSize }: Props) => (
  <DeletedUserText fontSize={fontSize}>
    [<fbt desc="deleted user placeholder">deleted user</fbt>]
  </DeletedUserText>
);

export default React.memo(DeletedUser);
