import React from 'react';
import { format } from 'timeago.js';

import { day, hour, minute, second, week } from '#utils/milliseconds';

import { Container, DatetimeText } from './TimeAgo.style';

type Props = {
  children: string | number;
  color?: string;
  fontSize?: string;
  onlyText?: boolean;

  /**
   * default is 1 week, if time is exceed the limit, it will show implicit date.
   */
  timeAgoLimitMS?: number;
};

const TimeAgo = ({
  children,
  color,
  fontSize,
  onlyText,
  timeAgoLimitMS,
}: Props): React.ReactElement => {
  const date = React.useRef(new Date(children));
  const [age, setAge] = React.useState(Date.now() - date.current.getTime());

  const isPostThisYear =
    date.current.getUTCFullYear() !== new Date().getUTCFullYear();
  const isExceedLimit = age >= (timeAgoLimitMS ?? week);
  const displayTime = isExceedLimit
    ? date.current.toLocaleDateString(undefined, {
        year: isPostThisYear ? '2-digit' : undefined,
        month: 'short',
        day: 'numeric',
      })
    : format(children);

  const getUpdateInterval = React.useCallback((): number | undefined => {
    switch (true) {
      case age < minute:
        return second;
      case age < hour:
        return minute;
      case age < day:
        return hour;
      default:
        return undefined;
    }
  }, [age]);

  React.useEffect(() => {
    let isMounted = true;
    let intervalId: number | undefined;
    let interval = second;

    if (isMounted) {
      const nextUpdateInterval = getUpdateInterval();

      if (nextUpdateInterval) {
        intervalId = setInterval(() => {
          interval = nextUpdateInterval;
          setAge(Date.now() - date.current.getTime());
        }, interval);
      }
    }

    return () => {
      isMounted = false;
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = undefined;
        interval = second;
      }
    };
  }, [getUpdateInterval]);

  return onlyText ? (
    <>{displayTime}</>
  ) : (
    <Container>
      <DatetimeText color={color} fontSize={fontSize}>
        {displayTime}
      </DatetimeText>
    </Container>
  );
};

export default TimeAgo;
