import fbt from 'fbt';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import TaggedTagsModal from '@/TaggedTagsModal';
import Vote from '@/Vote';
import { postVoted } from '#analytics/app/post';
import { ObjectType, VoteType } from '#enum';
import useFbt from '#hooks/useFbt';
import useFullPostAnalytics from '#hooks/useFullPostAnalytics';
import { PostCardSidebar_post } from '~/PostCardSidebar_post.graphql';
import { PostCardSidebar_viewer } from '~/PostCardSidebar_viewer.graphql';

import { Container } from './PostCardSidebar.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: PostCardSidebar_viewer | null;
  post: PostCardSidebar_post;
};

const PostCardSidebar = ({
  viewer,
  post,
}: Props): React.ReactElement | null => {
  useFbt();

  const fullPostProps = useFullPostAnalytics(post);
  const voteCallback = React.useCallback(
    async ({
      prevVote,
      newVote,
    }: {
      prevVote: VoteType;
      newVote: VoteType;
    }) => {
      await postVoted({ prevVote, newVote, ...fullPostProps });
    },
    [fullPostProps],
  );

  const voteScore = (post?.vote?.upvotes ?? 0) - (post?.vote?.downvotes ?? 0);

  return post ? (
    <Container>
      {!post.status?.value && (
        <>
          <Vote
            viewer={viewer}
            objectType={ObjectType.Post}
            objectId={post.id}
            userVote={post.userVote as { id: string; vote: VoteType }}
            voteScore={voteScore}
            voteCallback={voteCallback}
          />
          <TaggedTagsModal
            modalLabel={fbt('Post Tags', "viewer's post tagged tags")}
            viewer={viewer}
            objectType={ObjectType.Post}
            objectId={post.id}
            post={post}
            isSmallIcon
          />
        </>
      )}
    </Container>
  ) : null;
};

export default createFragmentContainer(
  React.memo(
    PostCardSidebar,
    (prev, next) =>
      prev.post?.vote?.upvotes === next.post?.vote?.upvotes &&
      prev.post?.vote?.downvotes === next.post?.vote?.downvotes,
  ),
  {
    viewer: graphql`
      fragment PostCardSidebar_viewer on User {
        id
        ...Vote_viewer
        ...TaggedTagsModal_viewer
      }
    `,
    post: graphql`
      fragment PostCardSidebar_post on Post
      @argumentDefinitions(viewerId: { type: "MongoID" }) {
        id
        vote {
          upvotes
          downvotes
        }
        status {
          value
        }
        count {
          shared
          tagged
        }
        meta {
          key
          value
        }
        userVote(userId: $viewerId) {
          id
          vote
        }
        ...TaggedTagsModal_post @arguments(viewerId: $viewerId)
      }
    `,
  },
);
