/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.Pressable`
  flex: 0 1 auto;
  flex-direction: row;
`;

export const ImageContainer = styled.View`
  margin-end: 6px;
`;

export const Label = styled(Text)`
  margin: auto 0;
  color: ${(props: any): string => props.theme.colors.text2};
`;

export default null;
