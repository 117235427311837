/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EnumImageSizesKey = "avatar" | "medium" | "original" | "px1080" | "px720" | "thumbnail" | "%future added value";
export type CommentCascadeLine_comment = {
    readonly id: string;
    readonly parentCommentId: string | null;
    readonly createdBy: {
        readonly id: string;
        readonly avatar: {
            readonly sizes: ReadonlyArray<{
                readonly key: EnumImageSizesKey | null;
                readonly url: string | null;
            } | null> | null;
        } | null;
    } | null;
    readonly " $refType": "CommentCascadeLine_comment";
};
export type CommentCascadeLine_comment$data = CommentCascadeLine_comment;
export type CommentCascadeLine_comment$key = {
    readonly " $data"?: CommentCascadeLine_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentCascadeLine_comment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentCascadeLine_comment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentCommentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageSizes",
              "kind": "LinkedField",
              "name": "sizes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();
(node as any).hash = 'c4efe11928b792db41e1e0979a45c724';
export default node;
