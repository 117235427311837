import lz from 'lzutf8';
import mongoose from 'mongoose';

import { decodeObjectId, encodeObjectId } from '#database/utils/base64ObjectId';
import { FeedSort, TaggedFeedSort } from '#enum';
import { TagItem } from '#interfaces';
import { FilterFindManyPostInput, FilterFindManyTaggedPostInput } from '~~';

export const feedQuery = (
  tags?: (string | TagItem)[] | null,
): string | undefined => {
  if (!tags?.length) return undefined;

  const tagIds = tags.reduce(
    (res, tag) => [...res, typeof tag === 'string' ? tag : tag.id],
    [] as string[],
  );
  const feed = tagIds
    .sort()
    .map((tagId) => encodeObjectId(tagId))
    .join(',');

  return lz.compress(feed, { outputEncoding: 'Base64' });
};

/**
 * Turn hash string to the array of tagId.
 *
 * @param hashFeed the hash string used to do query from the url
 * @returns tagIds
 */
export const feedValue = (
  hashFeed?: string | string[],
): string[] | undefined => {
  if (!hashFeed?.length) return undefined;

  const hash = Array.isArray(hashFeed) ? hashFeed[0] : hashFeed;
  const tagIdsStr: string = lz.decompress(hash, { inputEncoding: 'Base64' });

  const tagIds = [...tagIdsStr.split(',')].map((tagId) =>
    decodeObjectId(tagId),
  );

  if (
    tagIds.every(
      (tagId): tagId is string =>
        !!tagId && mongoose.Types.ObjectId.isValid(tagId),
    )
  )
    return tagIds;

  return undefined;
};

/**
 * Turn hash string to the query object.
 *
 * @param hashFeed the hash string used to do query from the url
 * @returns query object
 */
export const feedFilter = (
  hashFeed?: string | string[],
): FilterFindManyPostInput | undefined => {
  const noDeleted = { status: { value: null } };

  if (!hashFeed?.length) return noDeleted;

  const tagIds = feedValue(hashFeed);

  const andCriterias = tagIds
    ?.filter((tagId) => tagId)
    .map((tagId) => ({
      tagCollections: { taggedTags: [{ tagId }] },
    }));

  return andCriterias?.length ? { AND: andCriterias } : noDeleted;
};

export const feedFilterTagged = (
  hashFeed?: string | string[],
  viewerId?: string | null,
): FilterFindManyTaggedPostInput | undefined => {
  const tagIds = feedValue(hashFeed);

  const andCriterias = tagIds?.map((tagId) => ({
    tagIds: [tagId],
  }));

  return andCriterias?.length
    ? { userId: viewerId, AND: andCriterias }
    : undefined;
};

export const feedSort = (sort?: string | string[]): FeedSort => {
  const sortValue = Array.isArray(sort) ? sort[0] : sort;
  switch (sortValue) {
    case 'newest':
      return FeedSort.Newest;
    case 'hot':
      return FeedSort.HotScore;
    case 'best':
      return FeedSort.WilsonScore;
    default:
      return FeedSort.FeaturedScore;
  }
};

export const feedSortTagged = (sort?: string | string[]): TaggedFeedSort => {
  const sortValue = Array.isArray(sort) ? sort[0] : sort;
  switch (sortValue) {
    case 'newest':
      return TaggedFeedSort.Newest;
    case 'asc':
      return TaggedFeedSort.TaggedDateAsc;
    default:
      return TaggedFeedSort.TaggedDateDesc;
  }
};

export default null;
