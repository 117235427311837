/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardCommentButton_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CommentDrawer_viewer">;
    readonly " $refType": "PostCardCommentButton_viewer";
};
export type PostCardCommentButton_viewer$data = PostCardCommentButton_viewer;
export type PostCardCommentButton_viewer$key = {
    readonly " $data"?: PostCardCommentButton_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardCommentButton_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardCommentButton_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentDrawer_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '248b969a3ac58af2b4c70c75ec0a9af0';
export default node;
