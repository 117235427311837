/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentBlockHeader_comment = {
    readonly createdBy: {
        readonly id: string;
        readonly username: string;
        readonly displayName: string | null;
    } | null;
    readonly createdDate: string | null;
    readonly " $refType": "CommentBlockHeader_comment";
};
export type CommentBlockHeader_comment$data = CommentBlockHeader_comment;
export type CommentBlockHeader_comment$key = {
    readonly " $data"?: CommentBlockHeader_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentBlockHeader_comment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentBlockHeader_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
(node as any).hash = '28bca80e66a3c25204d5f4707d86d0bf';
export default node;
