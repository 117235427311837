/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Button from '@/Button';
import Text from '@/Text';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from '#styled-system';

export const Container = styled.View<PositionProps & LayoutProps & SpaceProps>`
  position: relative;
  z-index: 1;
  ${position};
  ${layout};
  ${space};
`;

export const TitleContainer = styled.View<ColorProps>`
  margin-top: 26px;
  margin-bottom: 10px;
  padding: 16px;
  ${color};
`;

export const Title = styled(Text)<ColorProps>`
  color: ${(props: any) => props.theme.colors.text};
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.4px;
  ${color};
`;

export const ReadMoreButton = styled(Button)<
  PositionProps & ColorProps & BorderProps
>`
  align-self: center;
  width: fit-content;
  ${position};
  ${color};
  ${border};
`;

export default null;
