/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardHeaderMenu_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"ReportPostForm_viewer">;
    readonly " $refType": "PostCardHeaderMenu_viewer";
};
export type PostCardHeaderMenu_viewer$data = PostCardHeaderMenu_viewer;
export type PostCardHeaderMenu_viewer$key = {
    readonly " $data"?: PostCardHeaderMenu_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardHeaderMenu_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardHeaderMenu_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReportPostForm_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '5ba5eb55300a674156906978c8bc0e83';
export default node;
