import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { IChannelFilter } from '#database/models';
import { CBMutationConfig } from '#interfaces';
import { CreateCommunityMutation } from '~/CreateCommunityMutation.graphql';

const mutation = graphql`
  mutation CreateCommunityMutation(
    $userId: MongoID!
    $name: String!
    $images: CommunityImagesInput
    $filters: [ChannelFilterInput!]!
    $settings: [KeyValueInput]
  ) {
    userCommunityCreateCommunity(
      userId: $userId
      name: $name
      images: $images
      filters: $filters
      settings: $settings
    ) {
      recordId
      record {
        community {
          id
          channelId
          name
          filters {
            filterObjectType
            filterObjectId
            condition
          }
        }
      }
    }
  }
`;

const createCommunity = (
  environment: Environment,
  args: {
    userId: string;
    communityName: string;
    filters: IChannelFilter[];
  },
  config?: CBMutationConfig<CreateCommunityMutation>,
): Disposable => {
  const variables = {
    userId: args.userId,
    name: args.communityName,
    filters: args.filters.filter(
      (filter): filter is NonNullable<IChannelFilter> => filter !== null,
    ),
  };

  return commitMutation<CreateCommunityMutation>(environment, {
    ...config,
    mutation,
    variables,
  });
};

export default createCommunity;
