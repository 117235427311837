import React from 'react';
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from 'react-relay';

import PostCard from '@/PostCard';
import { PostCardFullContent_post$key } from '~/PostCardFullContent_post.graphql';
import { PostCardFullContent_viewer$key } from '~/PostCardFullContent_viewer.graphql';
import { PostCardFullContentQuery } from '~/PostCardFullContentQuery.graphql';

export const query = graphql`
  query PostCardFullContentQuery($postId: MongoID!) {
    postFindById(_id: $postId) {
      content
    }
  }
`;

const viewerFragment = graphql`
  fragment PostCardFullContent_viewer on User {
    ...PostCard_viewer
  }
`;

const postFragment = graphql`
  fragment PostCardFullContent_post on Post
  @argumentDefinitions(viewerId: { type: "MongoID" }) {
    ...PostCard_post @arguments(viewerId: $viewerId)
  }
`;

type Props = {
  queryRef: PreloadedQuery<PostCardFullContentQuery>;
  viewer: PostCardFullContent_viewer$key | null;
  post: PostCardFullContent_post$key;
} & Omit<React.ComponentProps<typeof PostCard>, 'viewer' | 'post'>;

const PostCardFullContent = ({ queryRef, viewer, post, ...props }: Props) => {
  const data = usePreloadedQuery(query, queryRef);
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  const containerStyle = React.useMemo(
    () => ({
      margin: '0px',
    }),
    [],
  );

  return data.postFindById?.content ? (
    <PostCard
      viewer={viewerData}
      post={postData}
      content={data.postFindById?.content}
      borderRadius="0"
      hideSidebar
      hideFooter
      containerStyle={containerStyle}
      {...props}
    />
  ) : null;
};

export default React.memo(PostCardFullContent);
