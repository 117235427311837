/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';

import CommentFeed from '@/CommentFeed';
import { CommentEmptyFeedQuery } from '~/CommentEmptyFeedQuery.graphql';

export const query = graphql`
  query CommentEmptyFeedQuery(
    $viewerId: MongoID
    $count: Int
    $cursor: String
    $filter: FilterFindManyCommentInput
    $sort: SortGetCommentsCommentEnum
    $getFstSubComments: Boolean!
    $getSndSubComments: Boolean!
    $getTrdSubComments: Boolean!
    $getFthSubComments: Boolean!
  ) {
    viewer(_id: $viewerId) {
      ...CommentFeed_viewer
    }
    ...CommentFeed_comments
      @arguments(
        viewerId: $viewerId
        count: $count
        cursor: $cursor
        filter: $filter
        sort: $sort
        getFstSubComments: $getFstSubComments
        getSndSubComments: $getSndSubComments
        getTrdSubComments: $getTrdSubComments
        getFthSubComments: $getFthSubComments
      )
  }
`;

type Props = {
  queryRef: PreloadedQuery<CommentEmptyFeedQuery>;
};

/**
 * The main usage is only to contain the viewer newly replied comments
 */
const CommentEmptyFeed = ({ queryRef }: Props) => {
  const data = usePreloadedQuery(query, queryRef);

  return (
    <CommentFeed
      viewer={data?.viewer ?? null}
      comments={data ?? null}
      recordConnection
    />
  );
};

export default React.memo(CommentEmptyFeed);
