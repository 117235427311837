import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import ReportForm from '@/ReportForm';
import useFbt from '#hooks/useFbt';
import { ReportPostForm_post$key } from '~/ReportPostForm_post.graphql';
import { ReportPostForm_viewer$key } from '~/ReportPostForm_viewer.graphql';

const viewerFragment = graphql`
  fragment ReportPostForm_viewer on User {
    ...ReportForm_viewer
  }
`;

const postFragment = graphql`
  fragment ReportPostForm_post on Post {
    id
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: ReportPostForm_viewer$key | null;
  post: ReportPostForm_post$key;
  visible?: boolean;
  onDismiss?: () => void;
};

const title = fbt('Report post', 'form title');

const items = [
  {
    value: 'hateful-or-abusive-content',
    label: fbt('Hateful or abusive content', 'button label'),
  },
  { value: 'child-abuse', label: fbt('Child abuse', 'button label') },
  {
    value: 'promotes-terrorism',
    label: fbt('Promotes terrorism', 'button label'),
  },
  {
    value: 'spam-or-misleading',
    label: fbt('Spam or misleading', 'button label'),
  },
  {
    value: 'infringes-my-right',
    label: fbt('Infringes my right', 'button label'),
  },
];

const ReportPostForm = ({ viewer, post, visible, onDismiss }: Props) => {
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  useFbt();

  const message = `Post ID: ${postData.id}`;

  return (
    <ReportForm
      visible={visible}
      viewer={viewerData}
      title={title}
      items={items}
      message={message}
      onDismiss={onDismiss}
    />
  );
};

export default React.memo(ReportPostForm);
