import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';

import CommentActionButton from '@/CommentActionButton';
import ConfirmationDialog from '@/ConfirmationDialog';
import { Status } from '#enum';
import useCommentGlobal from '#hooks/useCommentGlobal';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';
import { CommentButtonDelete_comment$key } from '~/CommentButtonDelete_comment.graphql';
import { CommentButtonDeleteMutation } from '~/CommentButtonDeleteMutation.graphql';

const deleteCommentMutation = graphql`
  mutation CommentButtonDeleteMutation(
    $commentId: MongoID!
    $postId: MongoID!
    $parentCommentId: MongoID
  ) @raw_response_type {
    commentRemoveById(
      _id: $commentId
      postId: $postId
      parentCommentId: $parentCommentId
    ) {
      record {
        id
        status {
          value
        }
      }
    }
  }
`;

const commentFragment = graphql`
  fragment CommentButtonDelete_comment on Comment {
    id
    parentCommentId
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  comment: CommentButtonDelete_comment$key;
};

const CommentButtonDelete = ({ comment }: Props) => {
  const [deleteComment, isDeleting] = useMutation<CommentButtonDeleteMutation>(
    deleteCommentMutation,
  );
  const commentData = useFragment(commentFragment, comment);

  useFbt();

  const [visible, setVisible] = React.useState(false);
  const { colors } = useTheme();
  const { activePostId, rootCommentId, setRootCommentId } = useCommentGlobal();

  const handlePress = () => {
    if (activePostId && commentData.id) {
      deleteComment({
        variables: {
          commentId: commentData.id,
          postId: activePostId,
          parentCommentId: commentData.parentCommentId,
        },
        onCompleted: () => {
          if (rootCommentId === commentData.id) setRootCommentId(undefined);
          setVisible(false);
        },
        optimisticResponse: {
          commentRemoveById: {
            record: {
              id: commentData.id,
              status: {
                value: Status.Deleted,
              },
            },
          },
        },
      });
    }
  };

  return (
    <>
      <CommentActionButton onPress={() => setVisible(true)}>
        <fbt desc="text button">Delete</fbt>
      </CommentActionButton>
      <ConfirmationDialog
        isLoading={isDeleting}
        disabled={isDeleting}
        visible={visible}
        title={fbt('Delete comment', 'confirmation title')}
        message={fbt(
          'Are you sure you want to delete this comment?',
          'confirmation message',
        )}
        submitLabel={fbt('Delete', 'button label')}
        submitProps={{
          color: colors?.danger,
          onPress: handlePress,
        }}
        cancelProps={{ onPress: () => setVisible(false) }}
      />
    </>
  );
};

export default React.memo(CommentButtonDelete);
