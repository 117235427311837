import { VoteType } from '#enum';

const getVoteInc = (
  vote?: VoteType,
  prevVote?: VoteType,
): { upvote: number; downvote: number } => {
  const defaultValue = { upvote: 0, downvote: 0 };
  if (!vote) return defaultValue;

  const isPrevUnvote = !prevVote || prevVote === VoteType.Unvote;

  switch (true) {
    case vote === VoteType.Upvote && isPrevUnvote:
      return { upvote: 1, downvote: 0 };
    case vote === VoteType.Upvote && prevVote === VoteType.Downvote:
      return { upvote: 1, downvote: -1 };
    case vote === VoteType.Downvote && isPrevUnvote:
      return { upvote: 0, downvote: 1 };
    case vote === VoteType.Downvote && prevVote === VoteType.Upvote:
      return { upvote: -1, downvote: 1 };
    case vote === VoteType.Unvote && prevVote === VoteType.Upvote:
      return { upvote: -1, downvote: 0 };
    case vote === VoteType.Unvote && prevVote === VoteType.Downvote:
      return { upvote: 0, downvote: -1 };
    default:
      return defaultValue;
  }
};

export default getVoteInc;
