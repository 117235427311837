/* eslint-disable @typescript-eslint/no-explicit-any */
import { getBasicPostProps, getBasicPostStats } from '#analytics/helpers';
import { FullPostProps } from '#interfaces';

import useAnalyticsContext from './useAnalyticsContext';

const useFullPostAnalytics = (
  content:
    | {
        vote?: { upvotes: number | null; downvotes: number | null } | null;
        count?: { shared: number | null; tagged: number | null } | null;
        meta?: ReadonlyArray<{ key: string; value: any } | null> | null;
      }
    | undefined
    | null,
): FullPostProps => {
  const { feedType, feedTags, feedSorted } = useAnalyticsContext();
  const basicPostProps = getBasicPostProps(content?.meta);
  const basicPostStats = getBasicPostStats(content);

  return Object.freeze({
    feedType,
    feedTags,
    feedSorted,
    ...basicPostProps,
    ...basicPostStats,
  });
};

export default useFullPostAnalytics;
