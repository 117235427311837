/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostFeedMain_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"Post_viewer" | "PostFeedEmpty_viewer">;
    readonly " $refType": "PostFeedMain_viewer";
};
export type PostFeedMain_viewer$data = PostFeedMain_viewer;
export type PostFeedMain_viewer$key = {
    readonly " $data"?: PostFeedMain_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostFeedMain_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostFeedMain_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Post_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostFeedEmpty_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '70ea4b39eb4b8a8eb614bcec4a20f383';
export default node;
