/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import { margin, MarginProps } from '#styled-system';

export const Container = styled.View<MarginProps>`
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0 8px 12px;
  ${margin};
`;

export const NavContainer = styled.View`
  flex: 2 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Nav = styled(Text)`
  color: ${(props: any) => props.theme.colors.text3};
  line-height: 150%;
`;

export default null;
