/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const DeletedUserText = styled(Text)`
  color: ${(props: any): string => props.theme.colors.text2};
`;

export default null;
