import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CommentBlock from '@/CommentBlock';
import useCommentGlobal from '#hooks/useCommentGlobal';
import {
  CommentThread_comment,
  CommentThread_comment$key,
} from '~/CommentThread_comment.graphql';
import { CommentThread_viewer$key } from '~/CommentThread_viewer.graphql';

import { Container } from './CommentThread.style';

const viewerFragment = graphql`
  fragment CommentThread_viewer on User {
    ...CommentBlock_viewer
  }
`;

const commentFragment = graphql`
  fragment CommentThread_comment on Comment
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    sort: { type: "SortGetCommentsCommentEnum", defaultValue: FEATURED_SCORE }
    featuredSubCommentType: { type: "String", defaultValue: "featured" }
    getFstSubComments: { type: "Boolean!" }
    getSndSubComments: { type: "Boolean!" }
    getTrdSubComments: { type: "Boolean!" }
    getFthSubComments: { type: "Boolean!" }
  ) {
    ...CommentBlock_comment @arguments(viewerId: $viewerId)
    id
    parentCommentId
    featuredSubComments(featuredSubCommentType: $featuredSubCommentType)
      @include(if: $getFstSubComments) {
      ...CommentBlock_comment @arguments(viewerId: $viewerId)
      id
      featuredSubComments(featuredSubCommentType: $featuredSubCommentType)
        @include(if: $getSndSubComments) {
        ...CommentBlock_comment @arguments(viewerId: $viewerId)
        id
        featuredSubComments(featuredSubCommentType: $featuredSubCommentType)
          @include(if: $getTrdSubComments) {
          ...CommentBlock_comment @arguments(viewerId: $viewerId)
          id
          featuredSubComments(featuredSubCommentType: $featuredSubCommentType)
            @include(if: $getFthSubComments) {
            ...CommentBlock_comment @arguments(viewerId: $viewerId)
            id
          }
        }
      }
    }
  }
`;

type Props = {
  viewer: CommentThread_viewer$key | null;
  comment: CommentThread_comment$key;
  zIndex?: number;
  subCommentDepth?: number;
};

const getIds = (data: {
  featuredSubComments?: CommentThread_comment['featuredSubComments'];
}) => data.featuredSubComments?.map((c) => c?.id);

/**
 * Render comment threads with 3 cascading level of featured sub-comments
 */
const CommentThread = ({
  viewer,
  comment,
  zIndex,
  subCommentDepth,
}: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);
  const commentData = useFragment(commentFragment, comment);

  const { setRootCommentId } = useCommentGlobal();
  const commentDepth = Math.min(subCommentDepth ?? 2, 2);

  return (
    <Container
      zIndex={zIndex}
      marginBottom={!commentData.parentCommentId ? '8px' : undefined}
    >
      <CommentBlock
        viewer={viewerData}
        comment={commentData ?? null}
        subCommentDepth={commentDepth}
        fetchedIds={getIds(commentData)}
      >
        {commentDepth > 0
          ? commentData.featuredSubComments?.map(
              (fstSubComment, i) =>
                fstSubComment && (
                  <CommentBlock
                    key={fstSubComment.id}
                    zIndex={0 - i}
                    viewer={viewerData}
                    comment={fstSubComment}
                    subCommentDepth={commentDepth - 1}
                    fetchedIds={getIds(fstSubComment)}
                  >
                    {commentDepth > 1
                      ? fstSubComment?.featuredSubComments?.map(
                          (sndSubComment, j) =>
                            sndSubComment && (
                              <CommentBlock
                                key={sndSubComment.id}
                                zIndex={0 - j}
                                viewer={viewerData}
                                comment={sndSubComment}
                                subCommentDepth={commentDepth - 2}
                                fetchedIds={getIds(sndSubComment)}
                              >
                                {commentDepth > 2
                                  ? sndSubComment?.featuredSubComments?.map(
                                      (trdSubComment, k) =>
                                        trdSubComment && (
                                          <CommentBlock
                                            key={trdSubComment.id}
                                            zIndex={0 - k}
                                            viewer={viewerData}
                                            comment={trdSubComment}
                                            subCommentDepth={commentDepth - 3}
                                            fetchedIds={getIds(trdSubComment)}
                                          >
                                            {commentDepth > 3
                                              ? trdSubComment?.featuredSubComments?.map(
                                                  (fthSubComment, l) =>
                                                    fthSubComment && (
                                                      <CommentBlock
                                                        key={fthSubComment.id}
                                                        zIndex={0 - l}
                                                        viewer={viewerData}
                                                        comment={fthSubComment}
                                                        subCommentDepth={
                                                          commentDepth - 4
                                                        }
                                                        onLoadMorePress={() =>
                                                          setRootCommentId(
                                                            fthSubComment.id,
                                                          )
                                                        }
                                                      />
                                                    ),
                                                )
                                              : null}
                                          </CommentBlock>
                                        ),
                                    )
                                  : null}
                              </CommentBlock>
                            ),
                        )
                      : null}
                  </CommentBlock>
                ),
            )
          : null}
      </CommentBlock>
    </Container>
  );
};

export default CommentThread;
