/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import { margin, MarginProps } from '#styled-system';

export const Container = styled.View<MarginProps>`
  display: flex;
  flex: 1 1 auto;
  ${margin};
`;

export const CommentsContainer = styled.View`
  position: relative;
  flex: 1 1 auto;
`;

export const ExtraContainer = styled.View`
  position: absolute;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
`;

export const NoCommentText = styled(Text)`
  color: ${(props: any) => props.theme.colors.blackOpac20};
  font-weight: bold;
  font-size: 14px;
`;

export default null;
