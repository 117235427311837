import fbt from 'fbt';
import React from 'react';
import { StyleSheet } from 'react-native';
import { RadioButton } from 'react-native-paper';
import { graphql, useFragment } from 'react-relay';

import DialogActionButtons from '@/DialogActionBttons';
import ModalCard from '@/ModalCard';
import useTheme from '#hooks/useTheme';
import { ReportForm_viewer$key } from '~/ReportForm_viewer.graphql';
import { slackWebhookUrlUserReports } from '~@';

const viewerFragment = graphql`
  fragment ReportForm_viewer on User {
    id
    fullName
    emails {
      emailAddress
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: ReportForm_viewer$key | null;
  visible?: boolean;
  title: string;
  items: {
    value: string;
    label: string;
  }[];
  message?: string;
  onDismiss?: () => void;
};

const ReportForm = ({
  viewer,
  visible,
  title,
  items,
  message,
  onDismiss,
}: Props) => {
  const viewerData = useFragment(viewerFragment, viewer);

  const [value, setValue] = React.useState('');
  const { colors } = useTheme();

  const s = React.useMemo(
    () =>
      StyleSheet.create({
        style: {
          flex: 1,
          flexDirection: 'row-reverse',
          minWidth: 450,
          maxHeight: 40,
        },
        labelStyle: {
          marginStart: 6,
          color: colors?.text,
          fontWeight: '500',
          fontSize: 14,
        },
      }),
    [colors?.text],
  );

  const activeColor = colors?.blue;

  const handleSubmit = React.useCallback(async () => {
    if (value && viewerData) {
      const userId = `User ID: ${viewerData.id}`;
      const userFullName = `User full name: ${viewerData.fullName}`;
      const userEmail = `User email: ${
        viewerData.emails?.length ? viewerData.emails[0]?.emailAddress : ''
      }`;

      const userInfo = `${userId}\n${userFullName}\n${userEmail}`;
      const payload = {
        method: 'POST',
        body: JSON.stringify({ text: `${message}\n\n${value}\n\n${userInfo}` }),
      };

      if (slackWebhookUrlUserReports)
        await fetch(slackWebhookUrlUserReports, payload);
    }
  }, [message, value, viewerData]);

  const handleCancel = React.useCallback(() => {
    setValue('');
    onDismiss?.();
  }, [onDismiss]);

  return viewer ? (
    <ModalCard
      title={title}
      visible={visible ?? false}
      maxWidth="450px"
      onDismiss={handleCancel}
    >
      <RadioButton.Group
        value={value}
        onValueChange={(newValue) => setValue(newValue)}
      >
        {items.map((item) => (
          <RadioButton.Item
            key={item.value}
            mode="android"
            value={item.value}
            label={item.label}
            color={activeColor}
            style={s.style}
            labelStyle={s.labelStyle}
          />
        ))}
      </RadioButton.Group>
      <DialogActionButtons
        submitLabel={fbt('Report', 'button label')}
        submitProps={{
          color: colors?.blue,
          disabled: !value,
          onPress: handleSubmit,
        }}
        cancelProps={{
          onPress: handleCancel,
        }}
      />
    </ModalCard>
  ) : null;
};

export default React.memo(ReportForm);
