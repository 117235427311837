import fbt from 'fbt';
import React from 'react';
import { ActivityIndicator } from 'react-native';
import { RelayPaginationProp } from 'react-relay';

import Button from '@/Button';
import FeedMarginals from '@/FeedMarginals';
import PaleBoldText from '@/PaleBoldText';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  buttonLabel?: string;
  noMoreText?: string;
  hasMore?: boolean;
  isLoading?: boolean;
  onPress: () => ReturnType<RelayPaginationProp['loadMore']>;
};

const LoadMoreConnection = ({
  buttonLabel,
  noMoreText,
  hasMore,
  isLoading,
  onPress,
}: Props): React.ReactElement => {
  useFbt();

  const { colors } = useTheme();

  const loadingComponent = React.useMemo(
    () =>
      isLoading ? (
        <ActivityIndicator
          size={24}
          style={{ marginBottom: 6, marginTop: 6 }}
        />
      ) : (
        <Button
          uppercase={false}
          onPress={onPress}
          disabled={isLoading}
          labelStyle={{
            color: colors?.blue2,
            fontWeight: '700',
            fontSize: 14,
            letterSpacing: 0.25,
          }}
        >
          {buttonLabel ?? <fbt desc="button label">Load More</fbt>}
        </Button>
      ),
    [buttonLabel, colors?.blue2, isLoading, onPress],
  );

  const noMore = React.useMemo(
    () => (
      <PaleBoldText>
        {noMoreText ?? <fbt desc="footer message">No more post</fbt>}
      </PaleBoldText>
    ),
    [noMoreText],
  );

  return <FeedMarginals>{hasMore ? loadingComponent : noMore}</FeedMarginals>;
};

export default React.memo(LoadMoreConnection);
