/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentButtonReport_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"ReportCommentForm_viewer">;
    readonly " $refType": "CommentButtonReport_viewer";
};
export type CommentButtonReport_viewer$data = CommentButtonReport_viewer;
export type CommentButtonReport_viewer$key = {
    readonly " $data"?: CommentButtonReport_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentButtonReport_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentButtonReport_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReportCommentForm_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'c66c794a9c9ae3dbba6e9ef52c8d7cf7';
export default node;
