/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type CreateOneCommentInput = {
    status?: DocumentStatusInput | null;
    postId: string;
    communityId?: string | null;
    parentCommentId?: string | null;
    version?: string | null;
    comment: string;
    featuredSubCommentIds?: CommentFeaturedSubCommentIdsInput | null;
    vote?: CommentVoteInput | null;
    featuredScore?: number | null;
    wilsonScore?: number | null;
    hotScore?: number | null;
    scoreInfo?: CommentScoreInfoInput | null;
    count?: CommentCountInput | null;
    createdDate?: string | null;
    createdByUserId: string;
    lastModifiedDate?: string | null;
    lastModifiedByUserId?: string | null;
    settings?: Array<KeyValueInput | null> | null;
    resource?: Array<KeyValueInput | null> | null;
    meta?: Array<KeyValueInput | null> | null;
};
export type DocumentStatusInput = {
    value?: EnumDocumentStatusValue | null;
    reason?: string | null;
    timestamp?: string | null;
};
export type CommentFeaturedSubCommentIdsInput = {
    featured?: Array<FeaturedSubCommentInput | null> | null;
    hot?: Array<FeaturedSubCommentInput | null> | null;
    best?: Array<FeaturedSubCommentInput | null> | null;
    controversial?: Array<FeaturedSubCommentInput | null> | null;
    oldest?: Array<FeaturedSubCommentInput | null> | null;
    newest?: Array<FeaturedSubCommentInput | null> | null;
};
export type FeaturedSubCommentInput = {
    commentId: string;
    value?: unknown | null;
};
export type CommentVoteInput = {
    upvotes?: number | null;
    downvotes?: number | null;
};
export type CommentScoreInfoInput = {
    lastUpdatedDate?: string | null;
};
export type CommentCountInput = {
    totalSubComments?: number | null;
    activeSubComments?: number | null;
};
export type KeyValueInput = {
    key: string;
    value?: unknown | null;
};
export type CreateCommentMutationVariables = {
    connections: Array<string>;
    record: CreateOneCommentInput;
};
export type CreateCommentMutationResponse = {
    readonly commentCreateOne: {
        readonly record: {
            readonly id: string;
            readonly postId: string;
            readonly parentCommentId: string | null;
            readonly comment: string;
            readonly vote: {
                readonly upvotes: number | null;
                readonly downvotes: number | null;
            } | null;
            readonly createdBy: {
                readonly id: string;
                readonly username: string;
                readonly displayName: string | null;
            } | null;
            readonly createdDate: string | null;
            readonly createdByUserId: string;
        } | null;
    } | null;
};
export type CreateCommentMutation = {
    readonly response: CreateCommentMutationResponse;
    readonly variables: CreateCommentMutationVariables;
};



/*
mutation CreateCommentMutation(
  $record: CreateOneCommentInput!
) {
  commentCreateOne(record: $record) {
    record {
      id
      postId
      parentCommentId
      comment
      vote {
        upvotes
        downvotes
      }
      createdBy {
        id
        username
        displayName
      }
      createdDate
      createdByUserId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "record",
    "variableName": "record"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Comment",
  "kind": "LinkedField",
  "name": "record",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentCommentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentVote",
      "kind": "LinkedField",
      "name": "vote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "upvotes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downvotes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdByUserId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOneCommentPayload",
        "kind": "LinkedField",
        "name": "commentCreateOne",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOneCommentPayload",
        "kind": "LinkedField",
        "name": "commentCreateOne",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "record",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "CommentEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2ff4c890c871d9a28e611a1306c68bf",
    "id": null,
    "metadata": {},
    "name": "CreateCommentMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCommentMutation(\n  $record: CreateOneCommentInput!\n) {\n  commentCreateOne(record: $record) {\n    record {\n      id\n      postId\n      parentCommentId\n      comment\n      vote {\n        upvotes\n        downvotes\n      }\n      createdBy {\n        id\n        username\n        displayName\n      }\n      createdDate\n      createdByUserId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '020e32207006f893c3470c2d2f47bf49';
export default node;
