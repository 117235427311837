import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CenterContent from '@/CenterContent';
import Features from '@/Features';
import LinealButton from '@/LinealButton';
import PostForm from '@/PostForm';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import SocialMediaImg from '#public/images/copywriting.png';
import { PostFeedEmpty_viewer$key } from '~/PostFeedEmpty_viewer.graphql';

import { ButtonContainer, Message } from './PostFeedEmpty.style';

const viewerFragment = graphql`
  fragment PostFeedEmpty_viewer on User {
    id
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: PostFeedEmpty_viewer$key | null;
};

const PostFeedEmpty = ({ viewer }: Props) => {
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const [, { lteSm }] = useResponsive();
  const [isFormOpen, setIsFormOpen] = React.useState(false);

  const openForm = React.useCallback(() => setIsFormOpen(true), []);
  const closeForm = React.useCallback(() => setIsFormOpen(false), []);

  return viewerData?.id ? (
    <>
      <PostForm
        viewerId={viewerData.id}
        post={null}
        visible={isFormOpen}
        onDismiss={closeForm}
      />
      <CenterContent>
        <Features imageUri={SocialMediaImg} vertical={lteSm}>
          <Message>
            <fbt desc="message">Share your thought to the world</fbt>
          </Message>
          <ButtonContainer onPress={openForm}>
            <LinealButton fontSize="18px">
              <fbt desc="button label">Create new post!</fbt>
            </LinealButton>
          </ButtonContainer>
        </Features>
      </CenterContent>
    </>
  ) : null;
};

export default React.memo(PostFeedEmpty);
