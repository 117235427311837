/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumChannelFilterFilterObjectType = "Tag" | "User" | "%future added value";
export type UpdateByIdChannelFilterInput = {
    filterObjectType?: EnumChannelFilterFilterObjectType | null;
    filterObjectId?: string | null;
    condition?: number | null;
};
export type CurrentCommunityUpdateCommunityFiltersMutationVariables = {
    communityId: string;
    filters?: Array<UpdateByIdChannelFilterInput | null> | null;
    viewerId: string;
};
export type CurrentCommunityUpdateCommunityFiltersMutationResponse = {
    readonly communityUpdate: {
        readonly record: {
            readonly id: string;
            readonly filterObjects: ReadonlyArray<{
                readonly type: EnumChannelFilterFilterObjectType;
                readonly condition: number | null;
                readonly payload: {
                    readonly id?: string;
                    readonly label?: string;
                } | null;
            }> | null;
        } | null;
    } | null;
};
export type CurrentCommunityUpdateCommunityFiltersMutation = {
    readonly response: CurrentCommunityUpdateCommunityFiltersMutationResponse;
    readonly variables: CurrentCommunityUpdateCommunityFiltersMutationVariables;
};



/*
mutation CurrentCommunityUpdateCommunityFiltersMutation(
  $communityId: MongoID!
  $filters: [UpdateByIdChannelFilterInput]
  $viewerId: MongoID!
) {
  communityUpdate(_id: $communityId, record: {filters: $filters}, viewerId: $viewerId) {
    record {
      id
      filterObjects {
        type
        condition
        payload {
          __typename
          ... on Tag {
            id
            label
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "communityId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_id",
    "variableName": "communityId"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      }
    ],
    "kind": "ObjectValue",
    "name": "record"
  },
  {
    "kind": "Variable",
    "name": "viewerId",
    "variableName": "viewerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "condition",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "Tag",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentCommunityUpdateCommunityFiltersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateByIdCommunityPayload",
        "kind": "LinkedField",
        "name": "communityUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Community",
            "kind": "LinkedField",
            "name": "record",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FilterObject",
                "kind": "LinkedField",
                "name": "filterObjects",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "payload",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurrentCommunityUpdateCommunityFiltersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateByIdCommunityPayload",
        "kind": "LinkedField",
        "name": "communityUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Community",
            "kind": "LinkedField",
            "name": "record",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FilterObject",
                "kind": "LinkedField",
                "name": "filterObjects",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "payload",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f737d542af3e5586f9d5c453ff5931e8",
    "id": null,
    "metadata": {},
    "name": "CurrentCommunityUpdateCommunityFiltersMutation",
    "operationKind": "mutation",
    "text": "mutation CurrentCommunityUpdateCommunityFiltersMutation(\n  $communityId: MongoID!\n  $filters: [UpdateByIdChannelFilterInput]\n  $viewerId: MongoID!\n) {\n  communityUpdate(_id: $communityId, record: {filters: $filters}, viewerId: $viewerId) {\n    record {\n      id\n      filterObjects {\n        type\n        condition\n        payload {\n          __typename\n          ... on Tag {\n            id\n            label\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f69340ca11b10d38b6e080a44e44fe4b';
export default node;
