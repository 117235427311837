import fbt from 'fbt';
import React from 'react';
import {
  fetchQuery,
  graphql,
  useFragment,
  useRelayEnvironment,
} from 'react-relay';

import ConfirmationDialog from '@/ConfirmationDialog';
import PostForm from '@/PostForm';
import ReportPostForm from '@/ReportPostForm';
import { postDeleted } from '#analytics/app/post';
import useFbt from '#hooks/useFbt';
import useFullPostAnalytics from '#hooks/useFullPostAnalytics';
import useModals from '#hooks/useModals';
import useSession from '#hooks/useSession';
import useTheme from '#hooks/useTheme';
import { RelayConnection } from '#interfaces';
import deletePost from '#mutations/DeletePost';
import { PostCardHeaderMenu_post$key } from '~/PostCardHeaderMenu_post.graphql';
import { PostCardHeaderMenu_viewer$key } from '~/PostCardHeaderMenu_viewer.graphql';
import { PostCardHeaderMenuQuery } from '~/PostCardHeaderMenuQuery.graphql';

import { Menu, MenuButton } from './PostCardHeaderMenu.style';

const viewerFragment = graphql`
  fragment PostCardHeaderMenu_viewer on User {
    id
    ...ReportPostForm_viewer
  }
`;

const postFragment = graphql`
  fragment PostCardHeaderMenu_post on Post
  @argumentDefinitions(viewerId: { type: "MongoID" }) {
    id
    vote {
      upvotes
      downvotes
    }
    count {
      shared
      tagged
    }
    meta {
      key
      value
    }
    createdByUserId
    createdDate
    ...PostForm_post @arguments(viewerId: $viewerId)
    ...ReportPostForm_post
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: PostCardHeaderMenu_viewer$key | null;
  post: PostCardHeaderMenu_post$key;
  connection: RelayConnection;
};

enum ModalType {
  EditContent,
  DeleteConfirmation,
  ReportPost,
}

// Helper function
const getUserIdFromSettings = (
  key: string,
  settings: ({
    key: string;
    value: unknown | null;
  } | null)[],
): string[] => {
  const setting = (settings?.find((attr) => attr?.key === key)?.value ??
    []) as {
    type: string;
    id: string;
  }[];

  return setting.reduce(
    (res, item) => (item.type === 'user' ? [...res, item.id] : res),
    [] as string[],
  );
};

// Component
const PostCardHeaderMenu = ({
  viewer,
  post,
  connection,
}: Props): React.ReactElement => {
  const environment = useRelayEnvironment();
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  useFbt();

  const [session] = useSession();
  const [visible, setVisible] = React.useState(false);
  const [activeModal, setActiveModal] = React.useState<ModalType>();
  const [permission, setPermission] = React.useState<{
    whoCanEdit?: string[];
    whoCanDelete?: string[];
  }>();
  const fullPostProps = useFullPostAnalytics(postData);
  const { colors } = useTheme();
  const { openSignUpLoginModal } = useModals();

  const isOwner = viewerData?.id === postData?.createdByUserId;
  const canEdit = viewerData && permission?.whoCanEdit?.includes(viewerData.id);
  const canDelete =
    viewerData && permission?.whoCanDelete?.includes(viewerData.id);

  // TODO: make allow list can use userTags together with userId
  const updateUserPermission = React.useCallback(async () => {
    if (environment && !isOwner && !permission) {
      fetchQuery<PostCardHeaderMenuQuery>(
        environment,
        graphql`
          query PostCardHeaderMenuQuery($id: MongoID!) {
            postFindById(_id: $id) {
              settings {
                key
                value
              }
            }
          }
        `,
        { id: postData?.id },
      ).subscribe({
        next: (data) => {
          const postSettings = data.postFindById?.settings;
          const settings = postSettings ? [...postSettings] : [];
          const whoCanEdit = getUserIdFromSettings('WHO_CAN_EDIT', settings);
          const whoCanDelete = getUserIdFromSettings(
            'WHO_CAN_DELETE',
            settings,
          );

          setPermission({ whoCanEdit, whoCanDelete });
        },
      });
    }
  }, [environment, isOwner, permission, postData?.id]);

  const openMenu = React.useCallback(() => {
    updateUserPermission();
    setVisible(true);
  }, [updateUserPermission]);

  const closeMenu = React.useCallback(() => setVisible(false), []);

  const openEditContent = React.useCallback(() => {
    setActiveModal(ModalType.EditContent);
    closeMenu();
  }, [closeMenu]);

  const openDeleteConfirmation = React.useCallback(async () => {
    setActiveModal(ModalType.DeleteConfirmation);
    closeMenu();

    await postDeleted({
      createdDate: postData?.createdDate,
      ...fullPostProps,
    });
  }, [closeMenu, postData?.createdDate, fullPostProps]);

  const handleReportPress = React.useCallback(() => {
    if (viewerData?.id) {
      setActiveModal(ModalType.ReportPost);
    } else {
      openSignUpLoginModal();
    }
  }, [openSignUpLoginModal, viewerData?.id]);

  const closeModal = React.useCallback(() => setActiveModal(undefined), []);

  const handleConfirm = React.useCallback(
    () =>
      deletePost(environment, postData?.id, connection, {
        onCompleted: () => closeModal(),
        onError: () => closeModal(),
      }),
    [closeModal, connection, environment, postData?.id],
  );

  const icon = React.useMemo(
    () => (
      <MenuButton
        name="dots-vertical"
        size={24}
        color={colors?.text}
        onPress={openMenu}
      />
    ),
    [colors?.text, openMenu],
  );

  return (
    <>
      <Menu visible={visible} onDismiss={closeMenu} anchor={icon}>
        {session?.user && (isOwner || canEdit) && (
          <Menu.Item title="Edit" onPress={openEditContent} />
        )}
        {session?.user && (isOwner || canDelete) && (
          <Menu.Item title="Delete" onPress={openDeleteConfirmation} />
        )}
        {(!session?.user || !isOwner) && (
          <Menu.Item title="Report" onPress={handleReportPress} />
        )}
      </Menu>
      {viewerData?.id && (
        <>
          <PostForm
            viewerId={viewerData.id}
            post={postData}
            visible={activeModal === ModalType.EditContent}
            onDismiss={closeModal}
          />
          <ConfirmationDialog
            title={fbt('Confirm delete', 'form title')}
            message={fbt('Are you sure to delete this post?', 'form message')}
            visible={activeModal === ModalType.DeleteConfirmation}
            submitLabel={fbt('Delete', 'button label')}
            submitProps={{ color: colors?.danger, onPress: handleConfirm }}
            cancelProps={{ onPress: closeModal }}
          />
        </>
      )}
      <ReportPostForm
        viewer={viewerData ?? null}
        post={postData}
        visible={activeModal === ModalType.ReportPost}
        onDismiss={closeModal}
      />
    </>
  );
};

export default React.memo(PostCardHeaderMenu);
