import React from 'react';
import { graphql, useFragment } from 'react-relay';

import Avatar from '@/Avatar';
import getImageBySize from '#database/utils/getImageBySize';
import { ImageSize } from '#enum';
import useCommentGlobal from '#hooks/useCommentGlobal';
import useTheme from '#hooks/useTheme';
import { CommentCascadeLine_comment$key } from '~/CommentCascadeLine_comment.graphql';

import {
  AvatarContainer,
  Container,
  VerticalLine,
  VerticalLineButton,
} from './CommentCascadeLine.style';

const commentFragment = graphql`
  fragment CommentCascadeLine_comment on Comment {
    id
    parentCommentId
    createdBy {
      id
      avatar {
        sizes {
          key
          url
        }
      }
    }
  }
`;

type Props = {
  comment: CommentCascadeLine_comment$key;
};

const CommentCascadeLine = ({ comment }: Props): React.ReactElement => {
  const commentData = useFragment(commentFragment, comment);

  const { rootCommentId, toggleComment } = useCommentGlobal();
  const { colors } = useTheme();

  const avatarSize = React.useRef(
    !commentData?.parentCommentId || rootCommentId === commentData?.id
      ? 28
      : 18,
  );
  const avatarUrl = getImageBySize(commentData?.createdBy?.avatar, [
    ImageSize.Thumbnail,
    ImageSize.Avatar,
  ]);

  const handlePress = React.useCallback(() => {
    toggleComment(commentData?.id);
  }, [commentData?.id, toggleComment]);

  return (
    <Container>
      <AvatarContainer>
        <Avatar
          userId={commentData?.createdBy?.id}
          imageUri={avatarUrl}
          size={avatarSize.current}
          color={colors?.text2}
        />
      </AvatarContainer>
      <VerticalLineButton onPress={handlePress}>
        <VerticalLine />
      </VerticalLineButton>
    </Container>
  );
};

export default React.memo(CommentCascadeLine);
