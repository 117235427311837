import React from 'react';
import { StyleSheet } from 'react-native';
import DropdownPicker from 'react-native-dropdown-picker';

import useTheme from '#hooks/useTheme';

type Props = React.ComponentProps<typeof DropdownPicker>;

const CamouflageDropdown = ({
  style,
  containerStyle,
  itemStyle,
  dropDownStyle,
  labelStyle,
  activeLabelStyle,
  onOpen,
  onClose,
  ...props
}: Props): React.ReactElement => {
  const [isActive, setIsActive] = React.useState(false);
  const { colors } = useTheme();

  const s = StyleSheet.create({
    style: {
      backgroundColor: isActive ? colors?.surface : 'transparent',
      borderColor: isActive ? colors?.light2 : 'transparent',
    },
    container: { minHeight: 34 },
    activeLabel: { fontWeight: '600' as const, color: colors?.text },
    dropDown: {
      backgroundColor: colors?.surface,
      borderColor: colors?.light2,
    },
    item: { justifyContent: 'flex-start' },
    label: { color: colors?.text },
  });

  const handleOpen = () => {
    onOpen?.();
    setIsActive(true);
  };

  const handleClose = () => {
    onClose?.();
    setIsActive(false);
  };

  return (
    <DropdownPicker
      {...props}
      style={[s.style, style]}
      containerStyle={[s.container, containerStyle]}
      activeLabelStyle={[s.activeLabel, activeLabelStyle]}
      itemStyle={[s.item, itemStyle]}
      labelStyle={[s.label, labelStyle]}
      dropDownStyle={[s.dropDown, dropDownStyle, { minWidth: 95, end: 0 }]}
      arrowColor={colors?.text3}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
};

export default React.memo(CamouflageDropdown);
