/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostFeedChannel_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"Post_viewer">;
    readonly " $refType": "PostFeedChannel_viewer";
};
export type PostFeedChannel_viewer$data = PostFeedChannel_viewer;
export type PostFeedChannel_viewer$key = {
    readonly " $data"?: PostFeedChannel_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostFeedChannel_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostFeedChannel_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Post_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'fd9ecde83a5484fc164669c451558fbb';
export default node;
