/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
  height: 30px;
  padding-top: 8px;
`;

export const CommunityButtonContainer = styled.View`
  flex: 2 1 auto;
  justify-content: center;
  margin: auto 0;
`;

export const SubmitButtonContainer = styled.View`
  flex: 1 1 auto;
  justify-content: center;
`;

export const SubmitButton = styled.Pressable`
  flex-flow: row nowrap;
  margin-start: auto;
  padding-end: 4px;
  padding-start: 12px;
`;

export const SubmitButtonLabel = styled(Text)`
  margin: auto 0;
  padding-end: 8px;
  color: ${(props: any) => props.theme.colors.text};
`;

export default null;
