import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { CBMutationConfig } from '#interfaces';
import {
  UpdateByIdCommentInput,
  UpdateCommentMutation,
} from '~/UpdateCommentMutation.graphql';

const mutation = graphql`
  mutation UpdateCommentMutation(
    $commentId: MongoID!
    $record: UpdateByIdCommentInput!
  ) {
    commentUpdateById(_id: $commentId, record: $record) {
      record {
        id
        comment
        lastModifiedByUserId
        lastModifiedDate
      }
    }
  }
`;

const updateComment = (
  environment: Environment,
  comment: UpdateByIdCommentInput,
  nodeIds: { comment: string },
  config?: CBMutationConfig<UpdateCommentMutation>,
): Disposable => {
  const variables = {
    commentId: nodeIds.comment,
    record: comment,
  };

  return commitMutation<UpdateCommentMutation>(environment, {
    ...config,
    mutation,
    variables,
    optimisticResponse: {
      commentUpdateById: {
        record: {
          id: nodeIds.comment,
          comment: comment.comment ?? '',
          lastModifiedByUserId: comment.lastModifiedByUserId ?? '',
          lastModifiedDate: comment.lastModifiedDate ?? '',
        },
      },
    },
  });
};

export default updateComment;
