import { FontAwesome } from '@expo/vector-icons';
import fbt from 'fbt';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import MiddleDot from '@/MiddleDot';
import TimeAgo from '@/TimeAgo';
import useCommentGlobal from '#hooks/useCommentGlobal';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';
import { getVotePoints } from '#utils';
import { CollapsedCommentBlock_comment } from '~/CollapsedCommentBlock_comment.graphql';

import {
  Container,
  Detail,
  DetailsContainer,
  ExpandButton,
  UserName,
} from './CollapsedCommentBlock.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  comment: CollapsedCommentBlock_comment;
};

const CollapsedCommentBlock = ({ comment }: Props) => {
  useFbt();

  const { toggleComment } = useCommentGlobal();
  const { colors } = useTheme();

  const name = comment.createdBy?.displayName ?? comment.createdBy?.username;
  const score = getVotePoints(comment.vote);

  return (
    <Container>
      <ExpandButton onPress={() => toggleComment(comment.id)}>
        <FontAwesome name="plus-circle" size={12} color={colors?.blue2} />
      </ExpandButton>
      <DetailsContainer>
        <UserName>{name}</UserName>
        <Detail>
          {fbt(
            fbt.plural('point', score, {
              name: 'summary vote score',
              showCount: 'yes',
            }),
            'comment vote score',
          )}
        </Detail>
        {comment.createdDate ? (
          <>
            <Detail>
              <MiddleDot onlyText />
            </Detail>
            <Detail>
              <TimeAgo onlyText>{comment.createdDate}</TimeAgo>
            </Detail>
          </>
        ) : null}
      </DetailsContainer>
    </Container>
  );
};

export default createFragmentContainer(React.memo(CollapsedCommentBlock), {
  comment: graphql`
    fragment CollapsedCommentBlock_comment on Comment {
      id
      vote {
        upvotes
        downvotes
      }
      createdBy {
        displayName
        username
      }
      createdDate
    }
  `,
});
