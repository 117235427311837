/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type CommentButtonDeleteMutationVariables = {
    commentId: string;
    postId: string;
    parentCommentId?: string | null;
};
export type CommentButtonDeleteMutationResponse = {
    readonly commentRemoveById: {
        readonly record: {
            readonly id: string;
            readonly status: {
                readonly value: EnumDocumentStatusValue | null;
            } | null;
        } | null;
    } | null;
};
export type CommentButtonDeleteMutationRawResponse = {
    readonly commentRemoveById: ({
        readonly record: ({
            readonly id: string;
            readonly status: ({
                readonly value: EnumDocumentStatusValue | null;
            }) | null;
        }) | null;
    }) | null;
};
export type CommentButtonDeleteMutation = {
    readonly response: CommentButtonDeleteMutationResponse;
    readonly variables: CommentButtonDeleteMutationVariables;
    readonly rawResponse: CommentButtonDeleteMutationRawResponse;
};



/*
mutation CommentButtonDeleteMutation(
  $commentId: MongoID!
  $postId: MongoID!
  $parentCommentId: MongoID
) {
  commentRemoveById(_id: $commentId, postId: $postId, parentCommentId: $parentCommentId) {
    record {
      id
      status {
        value
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentCommentId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "commentId"
      },
      {
        "kind": "Variable",
        "name": "parentCommentId",
        "variableName": "parentCommentId"
      },
      {
        "kind": "Variable",
        "name": "postId",
        "variableName": "postId"
      }
    ],
    "concreteType": "RemoveByIdCommentPayload",
    "kind": "LinkedField",
    "name": "commentRemoveById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentStatus",
            "kind": "LinkedField",
            "name": "status",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentButtonDeleteMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommentButtonDeleteMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "3c5b09b16245850bb15893790e46d8e3",
    "id": null,
    "metadata": {},
    "name": "CommentButtonDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation CommentButtonDeleteMutation(\n  $commentId: MongoID!\n  $postId: MongoID!\n  $parentCommentId: MongoID\n) {\n  commentRemoveById(_id: $commentId, postId: $postId, parentCommentId: $parentCommentId) {\n    record {\n      id\n      status {\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '834584203cf82247dc2e6f20bd780e70';
export default node;
