/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type EnumVoteVote = "Downvote" | "Unvote" | "Upvote" | "%future added value";
export type PostCardSidebar_post = {
    readonly id: string;
    readonly vote: {
        readonly upvotes: number | null;
        readonly downvotes: number | null;
    } | null;
    readonly status: {
        readonly value: EnumDocumentStatusValue | null;
    } | null;
    readonly count: {
        readonly shared: number | null;
        readonly tagged: number | null;
    } | null;
    readonly meta: ReadonlyArray<{
        readonly key: string;
        readonly value: unknown | null;
    } | null> | null;
    readonly userVote: {
        readonly id: string;
        readonly vote: EnumVoteVote;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"TaggedTagsModal_post">;
    readonly " $refType": "PostCardSidebar_post";
};
export type PostCardSidebar_post$data = PostCardSidebar_post;
export type PostCardSidebar_post$key = {
    readonly " $data"?: PostCardSidebar_post$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardSidebar_post">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardSidebar_post",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PostVote",
      "kind": "LinkedField",
      "name": "vote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "upvotes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downvotes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PostCount",
      "kind": "LinkedField",
      "name": "count",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shared",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tagged",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyValue",
      "kind": "LinkedField",
      "name": "meta",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "viewerId"
        }
      ],
      "concreteType": "Vote",
      "kind": "LinkedField",
      "name": "userVote",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vote",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "viewerId",
          "variableName": "viewerId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "TaggedTagsModal_post"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();
(node as any).hash = 'da94617edb3eb5abc638151af210a45f';
export default node;
