/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentThread_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CommentBlock_viewer">;
    readonly " $refType": "CommentThread_viewer";
};
export type CommentThread_viewer$data = CommentThread_viewer;
export type CommentThread_viewer$key = {
    readonly " $data"?: CommentThread_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentThread_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentThread_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentBlock_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '4c8c5f1ee9023b3a51378f2e55397407';
export default node;
