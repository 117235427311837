/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardCommentButton_post = {
    readonly id: string;
    readonly count: {
        readonly totalComments: number | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CommentDrawer_post">;
    readonly " $refType": "PostCardCommentButton_post";
};
export type PostCardCommentButton_post$data = PostCardCommentButton_post;
export type PostCardCommentButton_post$key = {
    readonly " $data"?: PostCardCommentButton_post$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardCommentButton_post">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardCommentButton_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PostCount",
      "kind": "LinkedField",
      "name": "count",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalComments",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentDrawer_post"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
(node as any).hash = '34a88fbb487396c86cbc479ece093179';
export default node;
