/* eslint-disable @typescript-eslint/no-explicit-any */
import { dequal } from 'dequal';
import React from 'react';
import {
  ActivityIndicator,
  FlatList,
  FlatListProps,
  useWindowDimensions,
  View,
} from 'react-native';
import { Disposable, FetchPolicy, usePaginationFragment } from 'react-relay';
import { Except } from 'type-fest';
import { useDebouncedCallback } from 'use-debounce';
import { useDeepCompareCallback } from 'use-deep-compare';

import CenterContent from '@/CenterContent';
import FeedMarginals from '@/FeedMarginals';
import FeedSidebar from '@/FeedSidebar';
import LoadMoreConnection from '@/LoadMoreConnection';
import PaleBoldText from '@/PaleBoldText';
import useAppState from '#hooks/useAppState';
import useDrawer from '#hooks/useDrawer';
import useResponsive from '#hooks/useResponsive';
import useSidebar from '#hooks/useSidebar';
import { ConnectionEdge } from '#interfaces';
import { initFeedCount } from '#variables';

import { Container } from './Feed.style';

type Props<ItemT extends { edges: any } | null> = {
  initIsRefetch?: boolean;
  feedHeader?: React.ReactElement | null;
  feedSidebar?: React.ReactElement | null;
  feedSidebarWidth?: number;
  isDataReady?: boolean | null;
  itemsPerLoad: number;
  isAutoLoading?: boolean;
  LoadMoreComponent?: typeof LoadMoreConnection;
  query: Record<string, string | undefined>;
  refetchVariables?: Record<string, any>;
  fetchPolicy?: FetchPolicy;
  noMoreText: string;
  pagination: Except<ReturnType<typeof usePaginationFragment>, 'data'>;
} & FlatListProps<ConnectionEdge<NonNullable<ItemT>>>;

const Feed = <ItemT extends { edges: any } | null>({
  initIsRefetch,
  feedHeader,
  feedSidebar,
  feedSidebarWidth,
  isDataReady,
  itemsPerLoad,
  isAutoLoading,
  LoadMoreComponent,
  query,
  refetchVariables,
  fetchPolicy,
  noMoreText,
  data,
  pagination,
  ...props
}: Props<ItemT>): React.ReactElement => {
  const { hasNext, loadNext, isLoadingNext, refetch } = pagination;
  const { width } = useWindowDimensions();
  const { isOpen: isSidebarOpen } = useSidebar();
  const { isOpen: isDrawerOpen } = useDrawer();
  const { isPostSortable, setIsPostSortable } = useAppState();
  const [, { sidebarType }] = useResponsive();
  const windowWidthRef = React.useRef<number>();
  const refetchVariablesRef = React.useRef(refetchVariables);
  const queryRef = React.useRef<typeof query>();
  const feedRef = React.useRef<View>(null);
  const sidebarXRef = React.useRef<number | undefined>();
  const autoLoading = React.useRef(isAutoLoading ?? true);
  const [shrinkCont, setShrinkCont] = React.useState(true);
  const [sidebarX, setSidebarX] = React.useState<number | undefined>(
    sidebarXRef?.current,
  );
  const [isRefetch, setIsRefetch] = React.useState(initIsRefetch);

  const isDrawer = sidebarType === 'drawer';
  const isFetching = !isDataReady;
  const isPostSortableLocal = (data?.length ?? 0) >= initFeedCount || hasNext;

  const s = React.useMemo(
    () => ({
      style: { width: '100%' },
      contentContainerStyle: {
        justifyContent: 'center' as const,
        marginEnd: !isDrawer ? feedSidebarWidth : undefined,
      },
    }),
    [feedSidebarWidth, isDrawer],
  );

  const updateSidebarPosition = useDebouncedCallback(() => {
    if (!isDrawer) {
      setShrinkCont(true);
      feedRef.current?.measure((_x, _y, _w, _h, pageX) => {
        setShrinkCont(false);
        setSidebarX(pageX + _w - (feedSidebarWidth ?? 0));
      });
    }
  }, 50);

  const disableShrinkCont = useDebouncedCallback(() => {
    setShrinkCont(false);
  }, 200);

  const cancelLoading = useDebouncedCallback((disposable: Disposable) => {
    autoLoading.current = false;
    disposable.dispose();
  }, 5000);

  const noFetching = useDebouncedCallback(() => setIsRefetch(false), 500);

  /**
   * Load more items by using `loadNext` function provided by `pagination`
   */
  const loadMore = React.useCallback(():
    | ReturnType<typeof loadNext>
    | null
    | undefined => {
    if (!hasNext || isLoadingNext) return;
    return loadNext(itemsPerLoad, {
      onComplete: (err) => {
        if (err) throw err;
        cancelLoading.cancel();
      },
    });
  }, [cancelLoading, hasNext, isLoadingNext, itemsPerLoad, loadNext]);

  /**
   * Call when feed reach its end, only call if `isAutoLoading` is true
   */
  const handleEndReach = React.useCallback(() => {
    if (!isLoadingNext && hasNext && autoLoading.current) {
      const disposable = loadMore();
      if (disposable) cancelLoading(disposable);
    }
  }, [cancelLoading, hasNext, isLoadingNext, loadMore]);

  const handleLoadMorePress = React.useCallback(() => {
    autoLoading.current = true;
    return loadMore();
  }, [loadMore]);

  const refetchItems = useDeepCompareCallback(() => {
    refetchVariablesRef.current = refetchVariables;
    queryRef.current = query;
    sidebarXRef.current = sidebarX;
    setIsRefetch(true);

    return refetch(refetchVariables ?? {}, {
      fetchPolicy: fetchPolicy ?? 'store-and-network',
      // Sometime, `onComplete` not fire especialy when user re-enter the initial page
      onComplete: (err) => {
        if (err) throw err;
        setIsRefetch(false);
      },
    });
  }, [itemsPerLoad, query, refetchVariables, sidebarX]);

  const keyExtractor = React.useCallback(
    (edge, i): string => edge?.node?.id ?? i.toString(),
    [],
  );

  disableShrinkCont();

  React.useEffect(() => {
    if (isPostSortable !== isPostSortableLocal)
      setIsPostSortable(isPostSortableLocal);
  }, [isPostSortable, isPostSortableLocal, setIsPostSortable]);

  React.useEffect(() => {
    if (windowWidthRef.current !== width || !isRefetch) {
      windowWidthRef.current = width;
      updateSidebarPosition();
    }
  }, [isRefetch, updateSidebarPosition, width]);

  // Currently, we need to call `refetchItems` (or `refetch`) two times
  // to update the store with the newly fetched data.
  React.useEffect(() => {
    let disposable: Disposable | undefined;
    let disposable2: Disposable | undefined;

    // refetch when query or its variables change or when it is first load
    if (
      (!data?.length &&
        queryRef.current === undefined &&
        !dequal(query, queryRef.current)) ||
      (refetchVariables &&
        refetchVariablesRef.current &&
        !dequal(refetchVariables, refetchVariablesRef.current))
    ) {
      refetchVariablesRef.current = refetchVariables;
      disposable = refetchItems();
      disposable2 = refetchItems();
    }

    return () => {
      disposable?.dispose();
      disposable2?.dispose();
    };
  }, [
    data?.length,
    fetchPolicy,
    isLoadingNext,
    query,
    refetch,
    refetchItems,
    refetchVariables,
  ]);

  // This is a guarantee that the feed won't stuck on the `refetching` state.
  // It's bug that `onComplete` not call on some cases and `noFetching` also
  // not run properly when put it inside `useEffect` hook.
  noFetching();

  const defaultLoadMoreComponent = React.useMemo(() => {
    const Component = LoadMoreComponent ?? LoadMoreConnection;
    return (
      <Component
        noMoreText={noMoreText}
        onPress={handleLoadMorePress}
        hasMore={hasNext}
        isLoading={isLoadingNext}
      />
    );
  }, [
    LoadMoreComponent,
    noMoreText,
    handleLoadMorePress,
    hasNext,
    isLoadingNext,
  ]);

  if ((!(isFetching || isRefetch) && !data?.length) || !data)
    return (
      <CenterContent>
        <PaleBoldText>{noMoreText}</PaleBoldText>
      </CenterContent>
    );

  return (
    <Container
      ref={feedRef}
      width={shrinkCont && !isDrawer ? 'auto' : undefined}
    >
      {feedHeader && <FeedMarginals>{feedHeader}</FeedMarginals>}
      {!isDrawer && !isSidebarOpen && !isDrawerOpen ? (
        <FeedSidebar
          xPos={sidebarX}
          width={feedSidebarWidth}
          isVisible={
            !(isFetching || isRefetch) && isPostSortableLocal && !!sidebarX
          }
        >
          {feedSidebar}
        </FeedSidebar>
      ) : null}
      {isFetching || isRefetch ? (
        <CenterContent>
          <ActivityIndicator size={32} />
        </CenterContent>
      ) : (
        <FlatList<ConnectionEdge<NonNullable<ItemT>>>
          {...props}
          data={data}
          style={s.style}
          contentContainerStyle={s.contentContainerStyle}
          keyExtractor={keyExtractor}
          scrollEnabled={!shrinkCont || isDrawer}
          onEndReached={handleEndReach}
          onEndReachedThreshold={2}
          ListFooterComponent={defaultLoadMoreComponent}
        />
      )}
    </Container>
  );
};

export default Feed;
