/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Message = styled(Text)`
  color: ${(props: any) => props.theme.colors.text3};
  font-size: 14px;
  font-style: italic;
`;

export default null;
