import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import cleanDeep from 'clean-deep';
import { useRouting } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';

import Dropdown from '@/Dropdown';
import { FeedScope, FeedSort, QueryParam, TaggedFeedSort } from '#enum';
import useFbt from '#hooks/useFbt';
import useSession from '#hooks/useSession';
import useTheme from '#hooks/useTheme';
import { feedSort, feedSortTagged } from '#relay/helpers/feed';

// eslint-disable-next-line no-unused-expressions
fbt;

const iconSize = 24;

const getSort = (
  currentSort: FeedSort | TaggedFeedSort,
): string | undefined => {
  switch (currentSort) {
    case FeedSort.FeaturedScore:
      return undefined;
    case FeedSort.HotScore:
      return 'hot';
    case FeedSort.Newest:
      return 'newest';
    case TaggedFeedSort.Newest:
      return 'newest';
    default:
      return undefined;
  }
};

const FeedDropdownSorter = (): React.ReactElement => {
  useFbt();

  const [activeSorting, setActiveSorting] = React.useState<
    FeedSort | TaggedFeedSort
  >();
  const { navigate, getParam } = useRouting();
  const { colors } = useTheme();
  const [session] = useSession();

  const feedScope = getParam(QueryParam.FeedScope);
  const isTaggedScope = feedScope === FeedScope.Tagged && session?.user;

  const labelStyle = React.useMemo(() => ({ marginVertical: 'auto' }), []);

  const handleChangeItem = React.useCallback(
    (item: { label: string; value: FeedSort }) => {
      const sort = item.value;
      navigate({
        routeName: 'Home',
        web: { path: '/', shallow: true },
        params: cleanDeep({
          [QueryParam.FeedQuery]: getParam(QueryParam.FeedQuery),
          [QueryParam.FeedScope]: feedScope,
          [QueryParam.FeedSort]: getSort(sort),
        }),
      });
    },
    [feedScope, getParam, navigate],
  );

  const itemSorter = React.useMemo(
    () => [
      {
        label: `${fbt('Best posts', 'sorter label')}`,
        value: FeedSort.FeaturedScore,
        icon: () => (
          <MaterialIcons
            name="rss-feed"
            color={colors?.orange}
            size={iconSize}
          />
        ),
      },
      {
        label: `${fbt('Hot posts', 'sorter label')}`,
        value: FeedSort.HotScore,
        icon: () => (
          <MaterialIcons name="whatshot" color={colors?.red} size={iconSize} />
        ),
      },
      {
        label: `${fbt('Newest posts', 'sorter label')}`,
        value: FeedSort.Newest,
        icon: () => (
          <MaterialCommunityIcons
            name="clock-fast"
            color={colors?.blue}
            size={iconSize}
          />
        ),
      },
    ],
    [colors?.blue, colors?.orange, colors?.red],
  );

  const taggedItemSorter = React.useMemo(
    () => [
      {
        label: `${fbt('Tagged posts', 'sorter label')}`,
        value: TaggedFeedSort.TaggedDateDesc,
        icon: () => (
          <MaterialCommunityIcons
            name="numeric-1-box-multiple-outline"
            color={colors?.orange}
            size={iconSize - 2}
          />
        ),
      },
      {
        label: `${fbt('Newest posts', 'sorter label')}`,
        value: TaggedFeedSort.Newest,
        icon: () => (
          <MaterialCommunityIcons
            name="clock-fast"
            color={colors?.blue}
            size={iconSize}
          />
        ),
      },
    ],
    [colors?.blue, colors?.orange],
  );

  React.useEffect(() => {
    const sort: string | undefined = getParam(QueryParam.FeedSort);
    const current = isTaggedScope ? feedSortTagged(sort) : feedSort(sort);
    setActiveSorting(current);
  }, [getParam, isTaggedScope]);

  return (
    <Dropdown
      items={isTaggedScope ? taggedItemSorter : itemSorter}
      onChangeItem={handleChangeItem}
      defaultValue={activeSorting}
      labelStyle={labelStyle}
    />
  );
};

export default React.memo(FeedDropdownSorter, () => true);
