/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TaggedTagsModal_viewer = {
    readonly id: string;
    readonly " $refType": "TaggedTagsModal_viewer";
};
export type TaggedTagsModal_viewer$data = TaggedTagsModal_viewer;
export type TaggedTagsModal_viewer$key = {
    readonly " $data"?: TaggedTagsModal_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"TaggedTagsModal_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaggedTagsModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '8ad65723656215003c696607ab9784bf';
export default node;
