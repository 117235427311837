/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentDrawer_post = {
    readonly id: string;
    readonly count: {
        readonly totalComments: number | null;
        readonly activeRootComments: number | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CommentFeedRoot_post">;
    readonly " $refType": "CommentDrawer_post";
};
export type CommentDrawer_post$data = CommentDrawer_post;
export type CommentDrawer_post$key = {
    readonly " $data"?: CommentDrawer_post$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentDrawer_post">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentDrawer_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PostCount",
      "kind": "LinkedField",
      "name": "count",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalComments",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activeRootComments",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentFeedRoot_post"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
(node as any).hash = '0fa5f933854e43072b221d773e98ef9a';
export default node;
