/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CommunityRenameFormMutationVariables = {
    viewerId: string;
    communityId: string;
    name: string;
};
export type CommunityRenameFormMutationResponse = {
    readonly communityUpdate: {
        readonly record: {
            readonly id: string;
            readonly name: string;
        } | null;
    } | null;
};
export type CommunityRenameFormMutationRawResponse = {
    readonly communityUpdate: ({
        readonly record: ({
            readonly id: string;
            readonly name: string;
        }) | null;
    }) | null;
};
export type CommunityRenameFormMutation = {
    readonly response: CommunityRenameFormMutationResponse;
    readonly variables: CommunityRenameFormMutationVariables;
    readonly rawResponse: CommunityRenameFormMutationRawResponse;
};



/*
mutation CommunityRenameFormMutation(
  $viewerId: MongoID!
  $communityId: MongoID!
  $name: String!
) {
  communityUpdate(viewerId: $viewerId, _id: $communityId, record: {name: $name}) {
    record {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "communityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewerId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "communityId"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "record"
      },
      {
        "kind": "Variable",
        "name": "viewerId",
        "variableName": "viewerId"
      }
    ],
    "concreteType": "UpdateByIdCommunityPayload",
    "kind": "LinkedField",
    "name": "communityUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Community",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunityRenameFormMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommunityRenameFormMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "38fc2a3e694eb945d40fe31d7952fdb9",
    "id": null,
    "metadata": {},
    "name": "CommunityRenameFormMutation",
    "operationKind": "mutation",
    "text": "mutation CommunityRenameFormMutation(\n  $viewerId: MongoID!\n  $communityId: MongoID!\n  $name: String!\n) {\n  communityUpdate(viewerId: $viewerId, _id: $communityId, record: {name: $name}) {\n    record {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '54a8c0cb08bb1a9a4e01cffc8f3c1447';
export default node;
