import styled from 'styled-components/native';

export const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 48px;
  margin-end: 2px;
`;

export default null;
