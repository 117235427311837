import React from 'react';
import { Image } from 'react-native';

import useResponsive from '#hooks/useResponsive';
import { FlexboxProps, LayoutProps, SpaceProps } from '#styled-system';

import {
  Container,
  ImageContainer,
  Message,
  MessageContainer,
} from './Features.style';

type Props = {
  children: React.ReactNode;
  imageSide?: 'l' | 'r';
  imageUri?: string;
  imageSize?: { width: number; height: number };
  width?: number;
  vertical?: boolean;
} & FlexboxProps &
  LayoutProps &
  SpaceProps;

const Features = ({
  children,
  imageSide = 'l',
  imageUri,
  imageSize,
  width = 650,
  vertical,
  ...props
}: Props) => {
  const [, { lteSm }] = useResponsive();

  const addNumber = vertical ? 24 : 0;
  const size = lteSm ? 96 + addNumber : 128 + addNumber;
  const imgSize = lteSm
    ? { width: size, height: size }
    : { width: size, height: size };

  return (
    <Container
      flexDirection={vertical ? 'column' : undefined}
      maxWidth={width}
      {...props}
    >
      {imageSide === 'l' && imageUri && (
        <ImageContainer
          pe={!vertical ? '20px' : undefined}
          pb={vertical ? '20px' : undefined}
        >
          <Image source={{ uri: imageUri }} style={imageSize ?? imgSize} />
        </ImageContainer>
      )}
      <MessageContainer alignItems={vertical ? 'center' : undefined}>
        {typeof children === 'string' ? (
          <Message>{children}</Message>
        ) : (
          children
        )}
      </MessageContainer>
      {imageSide === 'r' && imageUri && (
        <ImageContainer ps="20px">
          <Image source={{ uri: imageUri }} style={imageSize ?? imgSize} />
        </ImageContainer>
      )}
    </Container>
  );
};

export default React.memo(
  Features,
  (prev, next) =>
    prev.vertical === next.vertical &&
    prev.imageSize?.height === next.imageSize?.height &&
    prev.imageSize?.width === next.imageSize?.width,
);
