import fbt from 'fbt';
import React from 'react';
import { StyleSheet } from 'react-native';
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from 'react-relay';

import CommentFeed from '@/CommentFeed';
import useCommentGlobal from '#hooks/useCommentGlobal';
import useFbt from '#hooks/useFbt';
import useResponsive from '#hooks/useResponsive';
import { CommentFeedRoot_post$key } from '~/CommentFeedRoot_post.graphql';
import { CommentFeedRoot_viewer$key } from '~/CommentFeedRoot_viewer.graphql';
import { CommentFeedRootQuery } from '~/CommentFeedRootQuery.graphql';

export const query = graphql`
  query CommentFeedRootQuery(
    $viewerId: MongoID
    $rootCount: Int
    $count: Int
    $cursor: String
    $filter: FilterFindManyCommentInput
    $sort: SortGetCommentsCommentEnum
    $featuredSubCommentType: String
    $getFstSubComments: Boolean!
    $getSndSubComments: Boolean!
    $getTrdSubComments: Boolean!
    $getFthSubComments: Boolean!
  ) {
    rootComments: comments(first: $rootCount, after: $cursor, filter: $filter) {
      edges {
        node {
          parentCommentId
        }
      }
    }
    ...CommentFeed_comments
      @arguments(
        viewerId: $viewerId
        count: $count
        cursor: $cursor
        filter: $filter
        sort: $sort
        featuredSubCommentType: $featuredSubCommentType
        getFstSubComments: $getFstSubComments
        getSndSubComments: $getSndSubComments
        getTrdSubComments: $getTrdSubComments
        getFthSubComments: $getFthSubComments
      )
  }
`;

const viewerFragment = graphql`
  fragment CommentFeedRoot_viewer on User {
    ...CommentFeed_viewer
  }
`;

const postFragment = graphql`
  fragment CommentFeedRoot_post on Post {
    count {
      activeRootComments
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  queryRef: PreloadedQuery<CommentFeedRootQuery>;
  viewer: CommentFeedRoot_viewer$key | null;
  post: CommentFeedRoot_post$key;
};

/**
 * If post comment count is 0, do nothing and render `No Comment`
 * if post comment count > 0, show `ActivityIndicator` and fetch comments
 */
const CommentFeedRoot = ({
  queryRef,
  viewer,
  post,
}: Props): React.ReactElement => {
  const data = usePreloadedQuery(query, queryRef);
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  useFbt();

  const [, { lteSm }] = useResponsive();
  const {
    rootCommentId,
    setIsLoading,
    setIsGoingUp,
    setCanGoUp,
    setParentCommentId,
  } = useCommentGlobal();
  const s = React.useMemo(
    () =>
      StyleSheet.create({
        container: {
          marginHorizontal: lteSm ? 8 : 12,
          paddingBottom: 20,
        },
      }),
    [lteSm],
  );

  const parentCommentId = data?.rootComments?.edges?.[0]?.node?.parentCommentId;

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setCanGoUp(!!(rootCommentId ?? parentCommentId));
      setIsGoingUp(false);
      setIsLoading(false);
      setParentCommentId(parentCommentId);
    }

    return () => {
      isMounted = false;
    };
  }, [
    parentCommentId,
    rootCommentId,
    setCanGoUp,
    setIsGoingUp,
    setIsLoading,
    setParentCommentId,
  ]);

  return (
    <CommentFeed
      viewer={viewerData ?? null}
      comments={data ?? null}
      loadMoreType="comment"
      scrollable
      recordConnection
      totalSubComments={postData.count?.activeRootComments}
      subCommentDepth={4}
      contentContainerStyle={s.container}
    />
  );
};

export default CommentFeedRoot;
