import styled from 'styled-components/native';

import {
  display,
  DisplayProps,
  position,
  PositionProps,
  width,
  WidthProps,
} from '#styled-system';

export const Container = styled.View<DisplayProps & PositionProps & WidthProps>`
  flex-direction: column;
  ${width};
  ${position};
  ${display};
`;

export default null;
