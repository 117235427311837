import { Link } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import Copyright from '@/Copyright';
import MiddleDot from '@/MiddleDot';
import useFbt from '#hooks/useFbt';
import useSession from '#hooks/useSession';
import getAvailablePreferedLocales from '#i18n/utils/getAvailablePreferedLocales';
import { appLanguages } from '#lib/variables';
import { MarginProps } from '#styled-system';
import { FooterNav_viewer$key } from '~/FooterNav_viewer.graphql';

import { Container, Nav, NavContainer } from './FooterNav.style';

const viewerFragment = graphql`
  fragment FooterNav_viewer on User {
    details {
      appLanguage
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: FooterNav_viewer$key | null;
} & MarginProps;

const FooterNav = ({ viewer, ...props }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const [session] = useSession();
  const appLang = React.useMemo(() => {
    const currentLang = getAvailablePreferedLocales(
      [viewerData?.details?.appLanguage, session?.user?.locale],
      appLanguages.map((locale) => locale.value),
    )?.[0];
    return appLanguages.find((lang) => lang.value === currentLang)?.label;
  }, [session?.user?.locale, viewerData?.details?.appLanguage]);

  return (
    <Container {...props}>
      <NavContainer>
        <Link routeName="Home" web={{ path: '/terms' }}>
          <Nav>
            <fbt desc="nav link">Terms of Service</fbt>
          </Nav>
        </Link>
        <MiddleDot />
        <Link routeName="Home" web={{ path: '/privacy' }}>
          <Nav>
            <fbt desc="nav link">Privacy Policy</fbt>
          </Nav>
        </Link>
        <MiddleDot />
        <Link routeName="Home" web={{ path: '/cookies' }}>
          <Nav>
            <fbt desc="nav link">Cookies</fbt>
          </Nav>
        </Link>
        <MiddleDot />
        <Nav>
          <fbt desc="nav link">Language:</fbt> {appLang}
        </Nav>
        <MiddleDot />
        <Nav>
          <Copyright />
        </Nav>
      </NavContainer>
    </Container>
  );
};

export default React.memo(FooterNav, () => true);
