/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollapsedCommentBlock_comment = {
    readonly id: string;
    readonly vote: {
        readonly upvotes: number | null;
        readonly downvotes: number | null;
    } | null;
    readonly createdBy: {
        readonly displayName: string | null;
        readonly username: string;
    } | null;
    readonly createdDate: string | null;
    readonly " $refType": "CollapsedCommentBlock_comment";
};
export type CollapsedCommentBlock_comment$data = CollapsedCommentBlock_comment;
export type CollapsedCommentBlock_comment$key = {
    readonly " $data"?: CollapsedCommentBlock_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CollapsedCommentBlock_comment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollapsedCommentBlock_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentVote",
      "kind": "LinkedField",
      "name": "vote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "upvotes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downvotes",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
(node as any).hash = '192068ab9c2a529997d7e7f0e6510730';
export default node;
