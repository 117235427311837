import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CenterContent from '@/CenterContent';
import { Status } from '#enum';
import useFbt from '#hooks/useFbt';
import { PostStatusMessage_post$key } from '~/PostStatusMessage_post.graphql';

import { Message } from './PostStatusMessage.style';

const postFragment = graphql`
  fragment PostStatusMessage_post on Post {
    status {
      value
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  post: PostStatusMessage_post$key;
};

const PostStatusMessage = ({ post }: Props) => {
  const postData = useFragment(postFragment, post);

  useFbt();

  return postData.status?.value ? (
    <CenterContent minHeight="140px">
      {postData.status.value === Status.Suspended && (
        <Message>
          <fbt desc="placeholder message">
            This post was currently suspended
          </fbt>
        </Message>
      )}
      {postData.status.value === Status.Deleted && (
        <Message>
          <fbt desc="placeholder message">This post was deleted</fbt>
        </Message>
      )}
    </CenterContent>
  ) : null;
};

export default React.memo(PostStatusMessage);
