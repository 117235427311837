/* eslint-disable @typescript-eslint/no-explicit-any */
import Color from 'color';
import { EditorState } from 'draft-js';
import fbt from 'fbt';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';
import { v1 as uuidv1 } from 'uuid';

import Textarea from '@/Textarea';
import useCommentFeed from '#hooks/useCommentFeed';
import useCommentGlobal from '#hooks/useCommentGlobal';
import useCommentLocal from '#hooks/useCommentLocal';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';
import {
  convertEditorStateToJSONWithAssets,
  createEditorState,
  getPlainText,
} from '#lib/draft-js/converter';
import ds from '#lib/draft-js/dataSets';
import createComment from '#mutations/CreateComment';
import updateComment from '#mutations/UpdateComment';
import { CommentInput_viewer$key } from '~/CommentInput_viewer.graphql';

const viewerFragment = graphql`
  fragment CommentInput_viewer on User {
    id
    username
    displayName
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type FormValues = {
  comment: EditorState;
};

type Props = {
  viewer: CommentInput_viewer$key | null;
  editorState?: EditorState;
  commentId?: string | null;
  parentCommentId?: string | null;
  autoFocus?: boolean;
  isFullSize?: boolean;
  submitText?: string;
  submitButtonColor?: string;
  cancelText?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
};

// TODO: disabled input if viewerId or postId is null
const CommentInput = ({
  viewer,
  editorState,
  commentId,
  parentCommentId,
  autoFocus,
  isFullSize,
  submitText,
  submitButtonColor,
  cancelText,
  onSubmit,
  onCancel,
}: Props): React.ReactElement => {
  const environment = useRelayEnvironment();
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const { colors, isDarkTheme } = useTheme();
  const { activePostId } = useCommentGlobal();
  const { connections } = useCommentLocal();
  const [contentState, setContentState] = React.useState(
    editorState ?? createEditorState(),
  );
  const { addedComment, removedComment } = useCommentFeed();
  const [isFocus, setIsFocus] = React.useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: { comment: contentState },
  });

  const plainContent = getPlainText(contentState);
  const isSubmittable =
    !!plainContent && getPlainText(editorState) !== plainContent;
  const isActive = !!plainContent || isFocus || isFullSize;
  const submitTextColor = submitButtonColor ?? colors?.blue2;
  const submitBgColor = isDarkTheme
    ? `${Color(submitTextColor).darken(0.7)}`
    : `${Color(submitTextColor).lighten(0.58)}`;

  const s = React.useMemo(
    () =>
      StyleSheet.create({
        textarea: { minHeight: isActive ? 100 : undefined },
      }),
    [isActive],
  );

  const handleFocus = React.useCallback(() => setIsFocus(true), []);

  const handleBlur = React.useCallback(
    (callback?: () => void) => () => {
      callback?.();
      setIsFocus(false);
    },
    [],
  );

  const onFormSubmit = React.useCallback(
    async (values: FormValues) => {
      if (isSubmittable && values.comment && activePostId && viewerData?.id) {
        const [commentContent] = await convertEditorStateToJSONWithAssets(
          values.comment,
        );
        const emptyEditorState = createEditorState();

        onSubmit?.();

        if (commentId) {
          updateComment(
            environment,
            {
              version: uuidv1(),
              comment: commentContent,
              lastModifiedByUserId: viewerData.id,
              lastModifiedDate: new Date().toUTCString(),
            },
            {
              comment: commentId,
            },
          );
        } else {
          addedComment();
          createComment(
            environment,
            {
              parentCommentId,
              postId: activePostId,
              version: uuidv1(),
              comment: commentContent,
              vote: { upvotes: 1, downvotes: 0 },
              createdByUserId: viewerData?.id,
              createdDate: new Date().toUTCString(),
            },
            { connections: connections ?? [] },
            viewerData,
            {
              onError: () => {
                removedComment();
              },
            },
          );
          reset({ comment: emptyEditorState });
          setContentState(emptyEditorState);
        }
      }
    },
    [
      isSubmittable,
      activePostId,
      viewerData,
      onSubmit,
      commentId,
      environment,
      addedComment,
      parentCommentId,
      connections,
      reset,
      removedComment,
    ],
  );

  return (
    <Controller
      control={control}
      name="comment"
      render={({ value, onChange, onBlur }) => (
        <Textarea
          dataSet={ds.Editor14}
          readOnly={!viewerData?.id}
          showToolbar={isActive}
          autoFocus={autoFocus}
          editorState={value}
          placeholder={fbt('Comment...', 'textinput placeholder')}
          onChange={(v) => {
            onChange(v);
            setContentState(v);
          }}
          onFocus={handleFocus}
          onBlur={handleBlur(onBlur)}
          style={s.textarea}
          submitButton={{
            text: submitText,
            onPress: isSubmittable ? handleSubmit(onFormSubmit) : undefined,
            disabled: !isSubmittable,
            color: isSubmittable ? submitTextColor : colors?.disabled,
            backgroundColor: isSubmittable ? submitBgColor : undefined,
          }}
          cancelButton={
            onCancel && {
              text: cancelText,
              onPress: onCancel,
            }
          }
        />
      )}
    />
  );
};

export default React.memo(
  CommentInput,
  (prev, next) =>
    prev.isFullSize === next.isFullSize &&
    prev.submitText === next.submitText &&
    prev.submitButtonColor === next.submitButtonColor &&
    prev.cancelText === next.cancelText,
);
