/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pressable } from 'react-native';
import { Button } from 'react-native-paper';
import styled from 'styled-components/native';

import Text from '@/Text';

export const CommunityButton = styled.Pressable`
  flex-flow: row nowrap;
`;

export const CommunityButtonLabel = styled(Text)`
  margin: auto 0;
  padding-start: 6px;
  color: ${(props: any) => props.theme.colors.text3};
`;

export const Message = styled(Text)`
  color: ${(props: any) => props.theme.colors.text};
`;

export const ActionButton = styled(Button)`
  padding: 3px 6px;
`;

export default null;
