/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import {
  margin,
  MarginProps,
  typography,
  TypographyProps,
} from '#styled-system';
import { fonts } from '#styles/variables';

export const TitleContainer = styled.View<MarginProps>`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column wrap;
  justify-content: flex-end;
  margin: 36px 20px 0;
  ${margin};
`;

export const Title = styled(Text)<TypographyProps>`
  display: flex;
  flex: 1 1 auto;
  color: ${(props: any) => props.theme.colors.text};
  font-size: 56px;
  font-family: '${fonts.OfficinaSans}';
  ${typography};
`;

export const FeaturesContainer = styled.View`
  display: flex;
  flex: 8 1 auto;
  justify-content: space-around;
`;

export const ButtonContainer = styled.View`
  margin: 30px 20px 0px;
`;

export const HintContainer = styled.View<MarginProps>`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  margin: 20px 20px 20px;
  ${margin};
`;

export const Hint = styled(Text)<MarginProps>`
  display: flex;
  margin-vertical: 2px;
  color: ${(props: any) => props.theme.colors.text2};
  font-size: 14px;
  ${margin};
`;

export default null;
