/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const CommentInputContainer = styled.View`
  display: flex;
  flex: 0 0 auto;
  margin: 8px 8px 0;
`;

export const CommentFeedHeader = styled.View`
  z-index: 1;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  margin: 0 8px;
`;

export const BackButtonContainer = styled.View`
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
`;

export const BackButton = styled.Pressable`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BackButtonText = styled(Text)`
  margin-horizontal: 6px;
  color: ${(props: any) => props.theme.colors.text2};
  font-weight: bold;
  font-size: 12px;
`;

export const CommentSorterContainer = styled.View``;

export default null;
