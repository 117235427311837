import React from 'react';
import { graphql, useFragment } from 'react-relay';

import PostCardContent from '@/PostCardContent';
import PostCardSidebar from '@/PostCardSidebar';
import PostCardTemplate from '@/PostCardTemplate';
import useResponsive from '#hooks/useResponsive';
import { PostCard_post$key } from '~/PostCard_post.graphql';
import { PostCard_viewer$key } from '~/PostCard_viewer.graphql';

const viewerFragment = graphql`
  fragment PostCard_viewer on User {
    ...PostCardTemplate_viewer
    ...PostCardSidebar_viewer
  }
`;

const postFragment = graphql`
  fragment PostCard_post on Post
  @argumentDefinitions(viewerId: { type: "MongoID" }) {
    ...PostCardTemplate_post @arguments(viewerId: $viewerId)
    ...PostCardContent_post
    ...PostCardSidebar_post @arguments(viewerId: $viewerId)
  }
`;

type Props = {
  post: PostCard_post$key;
  viewer: PostCard_viewer$key | null;
  content?: string;
  hideSidebar?: boolean;
  onReadMorePress?: () => void;
} & Pick<
  React.ComponentProps<typeof PostCardTemplate>,
  'connection' | 'hideFooter' | 'hideHeader' | 'borderRadius' | 'containerStyle'
>;

const PostCard = ({
  viewer,
  post,
  content,
  hideSidebar,
  onReadMorePress,
  ...postCardProps
}: Props) => {
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  const [r, { lteSm }] = useResponsive();

  return post ? (
    <PostCardTemplate
      viewer={viewerData}
      post={postData}
      sidebar={
        !lteSm &&
        !hideSidebar && <PostCardSidebar viewer={viewerData} post={postData} />
      }
      {...postCardProps}
    >
      <PostCardContent
        post={postData}
        minHeight={r(['80px', '80px', undefined])}
        content={content}
        onReadMorePress={onReadMorePress}
      />
    </PostCardTemplate>
  ) : null;
};

export default React.memo(PostCard);
