import styled from 'styled-components/native';

import { margin, MarginProps, zIndex, ZIndexProps } from '#styled-system';

export const Container = styled.View<ZIndexProps>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  margin-top: 18px;
  ${zIndex};
`;

export const CascadeLineContainer = styled.View<MarginProps>`
  display: inline-flex;
  flex: 0 0 auto;
  margin-end: 12px;
  ${margin};
`;

export const MainContainer = styled.View`
  z-index: 4;
  display: inline-flex;
  flex: 1 1 auto;
`;

export const CommentContainer = styled.View`
  z-index: 3;
  margin-top: 4px;
`;

export const SubCommentsContainer = styled.View`
  z-index: 2;
`;

export const CommentContentContainer = styled.View``;

export default null;
