/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 21px;
`;

export const ExpandButton = styled.Pressable``;

export const DetailsContainer = styled.View`
  display: flex;
  flex-flow: row;
`;

export const UserName = styled(Text)`
  align-self: flex-end;
  margin-start: 8px;
  color: ${(props: any) => props.theme.colors.text4};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

export const Detail = styled(Text)`
  align-self: flex-end;
  margin-start: 4px;
  color: ${(props: any) => props.theme.colors.blackOpac20};
  font-size: 12px;
  line-height: 16px;
`;

export default null;
