import styled from 'styled-components/native';

import { margin, MarginProps, zIndex, ZIndexProps } from '#styled-system';

export const Container = styled.View<MarginProps & ZIndexProps>`
  ${zIndex};
  ${margin};
`;

export default null;
