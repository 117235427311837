/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import { color, ColorProps } from '#styled-system';

export const Container = styled.View`
  flex: 1 1 auto;
  max-height: 360px;
`;

export const NavContainer = styled.View<ColorProps>`
  width: 100%;
  margin-vertical: 2px;
  padding: 8px 28px;
  border-radius: 20px;
  ${color};
`;

export const ButtonLabel = styled(Text)<ColorProps>`
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.5px;
`;

export default null;
