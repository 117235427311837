import { AntDesign } from '@expo/vector-icons';
import React from 'react';
import { IconButton } from 'react-native-paper';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';
import { useDebouncedCallback } from 'use-debounce';

import getVoteInc from '#database/utils/getVoteInc';
import { ObjectType, VoteType } from '#enum';
import useModals from '#hooks/useModals';
import useSession from '#hooks/useSession';
import useTheme from '#hooks/useTheme';
import vote from '#mutations/Vote';
import { Vote_viewer$key } from '~/Vote_viewer.graphql';

import { Container, IconContainer, Score, ScoreContainer } from './Vote.style';

const viewerFragment = graphql`
  fragment Vote_viewer on User {
    id
  }
`;

type Props = {
  objectType: ObjectType;
  objectId: string;
  voteScore?: number | null;
  viewer: Vote_viewer$key | null;
  userVote?: { id: string; vote: VoteType } | null;
  voteCallback?: (props: {
    prevVote: VoteType;
    newVote: VoteType;
  }) => Promise<void>;
  size?: 's' | 'm' | 'l';
  isHorizontal?: boolean;
  disabled?: boolean;
};

function getValueBySize<S = undefined, M = undefined, L = undefined>(
  size: Props['size'] = 'l',
  values: { s?: S; m?: M; l?: L },
): S | M | L | undefined {
  return values[size];
}

const Vote = ({
  objectType,
  objectId,
  voteScore,
  viewer,
  userVote,
  voteCallback,
  size,
  isHorizontal,
  disabled,
}: Props): React.ReactElement => {
  const environment = useRelayEnvironment();
  const viewerData = useFragment(viewerFragment, viewer);

  const voteProp = (userVote?.vote ?? VoteType.Unvote) as VoteType;
  const [voteState, setVoteState] = React.useState(voteProp);
  const [scoreState, setScoreState] = React.useState(voteScore ?? 0);
  const submittedVote = React.useRef<VoteType>(voteProp);
  const [session] = useSession();
  const { colors } = useTheme();
  const { openSignUpLoginModal } = useModals();

  const viewerId = viewerData?.id ?? session?.user?.id;
  const iconSize = getValueBySize(size, { s: 14, m: 18, l: 24 });
  const iconWidth = getValueBySize(size, { s: '18px', m: '36px' });
  const iconHeight = getValueBySize(size, { s: '20px', m: '24px' });
  const iconPaddingV = getValueBySize(size, { s: '4px', m: '5px' });
  const iconPadding = isHorizontal ? iconPaddingV : undefined;

  const scoreMinWidth = getValueBySize(size, { s: '20px', m: '24px' });
  const scoreFontSize = getValueBySize(size, { s: '12px', m: '14px' });
  const scoreFontWeight = isHorizontal ? '700' : undefined;
  const scorePadding = isHorizontal ? '3px' : undefined;

  const getNewVoteAndInc = React.useCallback(
    (
      inputVote: VoteType,
      basedVote: VoteType,
    ): [VoteType, { upvote: number; downvote: number }] => {
      const newVote = basedVote === inputVote ? VoteType.Unvote : inputVote;
      const incVotes = getVoteInc(newVote, basedVote);
      return [newVote, incVotes];
    },
    [],
  );

  const updateVoteDatabase = useDebouncedCallback(async () => {
    if (viewerId && voteState !== submittedVote.current) {
      const [newVote, { upvote, downvote }] = getNewVoteAndInc(
        voteState,
        submittedVote.current,
      );
      vote(
        environment,
        {
          userId: viewerId,
          objectType,
          objectId,
          vote: newVote,
          incUpvote: upvote,
          incDownvote: downvote,
        },
        {
          viewer: viewerId,
          vote: userVote?.id,
          object: objectId,
        },
      );
      submittedVote.current = newVote;

      await voteCallback?.({
        prevVote: VoteType.Unvote,
        newVote: VoteType.Downvote,
      });
    }
  }, 1000);

  const handleVote = React.useCallback(
    (inputVote: VoteType.Upvote | VoteType.Downvote) => () => {
      if (session?.user || viewerId) {
        const [newVote, { upvote, downvote }] = getNewVoteAndInc(
          inputVote,
          voteState,
        );
        setVoteState(newVote);
        setScoreState(scoreState + (upvote - downvote));
        updateVoteDatabase();
      } else {
        openSignUpLoginModal();
      }
    },
    [
      getNewVoteAndInc,
      openSignUpLoginModal,
      scoreState,
      session?.user,
      updateVoteDatabase,
      viewerId,
      voteState,
    ],
  );

  return (
    <Container flexDirection={isHorizontal ? 'row' : undefined}>
      <IconContainer width={iconWidth} height={iconHeight} pt={iconPadding}>
        <IconButton
          disabled={disabled}
          icon={() => (
            <AntDesign
              name="caretup"
              size={iconSize}
              color={
                session?.user && voteState === VoteType.Upvote
                  ? colors?.orange
                  : colors?.blackOpac
              }
            />
          )}
          onPress={handleVote(VoteType.Upvote)}
        />
      </IconContainer>
      <ScoreContainer minWidth={scoreMinWidth}>
        <Score
          paddingTop={scorePadding}
          fontWeight={scoreFontWeight}
          fontSize={scoreFontSize}
        >
          {scoreState - 1}
        </Score>
      </ScoreContainer>
      <IconContainer width={iconWidth} height={iconHeight} pb={iconPadding}>
        <IconButton
          disabled={disabled}
          icon={() => (
            <AntDesign
              name="caretdown"
              size={iconSize}
              color={
                session?.user && voteState === VoteType.Downvote
                  ? colors?.orange
                  : colors?.blackOpac
              }
            />
          )}
          onPress={handleVote(VoteType.Downvote)}
        />
      </IconContainer>
    </Container>
  );
};

export default React.memo(
  Vote,
  (prev, next) =>
    !!prev.viewer === !!next.viewer &&
    prev.voteScore === next.voteScore &&
    prev.userVote?.vote === next.userVote?.vote &&
    prev.isHorizontal === next.isHorizontal &&
    prev.size === next.size &&
    prev.disabled === next.disabled,
);
