/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentContentBlock_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CommentInput_viewer">;
    readonly " $refType": "CommentContentBlock_viewer";
};
export type CommentContentBlock_viewer$data = CommentContentBlock_viewer;
export type CommentContentBlock_viewer$key = {
    readonly " $data"?: CommentContentBlock_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentContentBlock_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentContentBlock_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentInput_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'd5db4e1db09317b12d950903b5790fe7';
export default node;
