/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommunityListRefetchQueryVariables = {
    viewerId?: string | null;
};
export type CommunityListRefetchQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"CommunityList_userCommunities">;
};
export type CommunityListRefetchQuery = {
    readonly response: CommunityListRefetchQueryResponse;
    readonly variables: CommunityListRefetchQueryVariables;
};



/*
query CommunityListRefetchQuery(
  $viewerId: MongoID
) {
  ...CommunityList_userCommunities_3KSzcj
}

fragment CommunityList_userCommunities_3KSzcj on Query {
  userCommunityFindMany(filter: {userId: $viewerId}) {
    id
    lastVisitedDate
    community {
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewerId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunityListRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "viewerId",
            "variableName": "viewerId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "CommunityList_userCommunities"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommunityListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "userId",
                "variableName": "viewerId"
              }
            ],
            "kind": "ObjectValue",
            "name": "filter"
          }
        ],
        "concreteType": "UserCommunity",
        "kind": "LinkedField",
        "name": "userCommunityFindMany",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastVisitedDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Community",
            "kind": "LinkedField",
            "name": "community",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de4c783b184737e2b8a1af73b53264f2",
    "id": null,
    "metadata": {},
    "name": "CommunityListRefetchQuery",
    "operationKind": "query",
    "text": "query CommunityListRefetchQuery(\n  $viewerId: MongoID\n) {\n  ...CommunityList_userCommunities_3KSzcj\n}\n\nfragment CommunityList_userCommunities_3KSzcj on Query {\n  userCommunityFindMany(filter: {userId: $viewerId}) {\n    id\n    lastVisitedDate\n    community {\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a6ff435086a14a985e778a226455c4c6';
export default node;
