import {
  commitMutation,
  ConnectionHandler,
  Disposable,
  Environment,
  graphql,
} from 'react-relay';

import { CBMutationConfig, RelayConnection } from '#interfaces';
import { DeletePostMutation } from '~/DeletePostMutation.graphql';

const mutation = graphql`
  mutation DeletePostMutation($postId: MongoID!) {
    postRemoveById(_id: $postId) {
      record {
        id
      }
    }
  }
`;

const deletePost = (
  environment: Environment,
  postId: string,
  connection: RelayConnection,
  config?: CBMutationConfig<DeletePostMutation>,
): Disposable => {
  const variables = { postId };

  return commitMutation<DeletePostMutation>(environment, {
    ...config,
    mutation,
    variables,
    updater: (store) => {
      // TODO: this seem to have bug, it don't remove node from connection
      const postPayload = store
        .getRootField('postRemoveById')
        ?.getLinkedRecord('record');
      const postPayloadId = postPayload?.getDataID();
      const deletedPostId = postPayloadId ?? postId;

      store.delete(postPayloadId ?? postId);

      const root = store.getRoot();
      const postsConn = ConnectionHandler.getConnection(
        root,
        connection.key,
        connection.filters,
      );
      if (postsConn) ConnectionHandler.deleteNode(postsConn, deletedPostId);
    },
  });
};

export default deletePost;
