/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdatePostScoreMutationVariables = {
    _id: string;
    shouldUpdateScore: boolean;
};
export type UpdatePostScoreMutationResponse = {
    readonly postUpdateScore?: {
        readonly _id: string;
    };
};
export type UpdatePostScoreMutation = {
    readonly response: UpdatePostScoreMutationResponse;
    readonly variables: UpdatePostScoreMutationVariables;
};



/*
mutation UpdatePostScoreMutation(
  $_id: MongoID!
  $shouldUpdateScore: Boolean!
) {
  postUpdateScore(_id: $_id) @include(if: $shouldUpdateScore) {
    _id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldUpdateScore"
  }
],
v1 = [
  {
    "condition": "shouldUpdateScore",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "_id",
            "variableName": "_id"
          }
        ],
        "concreteType": "Post",
        "kind": "LinkedField",
        "name": "postUpdateScore",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePostScoreMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePostScoreMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "04747c3923eb150be3548016719fa6b1",
    "id": null,
    "metadata": {},
    "name": "UpdatePostScoreMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePostScoreMutation(\n  $_id: MongoID!\n  $shouldUpdateScore: Boolean!\n) {\n  postUpdateScore(_id: $_id) @include(if: $shouldUpdateScore) {\n    _id\n  }\n}\n"
  }
};
})();
(node as any).hash = '77f93b2674591e2cd9fe3d7aaed01adf';
export default node;
