import fbt from 'fbt';
import React from 'react';
import { ActivityIndicator } from 'react-native';

import useFbt from '#hooks/useFbt';

import { Button, Container } from './CommentLoaderButton.style';

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  type?: 'comment' | 'reply';
  count?: number;
  isLoading?: boolean;
  disabled?: boolean;
  onPress?: () => void;
};

const CommentLoaderButton = ({
  type,
  count,
  isLoading,
  disabled,
  onPress,
}: Props) => {
  useFbt();

  const text =
    type === 'comment'
      ? fbt(
          `View more ${fbt.plural('comment', count ?? 0, {
            name: 'number of comments',
            showCount: 'ifMany',
            many: 'comments',
          })}`,
          'load more comment label',
        )
      : fbt(
          `View more ${fbt.plural('reply', count ?? 0, {
            name: 'number of replies',
            showCount: 'ifMany',
            many: 'replies',
          })}`,
          'load more comment label',
        );

  return count && count > 0 ? (
    <Container disabled={disabled ?? isLoading} onPress={onPress}>
      <Button>{text}</Button>
      {isLoading && <ActivityIndicator size={12} />}
    </Container>
  ) : null;
};

export default React.memo(CommentLoaderButton);
