/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card as PaperCard } from 'react-native-paper';
import styled from 'styled-components/native';

import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from '#styled-system';

export const CardContainer = styled.View<LayoutProps & SpaceProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
  ${layout};
  ${space};
`;

export const Card = styled(PaperCard)<LayoutProps & BorderProps>`
  width: 100%;
  max-width: 95%;
  ${border};
  ${layout};
`;

export default null;
