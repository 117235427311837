import { useEffect, useMemo, useRef, useState } from 'react';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';

import { ObjectType } from '#enum';
import { StoredSuggestedTags, StoredTags, TagItem } from '#interfaces';
import TagStorage from '#storage/TagStorage';
import { compareTags, getTagsHash, hour, minute } from '#utils';
import { useSuggestedTagsQuery } from '~/useSuggestedTagsQuery.graphql';

const haveTagsTTL = hour;
const noTagTTL = minute * 5;

const useSuggestedTags = (
  queryFor: ObjectType,
  selectedTagIds: string[] = [],
  initialSuggestedTags: TagItem[] = [],
  viewerId?: string, // to get specific suggested tags, normally, when it is tagged tag search
  isTaggedSearch?: boolean,
  lastTaggedDate?: string | null,
): TagItem[] => {
  const environment = useRelayEnvironment();

  const isInitData = useRef(false);
  const hashRef = useRef('');
  const tags = useRef<StoredTags>({});
  const storedSuggestedTags = useRef<StoredSuggestedTags>({});
  const [suggestedTags, setSuggestedTags] = useState<TagItem[]>(
    initialSuggestedTags,
  );

  const hash = getTagsHash(
    selectedTagIds,
    queryFor,
    isTaggedSearch ? viewerId : undefined,
  );
  const res = storedSuggestedTags.current[hash];
  const viewerLastTagged = useMemo(
    () => (lastTaggedDate ? new Date(lastTaggedDate) : new Date()),
    [lastTaggedDate],
  );
  const currentTime = new Date().getTime();
  const storedDateData =
    typeof res?.storedDate === 'string'
      ? new Date(res.storedDate)
      : res?.storedDate;
  const storedDate = storedDateData?.getTime() ?? 0;
  const dataAge = currentTime - storedDate;

  useEffect(() => {
    // hydrate viewer with stored suggestion tags
    (async () => {
      if (!isInitData.current && viewerId) {
        const storedTagsData = await TagStorage.getTags();
        const storedSuggestedTagsData = await TagStorage.getSuggestedTags();
        tags.current = storedTagsData ?? {};
        storedSuggestedTags.current = storedSuggestedTagsData?.[viewerId] ?? {};
        isInitData.current = true;
      }
    })();
  }, [viewerId]);

  useEffect(() => {
    if (hash !== hashRef.current) {
      const formattedRes = res?.tags
        .map((tag) => tags.current[tag.tagId])
        .filter((tag) => tag);

      if (formattedRes && !compareTags(suggestedTags, formattedRes))
        setSuggestedTags(formattedRes);

      if (
        (viewerId && viewerLastTagged.getTime() > storedDate) ||
        (!viewerId &&
          ((selectedTagIds.length && dataAge > haveTagsTTL) ||
            dataAge > noTagTTL))
      ) {
        const query = fetchQuery<useSuggestedTagsQuery>(
          environment,
          graphql`
            query useSuggestedTagsQuery(
              $tagIds: [String]
              $queryFor: String!
              $userId: String
            ) {
              getSuggestedTags(
                tagIds: $tagIds
                queryFor: $queryFor
                userId: $userId
              ) {
                suggestedTags {
                  tag {
                    tagId
                    slug
                    label
                  }
                }
              }
            }
          `,
          {
            tagIds: selectedTagIds,
            queryFor,
            userId: isTaggedSearch ? viewerId : undefined,
          },
        ).subscribe({
          next: (data) => {
            const resSuggestedTags = data.getSuggestedTags?.suggestedTags?.reduce(
              (resTags, tag) =>
                tag?.tag
                  ? [
                      ...resTags,
                      {
                        id: tag.tag.tagId,
                        slug: tag.tag.slug,
                        label: tag.tag.label,
                      },
                    ]
                  : resTags,
              [] as TagItem[],
            );

            if (resSuggestedTags) {
              resSuggestedTags.forEach((tag) => {
                tags.current[tag.id] = tag;
              });

              storedSuggestedTags.current[hash] = {
                tags: resSuggestedTags.map((tag) => ({ tagId: tag.id })),
                storedDate: new Date(),
              };

              TagStorage.setTags(tags.current);
              TagStorage.setSuggestedTags(
                storedSuggestedTags.current,
                viewerId,
              );

              // prevent the inconsistency setState between new incoming and returned data
              // by always using the stored data of the latest hash (hashRef) instead.
              const newSuggestedTags = storedSuggestedTags.current[
                hashRef.current
              ]?.tags.map((tag) => tags.current[tag.tagId]);

              setSuggestedTags(newSuggestedTags);
            }
          },
        });

        return () => {
          query.unsubscribe();
        };
      }

      hashRef.current = hash;
    }
  }, [
    dataAge,
    environment,
    hash,
    isTaggedSearch,
    queryFor,
    res,
    selectedTagIds,
    storedDate,
    suggestedTags,
    viewerId,
    viewerLastTagged,
  ]);

  return suggestedTags;
};

export default useSuggestedTags;
