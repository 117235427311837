/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardFullContent_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"PostCard_viewer">;
    readonly " $refType": "PostCardFullContent_viewer";
};
export type PostCardFullContent_viewer$data = PostCardFullContent_viewer;
export type PostCardFullContent_viewer$key = {
    readonly " $data"?: PostCardFullContent_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardFullContent_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardFullContent_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCard_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '1ad3fb33fee542501d694bc7b700871c';
export default node;
