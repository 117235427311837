import { SortGetCommentsCommentEnum } from '~~';

type CommentScoreKey = 'featuredScore' | 'hotScore' | 'wilsonScore' | null;

const getCommentScoreKey = (
  value: SortGetCommentsCommentEnum,
): CommentScoreKey => {
  if (value === SortGetCommentsCommentEnum.FeaturedScore)
    return 'featuredScore';
  if (value === SortGetCommentsCommentEnum.HotScore) return 'hotScore';
  if (value === SortGetCommentsCommentEnum.WilsonScore) return 'wilsonScore';
  return null;
};

export default getCommentScoreKey;
