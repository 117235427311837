import { AntDesign } from '@expo/vector-icons';
import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CreateCommunityButton from '@/CreateCommunityButton';
import useFbt from '#hooks/useFbt';
import useTheme from '#hooks/useTheme';
import { TagItem } from '#interfaces';
import { getObjectJoinString } from '#utils';
import { FilterTagsFooter_viewer$key } from '~/FilterTagsFooter_viewer.graphql';

import {
  CommunityButtonContainer,
  Container,
  SubmitButton,
  SubmitButtonContainer,
  SubmitButtonLabel,
} from './FilterTagsFooter.style';

const viewerFragment = graphql`
  fragment FilterTagsFooter_viewer on User {
    ...CreateCommunityButton_viewer
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: FilterTagsFooter_viewer$key | null;
  selectedTags?: TagItem[] | null;
  canCreateCommunity?: boolean;
  isSubmittable?: boolean;
  onSubmit?: () => void;
};

const FilterTagsFooter = ({
  viewer,
  selectedTags,
  canCreateCommunity,
  isSubmittable,
  onSubmit,
}: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);

  useFbt();

  const { colors } = useTheme();

  return (
    <Container>
      {canCreateCommunity && viewerData && (
        <CommunityButtonContainer>
          <CreateCommunityButton
            viewer={viewerData}
            selectedTags={selectedTags}
          />
        </CommunityButtonContainer>
      )}
      {isSubmittable && (
        <SubmitButtonContainer>
          <SubmitButton onPress={onSubmit}>
            <SubmitButtonLabel>
              <fbt desc="button label">Go</fbt>
            </SubmitButtonLabel>
            <AntDesign name="arrowright" size={18} color={colors?.text} />
          </SubmitButton>
        </SubmitButtonContainer>
      )}
    </Container>
  );
};

export default React.memo(
  FilterTagsFooter,
  (prev, next) =>
    getObjectJoinString(prev.selectedTags, ['id']) ===
      getObjectJoinString(next.selectedTags, ['id']) &&
    prev.canCreateCommunity === next.canCreateCommunity &&
    prev.isSubmittable === next.isSubmittable &&
    !!prev.viewer === !!next.viewer,
);
