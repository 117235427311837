import constate from 'constate';
import { useCallback, useMemo, useState } from 'react';

const useCommentLocalHook = () => {
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isReplyEditorOpen, setIsReplyEditorOpen] = useState(false);
  const [isRefetchOnSubmit, setIsRefetchOnSubmit] = useState(false);
  const [connections, setConnections] = useState<string[]>();

  const resetShouldRefetch = useCallback(() => setShouldRefetch(false), []);

  const openEditor = useCallback(() => setIsEditable(true), []);
  const closeEditor = useCallback(() => setIsEditable(false), []);

  const openReplyEditor = useCallback(() => setIsReplyEditorOpen(true), []);
  const closeReplyEditor = useCallback(() => setIsReplyEditorOpen(false), []);

  const contextValue = useMemo(
    () => ({
      connections,
      setConnections,
      isEditable,
      setIsEditable,
      openEditor,
      closeEditor,
      isReplyEditorOpen,
      setIsReplyEditorOpen,
      openReplyEditor,
      closeReplyEditor,
      isRefetchOnSubmit,
      setIsRefetchOnSubmit,
      shouldRefetch,
      setShouldRefetch,
      resetShouldRefetch,
    }),
    [
      closeEditor,
      closeReplyEditor,
      connections,
      isEditable,
      isRefetchOnSubmit,
      isReplyEditorOpen,
      openEditor,
      openReplyEditor,
      resetShouldRefetch,
      shouldRefetch,
    ],
  );

  return contextValue;
};

const [CommentLocalProvider, useCommentLocal] = constate(useCommentLocalHook);

export { CommentLocalProvider };
export default useCommentLocal;
