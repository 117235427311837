/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

export const Container = styled.View`
  display: flex;
  flex-direction: column;
`;

export const TaggedTagsBoxContainer = styled.View`
  z-index: 1;
  margin-bottom: 24px;
`;

export const IconContainer = styled.View`
  align-items: center;
  justify-content: center;
  height: 24px;
  margin: auto;
`;

export default null;
