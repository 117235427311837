/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Post_post = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"PostCard_post" | "PostCardFullContent_post">;
    readonly " $refType": "Post_post";
};
export type Post_post$data = Post_post;
export type Post_post$key = {
    readonly " $data"?: Post_post$data;
    readonly " $fragmentRefs": FragmentRefs<"Post_post">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "viewerId",
    "variableName": "viewerId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Post_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "PostCard_post"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "PostCardFullContent_post"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();
(node as any).hash = '6c0f784611f3661af9f74a680a22566b';
export default node;
