import { commitMutation, Disposable, Environment, graphql } from 'react-relay';

import { ObjectType, VoteType } from '#enum';
import { CBMutationConfig } from '#interfaces';
import { VoteMutation } from '~/VoteMutation.graphql';

let tempId = 0;

const mutation = graphql`
  mutation VoteMutation(
    $userId: MongoID!
    $objectType: String!
    $objectId: MongoID!
    $vote: String!
    $incUpvote: Int
    $incDownvote: Int
    $postIncVote: Boolean = false
    $commentIncVote: Boolean = false
  ) {
    voteUpsert(
      userId: $userId
      objectType: $objectType
      objectId: $objectId
      vote: $vote
    ) {
      id
      vote
    }
    postVote(_id: $objectId, incUpvote: $incUpvote, incDownvote: $incDownvote)
      @include(if: $postIncVote) {
      id
      vote {
        upvotes
        downvotes
      }
    }
    commentVote(
      _id: $objectId
      incUpvote: $incUpvote
      incDownvote: $incDownvote
    ) @include(if: $commentIncVote) {
      id
      vote {
        upvotes
        downvotes
      }
    }
  }
`;

const vote = (
  environment: Environment,
  args: {
    userId: string;
    objectType: ObjectType;
    objectId: string;
    vote: VoteType;
    incUpvote?: number;
    incDownvote?: number;
  },
  nodeIds?: { viewer?: string; vote?: string; object?: string },
  config?: CBMutationConfig<VoteMutation>,
): Disposable => {
  const variables = {
    ...args,
    postIncVote:
      args.objectType === ObjectType.Post &&
      (!!args.incUpvote || !!args.incDownvote),
    commentIncVote:
      args.objectType === ObjectType.Comment &&
      (!!args.incUpvote || !!args.incDownvote),
  };

  const res = commitMutation<VoteMutation>(environment, {
    ...config,
    mutation,
    variables,
    optimisticUpdater: (store) => {
      if (nodeIds?.viewer && nodeIds?.object) {
        const objectNode = store.get(nodeIds.object);
        const userVote = objectNode?.getLinkedRecord('userVote', {
          userId: nodeIds.viewer,
        });

        if (userVote) {
          userVote?.setValue(args.vote, 'vote');
        } else {
          const newUserVote = store
            .create(`client:newVote:${(tempId += 1)}`, 'Vote')
            .setValue(args.vote, 'vote');
          objectNode?.setLinkedRecord(newUserVote, 'userVote', {
            userId: nodeIds.viewer,
          });
        }

        const voteCount = objectNode?.getLinkedRecord('vote');
        const upvoteCount = (voteCount?.getValue('upvotes') ?? 0) as number;
        const downvoteCount = (voteCount?.getValue('downvote') ?? 0) as number;

        if (args.incUpvote)
          voteCount?.setValue(upvoteCount + args.incUpvote, 'upvotes');
        if (args.incUpvote)
          voteCount?.setValue(downvoteCount + args.incUpvote, 'downvotes');
      }
    },
    updater: (store) => {
      const votePayload = store.getRootField('voteUpsert');

      if (votePayload && nodeIds?.object) {
        store.get(nodeIds.object)?.setLinkedRecord(votePayload, 'userVote', {
          userId: nodeIds.viewer,
        });
      } else if (nodeIds?.vote) {
        store.delete(nodeIds.vote);
      }
    },
  });

  return res;
};

export default vote;
