import { dequal } from 'dequal';
import React from 'react';
import { graphql, useFragment, useRelayEnvironment } from 'react-relay';

import ControlledTaggedTagsBox from '@/ControlledTaggedTagsBox';
import { ControlledTaggedTags } from '@/ControlledTaggedTagsBox/ControlledTaggedTagsBox';
import FormFooterButtons from '@/FormFooterButtons';
import ModalCard from '@/ModalCard';
import TagIconButton from '@/TagIconButton';
import { formError } from '#analytics/app/form';
import { postTagged } from '#analytics/app/post';
import { ObjectType } from '#enum';
import useFullPostAnalytics from '#hooks/useFullPostAnalytics';
import useModals from '#hooks/useModals';
import useSession from '#hooks/useSession';
import { TagItem } from '#interfaces';
import updateTaggedTags, { ObjectOperation } from '#mutations/UpdateTaggedTags';
import { compareTags } from '#utils';
import { TaggedTagsModal_post$key } from '~/TaggedTagsModal_post.graphql';
import { TaggedTagsModal_viewer$key } from '~/TaggedTagsModal_viewer.graphql';

import {
  Container,
  IconContainer,
  TaggedTagsBoxContainer,
} from './TaggedTagsModal.style';

const viewerFragment = graphql`
  fragment TaggedTagsModal_viewer on User {
    id
  }
`;

const postFragment = graphql`
  fragment TaggedTagsModal_post on Post
  @argumentDefinitions(viewerId: { type: "MongoID" }) {
    vote {
      upvotes
      downvotes
    }
    count {
      shared
      tagged
    }
    meta {
      key
      value
    }
    userTaggedTags(userId: $viewerId) {
      id
      tags {
        id
        label
        slug
      }
    }
  }
`;

type Props = {
  viewer: TaggedTagsModal_viewer$key | null;
  post: TaggedTagsModal_post$key | null;
  modalLabel: string;
  objectType: ObjectType;
  objectId: string;
  isSmallIcon?: boolean;
};

type TaggedTags = TagItem[] | null | undefined;

const TaggedTagsModal = ({
  viewer,
  post,
  modalLabel,
  objectType,
  objectId,
  isSmallIcon,
}: Props) => {
  const environment = useRelayEnvironment();
  const viewerData = useFragment(viewerFragment, viewer);
  const postData = useFragment(postFragment, post);

  const [session] = useSession();
  const { openSignUpLoginModal } = useModals();
  const tags = React.useRef<ControlledTaggedTags>();
  const [initialTags, setInitialTags] = React.useState(
    postData?.userTaggedTags?.tags,
  );
  const [visible, setVisible] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmittable, setIsSubmittable] = React.useState(false);
  const fullPostProps = useFullPostAnalytics(postData);
  const isMounted = React.useRef(false);

  const handleTagChange = React.useCallback((state: ControlledTaggedTags) => {
    tags.current = state;
    setIsSubmittable(
      !compareTags(state.initialTags, state.selectedTags) &&
        (!!state.initialTags?.length || !!state.selectedTags?.length),
    );
  }, []);

  const handleSubmit = React.useCallback(async () => {
    if (viewerData?.id && isSubmittable) {
      setIsSubmitting(true);
      updateTaggedTags(
        environment,
        {
          userId: viewerData.id,
          objectType,
          objectId,
          prevTagList: initialTags as TagItem[],
          newTagList: tags.current?.selectedTags,
          objectOperation: ObjectOperation.UpdateTaggedTags,
        },
        {
          onCompleted: async (res) => {
            if (isMounted.current && res.taggedPostAddAndRemoveTags?.tags) {
              if (compareTags(initialTags, res.taggedPostAddAndRemoveTags.tags))
                setInitialTags(
                  res.taggedPostAddAndRemoveTags.tags as TagItem[],
                );

              setVisible(false);
              setIsSubmittable(false);

              const currentTagsLen = !!initialTags?.length;
              const newTagsLen = !!res.taggedPostAddAndRemoveTags.tags.length;

              if (!currentTagsLen && newTagsLen)
                await postTagged({
                  actionType: 'tagged',
                  ...fullPostProps,
                });
              else if (currentTagsLen && newTagsLen)
                await postTagged({
                  actionType: 'updated',
                  ...fullPostProps,
                });
              else if (currentTagsLen && !newTagsLen)
                await postTagged({
                  actionType: 'untagged',
                  ...fullPostProps,
                });
            }
            setIsSubmitting(false);
          },
          onError: async () => {
            await formError({ formName: 'taggedTagsModal' });
            setIsSubmitting(false);
          },
        },
      );
    }
  }, [
    environment,
    fullPostProps,
    initialTags,
    isSubmittable,
    objectId,
    objectType,
    viewerData?.id,
  ]);

  const handleCancel = React.useCallback(() => {
    tags.current = undefined;
    setVisible(false);
    setIsSubmittable(false);
  }, []);

  const handlePress = React.useCallback(() => {
    if (session?.user || viewerData?.id) {
      setVisible(true);
    } else {
      openSignUpLoginModal();
    }
  }, [openSignUpLoginModal, session?.user, viewerData?.id]);

  React.useEffect(() => {
    isMounted.current = true;

    if (isMounted) {
      const taggedTagsProps = (postData?.userTaggedTags?.tags ??
        []) as TaggedTags;

      if (
        viewerData?.id &&
        taggedTagsProps &&
        !compareTags(taggedTagsProps, initialTags as TagItem[])
      )
        setInitialTags(taggedTagsProps);
    }

    return () => {
      isMounted.current = false;
    };
  }, [initialTags, postData?.userTaggedTags?.tags, viewerData?.id]);

  return (
    <Container>
      <IconContainer>
        <TagIconButton
          isActive={!!initialTags?.length}
          isSmallIcon={isSmallIcon}
          onPress={handlePress}
        />
      </IconContainer>
      <ModalCard
        visible={visible}
        title={modalLabel}
        width="600px"
        onDismiss={handleCancel}
        overflow
      >
        <TaggedTagsBoxContainer>
          <ControlledTaggedTagsBox
            autoFocus
            initFocus
            canCreateNewTag
            selectedTagsLimit={10}
            initSelectedTags={initialTags as TaggedTags}
            onTagChange={handleTagChange}
          />
        </TaggedTagsBoxContainer>
        <FormFooterButtons
          isSubmitting={isSubmitting}
          disabled={!isSubmittable}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </ModalCard>
    </Container>
  );
};

export default React.memo(
  TaggedTagsModal,
  (prev, next) =>
    dequal(prev.post, next.post) && prev.isSmallIcon === next.isSmallIcon,
);
