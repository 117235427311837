/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentBlock_comment = {
    readonly id: string;
    readonly postId: string;
    readonly parentCommentId: string | null;
    readonly count: {
        readonly totalSubComments: number | null;
    } | null;
    readonly comment: string;
    readonly scoreInfo: {
        readonly lastUpdatedDate: string | null;
    } | null;
    readonly createdDate: string | null;
    readonly " $fragmentRefs": FragmentRefs<"CommentContentBlock_comment" | "CollapsedCommentBlock_comment" | "CommentCascadeLine_comment" | "CommentBlockHeader_comment" | "CommentBlockFooter_comment">;
    readonly " $refType": "CommentBlock_comment";
};
export type CommentBlock_comment$data = CommentBlock_comment;
export type CommentBlock_comment$key = {
    readonly " $data"?: CommentBlock_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentBlock_comment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentBlock_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentCommentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentCount",
      "kind": "LinkedField",
      "name": "count",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSubComments",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentScoreInfo",
      "kind": "LinkedField",
      "name": "scoreInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastUpdatedDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentContentBlock_comment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollapsedCommentBlock_comment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentCascadeLine_comment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentBlockHeader_comment"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "viewerId",
          "variableName": "viewerId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "CommentBlockFooter_comment"
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
(node as any).hash = '808012bf85fce3286efe0220a72e729a';
export default node;
