/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type CurrentCommunityDeleteCommunityMutationVariables = {
    userCommunityId: string;
};
export type CurrentCommunityDeleteCommunityMutationResponse = {
    readonly userCommunityDeleteCommunity: {
        readonly record: {
            readonly community: {
                readonly id: string;
                readonly name: string;
                readonly status: {
                    readonly value: EnumDocumentStatusValue | null;
                } | null;
            } | null;
        } | null;
    } | null;
};
export type CurrentCommunityDeleteCommunityMutation = {
    readonly response: CurrentCommunityDeleteCommunityMutationResponse;
    readonly variables: CurrentCommunityDeleteCommunityMutationVariables;
};



/*
mutation CurrentCommunityDeleteCommunityMutation(
  $userCommunityId: MongoID!
) {
  userCommunityDeleteCommunity(userCommunityId: $userCommunityId) {
    record {
      community {
        id
        name
        status {
          value
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userCommunityId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userCommunityId",
        "variableName": "userCommunityId"
      }
    ],
    "concreteType": "RemoveByIdUserCommunityPayload",
    "kind": "LinkedField",
    "name": "userCommunityDeleteCommunity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserCommunity",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Community",
            "kind": "LinkedField",
            "name": "community",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentCommunityDeleteCommunityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurrentCommunityDeleteCommunityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "59f9e045ceb26438f1ea022008ea5070",
    "id": null,
    "metadata": {},
    "name": "CurrentCommunityDeleteCommunityMutation",
    "operationKind": "mutation",
    "text": "mutation CurrentCommunityDeleteCommunityMutation(\n  $userCommunityId: MongoID!\n) {\n  userCommunityDeleteCommunity(userCommunityId: $userCommunityId) {\n    record {\n      community {\n        id\n        name\n        status {\n          value\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1a612dd77ace0f7198856e870f103f30';
export default node;
