/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CommentButtonDelete_comment = {
    readonly id: string;
    readonly parentCommentId: string | null;
    readonly " $refType": "CommentButtonDelete_comment";
};
export type CommentButtonDelete_comment$data = CommentButtonDelete_comment;
export type CommentButtonDelete_comment$key = {
    readonly " $data"?: CommentButtonDelete_comment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentButtonDelete_comment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentButtonDelete_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentCommentId",
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
(node as any).hash = '7da6db164e5f8c9f9e50cc55e65bc40d';
export default node;
