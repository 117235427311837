/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Post_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"PostCard_viewer" | "PostCardFullContent_viewer">;
    readonly " $refType": "Post_viewer";
};
export type Post_viewer$data = Post_viewer;
export type Post_viewer$key = {
    readonly " $data"?: Post_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"Post_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Post_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCard_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCardFullContent_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '794b23de118efa6125bbc10d40a2f88d';
export default node;
