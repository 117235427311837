import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CurrentCommunity from '@/CurrentCommunity';
import CurrentCommunityPlaceholder from '@/CurrentCommunity/CurrentCommunityPlaceholder';
import FilterTagsBox from '@/FilterTagsBox';
import { SidebarTagsBox_data$key } from '~/SidebarTagsBox_data.graphql';
import { SidebarTagsBox_viewer$key } from '~/SidebarTagsBox_viewer.graphql';

const dataFragment = graphql`
  fragment SidebarTagsBox_data on Query
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    userCommunityId: { type: "MongoID" }
    tagIds: { type: "[String]" }
    queryFor: { type: "String" }
  ) {
    ...CurrentCommunity_userCommunity
      @arguments(viewerId: $viewerId, userCommunityId: $userCommunityId)
    ...FilterTagsBox_tags @arguments(tagIds: $tagIds, queryFor: $queryFor)
  }
`;

const viewerFragment = graphql`
  fragment SidebarTagsBox_viewer on User {
    ...FilterTagsBox_viewer
    ...CurrentCommunity_viewer
  }
`;

type Props = {
  viewer: SidebarTagsBox_viewer$key | null;
  data: SidebarTagsBox_data$key;
};

const SidebarTagsBox = ({ viewer, data }: Props) => {
  const dataData = useFragment(dataFragment, data);
  const viewerData = useFragment(viewerFragment, viewer);

  const defaultComponent = React.useMemo(
    () => <FilterTagsBox viewer={viewerData} tags={dataData} />,
    [dataData, viewerData],
  );

  return (
    <React.Suspense fallback={<CurrentCommunityPlaceholder />}>
      <CurrentCommunity
        viewer={viewerData}
        userCommunity={dataData}
        defaultComponent={defaultComponent}
      />
    </React.Suspense>
  );
};

export default React.memo(SidebarTagsBox);
