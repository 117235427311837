import { useRouting } from 'expo-next-react-navigation';
import fbt from 'fbt';
import React from 'react';
import { graphql, useFragment, usePaginationFragment } from 'react-relay';

import Feed from '@/Feed';
import FeedSideSorter from '@/FeedSideSorter';
import Post from '@/Post';
import PostFeedEmpty from '@/PostFeedEmpty';
import { QueryParam } from '#enum';
import useFbt from '#hooks/useFbt';
import { feedFilter, feedSort } from '#relay/helpers/feed';
import { initFeedCount } from '#variables';
import {
  PostFeedMain_posts,
  PostFeedMain_posts$key,
} from '~/PostFeedMain_posts.graphql';
import { PostFeedMain_viewer$key } from '~/PostFeedMain_viewer.graphql';

const viewerFragemnt = graphql`
  fragment PostFeedMain_viewer on User {
    ...Post_viewer
    ...PostFeedEmpty_viewer
  }
`;

const postsFragment = graphql`
  fragment PostFeedMain_posts on Query
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    count: { type: "Int", defaultValue: 5 }
    cursor: { type: "String" }
    feedFilter: { type: "FilterFindManyPostInput" }
    feedSort: { type: "SortFeedPostEnum" }
  )
  @refetchable(queryName: "PostFeedMain_posts_Query") {
    postFeed(
      first: $count
      after: $cursor
      filter: $feedFilter
      sort: $feedSort
    ) @connection(key: "PostFeedMain_postFeed", filters: ["filter", "sort"]) {
      edges {
        node {
          id
          ...Post_post @arguments(viewerId: $viewerId)
        }
      }
    }
  }
`;

// eslint-disable-next-line no-unused-expressions
fbt;

type Props = {
  viewer: PostFeedMain_viewer$key | null;
  posts: PostFeedMain_posts$key | null;
  initIsRefetch?: boolean;
};

const PostFeedMain = ({
  viewer,
  posts,
  initIsRefetch,
}: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragemnt, viewer);
  const { data: postsData, ...postsPagination } = usePaginationFragment(
    postsFragment,
    posts,
  );

  useFbt();

  const { getParam, pathname } = useRouting();

  const f = getParam(QueryParam.FeedQuery) as string | undefined;
  const fs = getParam(QueryParam.FeedScope) as string | undefined;
  const sort = getParam(QueryParam.FeedSort) as string | undefined;
  const query: {
    [QueryParam.FeedQuery]?: string;
    [QueryParam.FeedScope]?: string;
    [QueryParam.FeedSort]?: string;
  } = React.useMemo(
    () => ({
      [QueryParam.FeedQuery]: f,
      [QueryParam.FeedScope]: fs,
      [QueryParam.FeedSort]: sort,
    }),
    [f, fs, sort],
  );

  const refetchVariables = React.useMemo(
    () => ({
      feedFilter: feedFilter(query[QueryParam.FeedQuery]),
      feedSort: feedSort(query[QueryParam.FeedSort]),
    }),
    [query],
  );

  const connection = React.useMemo(
    () => ({
      key: 'PostFeedMain_postFeed',
      filters: {
        filter: refetchVariables.feedFilter,
        sort: refetchVariables.feedSort,
      },
    }),
    [refetchVariables.feedFilter, refetchVariables.feedSort],
  );

  const renderItem = React.useCallback(
    ({ item }): React.ReactElement | null =>
      item?.node ? (
        <Post connection={connection} viewer={viewerData} post={item.node} />
      ) : null,
    [connection, viewerData],
  );

  if (
    !initIsRefetch &&
    viewer &&
    pathname === '/' &&
    !f &&
    !postsData?.postFeed?.edges?.length
  )
    return <PostFeedEmpty viewer={viewerData} />;

  return (
    <Feed<PostFeedMain_posts['postFeed']>
      initIsRefetch={initIsRefetch}
      pagination={postsPagination}
      isDataReady={!!postsData?.postFeed}
      feedSidebar={
        (postsData?.postFeed?.edges.length ?? 0) >= initFeedCount ? (
          <FeedSideSorter />
        ) : null
      }
      feedSidebarWidth={postsData?.postFeed ? 48 : undefined}
      data={postsData?.postFeed?.edges}
      renderItem={renderItem}
      refetchVariables={refetchVariables}
      query={query}
      itemsPerLoad={initFeedCount}
      noMoreText={
        postsData?.postFeed?.edges.length
          ? fbt('No more post', 'footer message')
          : fbt('No post for the selected tags', 'search results message')
      }
    />
  );
};

export default PostFeedMain;
