/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';
import { typography, TypographyProps } from '#styled-system';

const PaleBoldText = styled(Text)<TypographyProps>`
  margin: 8px 0 12px;
  color: ${(props: any) => props.theme.colors.blackOpac20};
  font-weight: bold;
  ${typography};
`;

export default PaleBoldText;
