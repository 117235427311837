/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

import Text from '@/Text';

export const Container = styled.View`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
`;

export const LabelContainer = styled.View`
  display: inline-flex;
  flex: 0 0 auto;
`;

export const Label = styled(Text)`
  color: ${(props: any) => props.theme.colors.text2};
  font-weight: bold;
  font-size: 10px;
`;

export const DropdownContainer = styled.View`
  display: inline-flex;
  flex: 0 0 auto;
  margin-start: 6px;
`;

export default null;
