import React from 'react';
import { graphql, useFragment } from 'react-relay';

import CommunityList from '@/CommunityList';
import FeedDropdownSorter from '@/FeedDropdownSorter';
import FooterNav from '@/FooterNav';
import SidebarItem from '@/SidebarItem';
import SidebarNav from '@/SidebarNav';
import SidebarTagsBox from '@/SidebarTagsBox';
import useAppState from '#hooks/useAppState';
import useResponsive from '#hooks/useResponsive';
import { IndexPageSidebar_data$key } from '~/IndexPageSidebar_data.graphql';
import { IndexPageSidebar_viewer$key } from '~/IndexPageSidebar_viewer.graphql';

import { ComponentContainer } from './IndexPageSidebar.style';

const dataFragment = graphql`
  fragment IndexPageSidebar_data on Query
  @argumentDefinitions(
    viewerId: { type: "MongoID" }
    tagIds: { type: "[String]" }
    queryFor: { type: "String!" }
    userCommunityId: { type: "MongoID" }
    isInCommunity: { type: "Boolean" }
  ) {
    ...SidebarTagsBox_data
      @arguments(
        viewerId: $viewerId
        userCommunityId: $userCommunityId
        tagIds: $tagIds
        queryFor: $queryFor
      )
    ...CommunityList_userCommunities @arguments(viewerId: $viewerId)
  }
`;

const viewerFragment = graphql`
  fragment IndexPageSidebar_viewer on User {
    ...SidebarTagsBox_viewer
    ...CommunityList_viewer
    ...FooterNav_viewer
  }
`;

type Props = {
  viewer: IndexPageSidebar_viewer$key | null;
  data: IndexPageSidebar_data$key;
};

const IndexPageSidebar = ({ data, viewer }: Props): React.ReactElement => {
  const dataData = useFragment(dataFragment, data);
  const viewerData = useFragment(viewerFragment, viewer);

  const [, { lteSm, sidebarType }] = useResponsive();
  const { isPostSortable } = useAppState();

  const isDrawer = sidebarType === 'drawer';

  return (
    <>
      <ComponentContainer>
        <SidebarItem mb="8px">
          {lteSm && isPostSortable ? <FeedDropdownSorter /> : null}
        </SidebarItem>
        <SidebarItem>
          <SidebarTagsBox viewer={viewerData} data={dataData} />
        </SidebarItem>
        <SidebarItem>
          <CommunityList viewer={viewerData} userCommunities={dataData} />
        </SidebarItem>
        {!viewer && <SidebarNav />}
      </ComponentContainer>
      <FooterNav viewer={viewerData} marginBottom={isDrawer ? 44 : undefined} />
    </>
  );
};

export default React.memo(
  IndexPageSidebar,
  (prev, next) => !!prev.viewer === !!next.viewer,
);
