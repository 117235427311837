import mongoose from 'mongoose';
import base64 from 'react-native-base64';

export const encodeObjectId = (
  objectId: string | mongoose.Types.ObjectId,
): string => base64.encode(objectId.toString());

export const decodeObjectId = (
  encodedBase64?: string | string[],
): string | undefined => {
  if (!encodedBase64 || typeof encodedBase64 !== 'string') return undefined;

  const decoded = base64.decode(encodedBase64);

  return mongoose.Types.ObjectId.isValid(decoded) ? decoded : undefined;
};

export default null;
