import styled from 'styled-components/native';

import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  zIndex,
  ZIndexProps,
} from '#styled-system';

export const Container = styled.View<ZIndexProps & LayoutProps & SpaceProps>`
  display: flex;
  margin-bottom: 40px;
  ${zIndex};
  ${layout};
  ${space};
`;

export default null;
