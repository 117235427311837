/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostFeed_viewer = {
    readonly id: string;
    readonly " $fragmentRefs": FragmentRefs<"PostFeedMain_viewer" | "PostFeedTagged_viewer" | "PostFeedChannel_viewer">;
    readonly " $refType": "PostFeed_viewer";
};
export type PostFeed_viewer$data = PostFeed_viewer;
export type PostFeed_viewer$key = {
    readonly " $data"?: PostFeed_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostFeed_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostFeed_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostFeedMain_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostFeedTagged_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostFeedChannel_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '55ed41a75eb32841acf20557a1fcb565';
export default node;
