/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EnumDocumentStatusValue = "Deleted" | "Suspended" | "%future added value";
export type UpdateByIdCommentInput = {
    status?: UpdateByIdDocumentStatusInput | null;
    postId?: string | null;
    communityId?: string | null;
    parentCommentId?: string | null;
    version?: string | null;
    comment?: string | null;
    featuredSubCommentIds?: UpdateByIdCommentFeaturedSubCommentIdsInput | null;
    vote?: UpdateByIdCommentVoteInput | null;
    featuredScore?: number | null;
    wilsonScore?: number | null;
    hotScore?: number | null;
    scoreInfo?: UpdateByIdCommentScoreInfoInput | null;
    count?: UpdateByIdCommentCountInput | null;
    createdDate?: string | null;
    createdByUserId?: string | null;
    lastModifiedDate?: string | null;
    lastModifiedByUserId?: string | null;
    settings?: Array<UpdateByIdKeyValueInput | null> | null;
    resource?: Array<UpdateByIdKeyValueInput | null> | null;
    meta?: Array<UpdateByIdKeyValueInput | null> | null;
};
export type UpdateByIdDocumentStatusInput = {
    value?: EnumDocumentStatusValue | null;
    reason?: string | null;
    timestamp?: string | null;
};
export type UpdateByIdCommentFeaturedSubCommentIdsInput = {
    featured?: Array<UpdateByIdFeaturedSubCommentInput | null> | null;
    hot?: Array<UpdateByIdFeaturedSubCommentInput | null> | null;
    best?: Array<UpdateByIdFeaturedSubCommentInput | null> | null;
    controversial?: Array<UpdateByIdFeaturedSubCommentInput | null> | null;
    oldest?: Array<UpdateByIdFeaturedSubCommentInput | null> | null;
    newest?: Array<UpdateByIdFeaturedSubCommentInput | null> | null;
};
export type UpdateByIdFeaturedSubCommentInput = {
    commentId: string;
    value?: unknown | null;
};
export type UpdateByIdCommentVoteInput = {
    upvotes?: number | null;
    downvotes?: number | null;
};
export type UpdateByIdCommentScoreInfoInput = {
    lastUpdatedDate?: string | null;
};
export type UpdateByIdCommentCountInput = {
    totalSubComments?: number | null;
    activeSubComments?: number | null;
};
export type UpdateByIdKeyValueInput = {
    key?: string | null;
    value?: unknown | null;
};
export type UpdateCommentMutationVariables = {
    commentId: string;
    record: UpdateByIdCommentInput;
};
export type UpdateCommentMutationResponse = {
    readonly commentUpdateById: {
        readonly record: {
            readonly id: string;
            readonly comment: string;
            readonly lastModifiedByUserId: string | null;
            readonly lastModifiedDate: string | null;
        } | null;
    } | null;
};
export type UpdateCommentMutation = {
    readonly response: UpdateCommentMutationResponse;
    readonly variables: UpdateCommentMutationVariables;
};



/*
mutation UpdateCommentMutation(
  $commentId: MongoID!
  $record: UpdateByIdCommentInput!
) {
  commentUpdateById(_id: $commentId, record: $record) {
    record {
      id
      comment
      lastModifiedByUserId
      lastModifiedDate
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "record"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_id",
        "variableName": "commentId"
      },
      {
        "kind": "Variable",
        "name": "record",
        "variableName": "record"
      }
    ],
    "concreteType": "UpdateByIdCommentPayload",
    "kind": "LinkedField",
    "name": "commentUpdateById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "record",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModifiedByUserId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModifiedDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCommentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCommentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "55820d842ed5e6be90e1bdfe508b8fc1",
    "id": null,
    "metadata": {},
    "name": "UpdateCommentMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCommentMutation(\n  $commentId: MongoID!\n  $record: UpdateByIdCommentInput!\n) {\n  commentUpdateById(_id: $commentId, record: $record) {\n    record {\n      id\n      comment\n      lastModifiedByUserId\n      lastModifiedDate\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bfcfb384e96d25b121a76ce8a8b9dcde';
export default node;
