/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCardFooter_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"Vote_viewer" | "TaggedTagsModal_viewer" | "PostCardCommentButton_viewer">;
    readonly " $refType": "PostCardFooter_viewer";
};
export type PostCardFooter_viewer$data = PostCardFooter_viewer;
export type PostCardFooter_viewer$key = {
    readonly " $data"?: PostCardFooter_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCardFooter_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCardFooter_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Vote_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaggedTagsModal_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCardCommentButton_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'a33df1ab4e511df3627928a5677bbdee';
export default node;
