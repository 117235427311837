/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PostCard_post = {
    readonly " $fragmentRefs": FragmentRefs<"PostCardTemplate_post" | "PostCardContent_post" | "PostCardSidebar_post">;
    readonly " $refType": "PostCard_post";
};
export type PostCard_post$data = PostCard_post;
export type PostCard_post$key = {
    readonly " $data"?: PostCard_post$data;
    readonly " $fragmentRefs": FragmentRefs<"PostCard_post">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "viewerId",
    "variableName": "viewerId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostCard_post",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "PostCardTemplate_post"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCardContent_post"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "PostCardSidebar_post"
    }
  ],
  "type": "Post",
  "abstractKey": null
};
})();
(node as any).hash = 'c8e953b8c3c42bde8f60e5d30c9edb59';
export default node;
