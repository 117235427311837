import cleanDeep from 'clean-deep';
import { dequal } from 'dequal';
import { useRouting } from 'expo-next-react-navigation';
import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useDeepCompareMemo } from 'use-deep-compare';

import ControlledTaggedTagsBox from '@/ControlledTaggedTagsBox';
import { ControlledTaggedTags } from '@/ControlledTaggedTagsBox/ControlledTaggedTagsBox';
import FilterTagsFooter from '@/FilterTagsFooter';
import TagIconButton from '@/TagIconButton';
import { FeedScope, ObjectType, QueryParam } from '#enum';
import useModals from '#hooks/useModals';
import useSession from '#hooks/useSession';
import useSuggestedTags from '#hooks/useSuggestedTags';
import useTheme from '#hooks/useTheme';
import { TagItem } from '#interfaces';
import { feedQuery, feedValue } from '#relay/helpers/feed';
import { compareTags } from '#utils';
import { FilterTagsBox_tags$key } from '~/FilterTagsBox_tags.graphql';
import { FilterTagsBox_viewer$key } from '~/FilterTagsBox_viewer.graphql';

const viewerFragment = graphql`
  fragment FilterTagsBox_viewer on User {
    id
    details {
      lastTaggedDate
    }
    ...FilterTagsFooter_viewer
  }
`;

const tagsFragment = graphql`
  fragment FilterTagsBox_tags on Query
  @argumentDefinitions(
    tagIds: { type: "[String]" }
    queryFor: { type: "String!" }
  ) {
    getSuggestedTags(tagIds: $tagIds, queryFor: $queryFor) {
      selectedTags {
        tagId
        slug
        label
      }
      suggestedTags {
        tag {
          tagId
          slug
          label
        }
      }
    }
  }
`;

type Props = {
  viewer: FilterTagsBox_viewer$key | null;
  tags: FilterTagsBox_tags$key;
};

// TODO: make suggested tags user specific
const FilterTagsBox = ({ viewer, tags }: Props): React.ReactElement => {
  const viewerData = useFragment(viewerFragment, viewer);
  const tagsData = useFragment(tagsFragment, tags);

  const initSelectedTags = React.useMemo(
    () =>
      tagsData?.getSuggestedTags?.selectedTags?.map((t) => ({
        id: t?.tagId,
        slug: t?.slug,
        label: t?.label,
      })) as TagItem[],
    [tagsData?.getSuggestedTags?.selectedTags],
  );
  const initialSuggestedTags = React.useMemo(
    () =>
      tagsData?.getSuggestedTags?.suggestedTags?.reduce(
        (ta, t) =>
          t?.tag
            ? [
                ...ta,
                {
                  id: t.tag?.tagId,
                  slug: t.tag?.slug,
                  label: t.tag?.label,
                },
              ]
            : ta,
        [] as TagItem[],
      ),
    [tagsData?.getSuggestedTags?.suggestedTags],
  );

  const { navigate, getParam } = useRouting();
  const [session] = useSession();
  const { colors, isDarkTheme } = useTheme();
  const { openSignUpLoginModal } = useModals();
  const [isTaggedSearch, setIsTaggedSearch] = React.useState(
    getParam(QueryParam.FeedScope) === FeedScope.Tagged,
  );
  const [selectedTags, setSelectedTags] = React.useState<
    ControlledTaggedTags['selectedTags']
  >(initSelectedTags);

  // get `suggestedTags` from the store/database
  const suggestedTags = useSuggestedTags(
    ObjectType.Post,
    selectedTags?.map((tag) => tag.id),
    initialSuggestedTags,
    viewerData?.id,
    isTaggedSearch,
    viewerData?.details?.lastTaggedDate,
  );
  const topSuggestedTags = useDeepCompareMemo(
    () => suggestedTags?.slice(0, selectedTags?.length ? 10 : 15),
    [selectedTags?.length, suggestedTags],
  );

  // variables
  const feed: string | undefined = getParam(QueryParam.FeedQuery);
  const textInputBg = isDarkTheme ? colors?.blackOpac04 : undefined;
  const isSelectedTagsChange = !dequal(
    feedValue(feed)?.sort() ?? [],
    (selectedTags ?? [])?.map((tag) => tag?.id).sort(),
  );
  const isFeedScopeChange =
    (getParam(QueryParam.FeedScope) === FeedScope.Tagged) !== isTaggedSearch;
  const isSubmittable = isSelectedTagsChange || isFeedScopeChange;
  const currentSortType = getParam(QueryParam.FeedSort);
  const haveUserToken = !!session?.user;

  // methods
  const handleTagChange = React.useCallback(
    (state: ControlledTaggedTags) => {
      if (!compareTags(selectedTags, state.selectedTags))
        setSelectedTags(state.selectedTags);
    },
    [selectedTags],
  );

  const handleSubmit = React.useCallback(() => {
    navigate({
      routeName: 'Home',
      web: { path: '/', shallow: true },
      params: cleanDeep({
        [QueryParam.FeedQuery]: feedQuery(selectedTags),
        [QueryParam.FeedScope]: isTaggedSearch ? FeedScope.Tagged : undefined,
        [QueryParam.FeedSort]: currentSortType,
      }),
    });
  }, [currentSortType, isTaggedSearch, navigate, selectedTags]);

  const handleTagIconPress = React.useCallback(() => {
    if (haveUserToken || viewerData?.id) {
      setSelectedTags(undefined);
      setIsTaggedSearch((prev) => !prev);
    } else {
      openSignUpLoginModal();
    }
  }, [haveUserToken, openSignUpLoginModal, viewerData?.id]);

  const filterTagsFooter = useDeepCompareMemo(
    () => (
      <FilterTagsFooter
        viewer={viewerData}
        selectedTags={selectedTags}
        canCreateCommunity={!!selectedTags?.length}
        isSubmittable={isSubmittable}
        onSubmit={handleSubmit}
      />
    ),
    [handleSubmit, isSubmittable, selectedTags, viewerData],
  );

  return (
    <ControlledTaggedTagsBox
      initSelectedTags={selectedTags as TagItem[]}
      suggestedTags={topSuggestedTags as TagItem[]}
      onTagChange={handleTagChange}
      searchableItems={
        // search all suggested tags if it's tagged tag search, else search global
        isTaggedSearch && suggestedTags.length ? suggestedTags : undefined
      }
      searchButtonComponent={
        <TagIconButton isActive={isTaggedSearch} onPress={handleTagIconPress} />
      }
      selectedTagsFooter={filterTagsFooter}
      bg={textInputBg}
    />
  );
};

export default React.memo(FilterTagsBox);
